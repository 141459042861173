@import "../../../scss/variable";
@import "../../../scss/mixin";

.threepl-settings-wrapper {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .button-wrapper {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 25px;
    }
    .wms-collapsible-content {
        columns: 4;
        -webkit-columns: 4;
        -moz-columns: 4;
        .wms-checkbox-component {
            width: 100%;
            float: unset !important;
            &.disabled-chk {
                opacity: 0.2;
            }
        }
        .chk-dropdown-wrapper {
            display: flex;
            flex-wrap: wrap;
            .wms-checkbox-component {
                width: 62%;
            }
        }
        .parent-child-content {
            flex: 1;
            .parent-settings-wrapper {
                &.disabled-section {
                    opacity: 0.2;
                }
                display: flex;
                padding: 0px 0px 5px 5px;
                label {
                    display: flex;
                    flex-direction: column-reverse;
                    color: #222222;
                    font-weight: 800;
                    font-size: 14px;
                    bottom: 10px;
                }
            }
            &.quick-box-parent-child {
                padding-top: 15px;
            }

            .learnmore-settings {
                display: flex;
                padding: 5px 15px 13px 18px;
                border: 1px solid #6faa27;
                border-radius: 50px;
                color: black;
                text-decoration: none;
                height: 33px;
                width: 111px;
                margin: 6px 0 18px 0;
            }
        }
    }
    .wms-dropdown-component {
        min-width: 140px;
        width: 40%;
        margin-bottom: 0;
        label {
            display: none;
        }
        select {
            height: 32px;
        }
        .caret {
            top: 11px;
        }
    }

    #threeplSettingsAddOnPriorityHeaders_main {
        .wms-collapsible-content {
            columns: unset;
            -webkit-columns: unset;
            -moz-columns: unset;
            .add-on-priority-wrapper {
                display: flex;
                .wms-checkbox-component {
                    width: auto;
                }
                .connection-edit-combination {
                    flex: 1;
                }
            }
        }
    }
    #customerNotification_main {
        .wms-collapsible-content {
            columns: unset;
            .notification-wrapper {
                display: flex;
                .send-from-name-wrapper {
                    display: flex;
                    width: 50%;
                    .sender-name-input {
                        width: 220px;
                        padding-right: 0;
                        input {
                            border-top-right-radius: unset !important;
                            border-bottom-right-radius: unset !important;
                            border-right: unset;
                        }
                    }
                    .sender-name-label {
                        padding-left: 0;
                        flex: 1;
                        input {
                            border-top-left-radius: unset !important;
                            border-bottom-left-radius: unset !important;
                        }
                    }
                }
                .from-email-address {
                    width: 30%;
                }
            }
        }
    }
}
