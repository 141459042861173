.attachDocuments {
    .customer-batch-wrapper{
        display: flex;
        margin-bottom: 10px;
        .wms-kendo-dropdown-list-component  {
            width: 50%;
            margin-top: 5px;
        }
    }
    .btn-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .wms-file-component {
            margin: 0;
        }
    }
    .attach-file-detail {
        display: flex;
        margin-bottom: 20px;
        height: 44px;
        .detail-label-wrapper {
            display: flex;
            flex-direction: column;
            width: 134px;
            label {
                font: Bold 16px/22px Open Sans;
                color: #222222;
            }
        }
        .detail-value-wrapper {
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            span {
                font: Regular 16px/22px Open Sans;
                color: #222222;
                height: 22px;
                white-space: pre-wrap;
                line-height: 22px;
            }
        }
    }
    .wms-collapsible-div  {
        flex: 1;
        display: flex;
        flex-direction: column;
        .wms-form-grid-holder {
            display: flex;
            flex: 1;
            // height: calc(100vh - 620px);
            min-height: 300px;
            .k-grid-header {
                padding-right: 0px;
            }
            .form-grid {
                .k-grid-content {
                    height: unset;
                    .download-file {
                        color: revert;
                        text-decoration: revert;
                    }
                }
            }
        }
    }
}