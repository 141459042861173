.order-summary-component {
    height: 100%;
    padding: 30px 15px 20px 0px;
    .wms-top-toolbar-btn-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        .wms-button-component {
            display: inline-block;
            padding-right: 15px;

            &.manage-order-summary-exit-btn {
                width: 100%;
                margin-bottom: 10px;

                .button_holder {
                    float: right;
                    .wms_toolbar_button {
                        margin-right: 0;
                    }
                }
            }
        }

        .wms-content-wrapper {
            flex: 1;
            height: 100%;
            overflow: auto;

            .wms-container-wrapper {
                .wms-area-content-darker {
                    margin-bottom: 0;
                    padding: 12px 15px 0px;

                    .wms-area-content-darker {
                        padding: 12px 5px 0px;

                        .wms-static-content-wrapper {
                            padding: 0 10px 10px;
                        }
                    }

                    .wms-static-content-wrapper {
                        display: inline-flex;
                        width: 100%;
                        padding: 0 0 10px;

                        .wms-content {
                            width: 50%;

                            &.wms-content-full {
                                width: 100%;
                                display: inline-flex;
                                justify-content: space-between;
                            }

                            .wms-data-items {
                                padding-bottom: 5px;

                                label {
                                    font-size: 14px;
                                    color: #527999;
                                    line-height: 20px;
                                    letter-spacing: 1px;
                                }

                                span {
                                    color: #333333;
                                }
                            }
                        }
                    }

                    .wms-table-wrapper {
                        padding: 0 0 11px;
                        overflow-x: hidden;

                        .wms-table-responsive {
                            display: block;
                            width: 100%;
                            overflow-x: auto;
                            -webkit-overflow-scrolling: touch;

                            table {
                                width: 100%;
                                border-collapse: collapse;
                                border: 0;
                                border: 1px solid #b7d2e5;

                                thead {
                                    background-color: var(--tabel-headbg);

                                    tr {
                                        border-color: var(--tableheader-rowbg);
                                        th {
                                            width: auto;
                                            color: var(--primary-black);
                                            border-right: 1px solid;
                                            padding: 3px 10px;
                                            background: var(--tabel-headbg);
                                            border-color: var(--tableheader-rowbg);
                                        }
                                    }
                                }

                                tbody {
                                    tr {
                                        &:nth-child(even) {
                                            background-color: #ffffff;
                                        }

                                        &:nth-child(odd) {
                                            background-color: #fafbfc;
                                        }

                                        td {
                                            padding: 8px 12px;

                                            .wms-table-wrapper {
                                                margin-left: 40px;

                                                tr {
                                                    &:first-child {
                                                        background-color: unset;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .wms-no-data-found {
                        text-align: center;
                        padding-bottom: 10px;
                    }

                    .wms-total-changes {
                        padding: 0px 7px 10px;
                        font-size: 14px;
                        color: #333;
                        text-align: right;

                        span {
                            font-size: 13px;
                            color: #527999;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}
