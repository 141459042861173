@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-container-wrapper {
    display: inline-block;
    width: 100%;

    .title {
        width: auto;
        float: left;
        text-align: left;
        margin-bottom: 8px;

        label {
            color: $form-title;
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;

            span {
                color: $label-color;
            }
        }

        .note {
            display: inline-block;
            color: $redbtn;
            font-size: 12px;
            margin-left: 5px;
            vertical-align: middle;
            font-weight: 600;
        }
    }
    .fa-info-circle {
        margin: 5px 5px 5px 5px;
        color: var(--primary-themecolor);
    }

    .wms-area-content {
        padding: 0;
        border: none;
        background: transparent;
        margin: 0px 0px 5px;
        display: inline-block;
        width: 100%;

        &:local(.wms_padding_top_18) {
            padding-top: 18px;
        }

        .wms-area-content-darker {
            width: 100%;
            float: left;
            background: $primary-white;
            border: 1px solid #d8e5ee;
            padding: 12px 5px 0px;
            margin-bottom: 12px;
            border-radius: 4px;
            position: relative;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;

            :local(.form_group) {
                width: 50%;
                margin-bottom: 11px;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 7px;
                    right: 7px;
                    bottom: 0;
                    background: #ffffff;
                }
            }
        }
    }
}
