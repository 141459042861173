.carrier-markup-model-wrapper {
    .fullWidth {
        width: 100%;
        display: flex;
        flex-direction: column;
        .wms-dropdown-component {
            padding: 0 5px;
        }
    }
    .carrier-service-options-checkbox {
        display: flex;
        flex-wrap: wrap;
        padding: 0 5px;
        column-gap: 5px;
        .column {
            width: 48%;
            label {
                display: flex;
                align-items: center;
            }
        }
    }
}
