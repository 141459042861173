@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";

#WindowCreatePickJob {
    .wms-collapsible-content{

        padding: 0;
        .title {
            padding: 15px;
            border-bottom: 1px solid #f5f5f5;
            display: flex;
            .wms-sprite{
                color: #fcde5f;
                margin-right: 10px;
                padding-top: 2px;
            }
        }
        .model-inside-content-wrapper{
            padding: 15px 9px 15px 9px;
            .wms-text-area-component{
                padding: 0 6px;
            }
        }
    }
}