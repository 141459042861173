@import "../../scss/variable";
@import "../../scss/mixin";

#wms-event-notification-wrapper {
    height: 100%;
    width: 100%;
    .event-notification-search-filter {
        height: 100%;
        border-right: none;
        transition: all 0.4s linear;
        overflow: hidden;
        display: inline-flex;
        flex-direction: column;
        -webkit-transition: all 0.4s ease-in;
        -moz-transition: all 0.4s ease-in;
        -o-transition: all 0.4s ease-in;
        transition: all 0.4s ease-in;
        margin-left: 0px;
        .title {
            padding: 5px 0 7px 0;
            label {
                color: $filter-note-title !important;
                font-size: 14px;
                letter-spacing: 1px;
                font-weight: 600;
            }
        }
        .wms-toolbar {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 25px;
            padding-right: 20px;
        }
        .disable-section {
            opacity: 0.2;
            pointer-events: none;
        }
        #search_default_collapse_main {
            margin-right: 10px;
            width: unset;
            .wms-dropdown-component {
                width: 100%;
            }
        }
        .event-notification-form-boxes-wrapper {
            position: relative;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            .event-notification-form-boxes {
                transition: all ease-in-out 0.3s;
                overflow: hidden;
                margin-right: 20px;
                .event-notification-area-content {
                    // display: inline-block;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    .title {
                        width: 100%;
                        float: left;
                        margin: 0 0 8px 0;
                        label {
                            color: $form-title !important;
                            font-size: 11px;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            font-weight: 600;
                        }
                    }
                    .wms-collapsible-div {
                        .wms_collapsible_header {
                            .wms-title {
                                padding: 5px 12px 0 20px;
                            }
                        }
                        .wms-collapsible-content {
                            margin-bottom: 0;
                            // padding: 15px 5px 5px;
                            .wms-dropdown-component {
                                width: 100%;
                            }
                            .wms-kendo-dropdown-list-component {
                                width: 100%;
                                flex-direction: row;
                                .form_group {
                                    float: left;
                                    position: relative;
                                    padding: 0px 7px;
                                    margin-bottom: 11px;
                                }
                            }
                            .add-clear-icon {
                                .wms-kendo-dropdown-list-component {
                                    .k-dropdown {
                                        width: calc(100% - 28px);
                                    }
                                }
                            }
                            ul#MultiSelectCustomerSkus_taglist {
                                position: absolute;
                            }
                            .wms-datetimepicker-component {
                                .border {
                                    width: 100%;
                                }
                            }
                            .wms-input-component {
                                width: 100%;
                            }
                            .wms-multiselect-input-wrapper {
                                .wms-kendo-dropdown-update-list-component {
                                    button {
                                        padding: 0;
                                        width: auto;
                                        height: 20px;
                                        justify-content: center;
                                        padding-left: 6px;
                                        padding-right: 6px;
                                        .wms_sprite {
                                            width: 12px;
                                            height: 12px;
                                            font-size: 12px;
                                            line-height: 22px;
                                        }
                                        .wms_toolbar_button_text {
                                            margin: 0px 9px 0px 4px;
                                            font-size: 10px;
                                            line-height: 22px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .form-group {
                        width: 100%;
                        float: left;
                        padding: 0px;
                        margin-bottom: 15px;
                    }
                    .wms_area_border {
                        height: 100%;
                        overflow: hidden;
                        padding: 0 0 26px 0;
                        #gridContainer {
                            height: 100%;
                        }
                    }
                }
            }
        }
        .filter-list-block {
            padding-bottom: 8px;
            margin-top: -4px;
            li.filter-list {
                display: flex;
                height: 48px;
                margin: 0px 7px 12px;
                background-color: transparent;
                border-radius: 4px !important;
                padding: 13px 5px 13px 12px;
                border: 1px solid #bbbbbb;
                a.title-text {
                    color: $input-color;
                    position: relative;
                    white-space: nowrap;
                    width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
                .wms-apply-btn {
                    display: flex;
                    margin: -7px 3px;
                }
                .clear-save-filter {
                    padding: 2px 5px;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    i {
                        font-size: 16px;
                        color: rgba(255, 0, 0, 0.2);
                        transition: all 0.3s ease-in-out;
                    }
                    &:hover {
                        color: #2c4059;
                        i {
                            color: rgba(255, 0, 0, 1);
                        }
                    }
                }
            }
        }
    }
    .new-layout-grid-wrapper {
        position: relative;
        transition-property: width;
        -webkit-transition: all 0.4s linear;
        -moz-transition: all 0.4s linear;
        -o-transition: all 0.4s linear;
        transition: all 0.4s linear;
        overflow: hidden;
        background: $form-boxbg;
        overflow-y: auto;
        padding: 0;
        margin-bottom: 30px;
        .title {
            margin: 0 0 0 2px;
        }
        .event-notification-refresh-btn {
            .blue_button_color {
                padding: 0px 16px;
                .wms_toolbar_button_text {
                    display: none;
                }
            }
        }
        .wms-toolbar-button {
            float: right;
            display: flex;
            flex-wrap: wrap;
            .refreshSmalParcelGrid {
                .blue_button_color {
                    padding: 0px 16px;
                    .wms_toolbar_button_text {
                        display: none;
                    }
                }
            }
        }
    }
    .wms-event-notification-search {
        height: 100%;
        position: relative;
        padding: 30px 10px 0 30px;
    }
    .event-notification-header-group {
        position: absolute;
        margin: 0 20px;
        top: 10px;
    }
}
#WebHookModal {
    .model-content-wrapper {
        .wms-collapsible-div {
            margin-bottom: 0px;
            .wms-collapsible-content {
                .display-inline {
                    display: flex;
                    .webhook-description {
                        width: 100%;
                    }
                    .webhook-destination-url {
                        width: 100%;
                        padding-left: 0;
                        .form_group {
                            width: 100%;
                        }
                        &.wms_invalid_input {
                            input {
                                border: 1px solid #d79093;
                            }
                        }
                    }
                    .webhook-customer {
                        width: 100%;
                        position: relative;
                        label {
                            margin-bottom: 8px;
                        }
                        .k-dropdown {
                            width: 272px;
                        }
                    }
                    &.webhook-event-dropdown-wrapper {
                        .wms-dropdown-component {
                            label {
                                padding: 0;
                            }
                            .webhook-event-type {
                                position: relative;
                            }
                        }
                    }
                    &.webhook-event-types-wrapper {
                        display: inline-block;
                        .wms-checkbox-component.wms-rounded-checkbox {
                            width: 33%;
                            label {
                                font-size: 12px;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }

        .scroll-horizontal {
            overflow-y: auto;
            // max-height: 250px;
            width: 100%;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border: 1px solid #eeeeee;
            border-top: none;
            .model-content-wrapper {
                width: 100%;
                padding: 15px;
                .wms-dropdown-component {
                    float: none;
                }
                .webhook-event-type {
                    width: 100%;
                    position: relative;
                    label {
                        margin-bottom: 8px;
                    }
                    .k-dropdown {
                        width: 272px;
                    }
                }
                .wms-checkbox-group {
                    margin-top: 15px;

                    &.disabled {
                        opacity: 0.3;
                    }

                    .label {
                        font-weight: 400;
                        font-size: 10px;
                        color: #222222;
                        margin-bottom: 4px;
                    }
                    .wms-checkbox-group-container {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 5px;
                        .checkbox-container {
                            width: 33.33%;
                            margin-bottom: 10px;
                            &.wms-checkbox-include-resouce-container {
                                width: 100%;
                            }
                            .wms-checkbox-component.wms-rounded-checkbox {
                                label {
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }
                    .resource-group-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        .check-div-60 {
                            width: 66.66%;
                            display: flex;
                            flex-wrap: wrap;
                            .checkbox-container {
                                width: 50%;
                                margin-bottom: 10px;
                            }
                        }
                        .check-div-40 {
                            width: 33.33%;
                            .tree-checkbox-container {
                                position: relative;
                                .wms-checkbox-component.wms-rounded-checkbox {
                                    label {
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                            }
                            .wms-checkbox-component.wms-rounded-checkbox {
                                float: unset !important;
                            }
                            .list-view {
                                margin: 0;
                                padding: 0;
                                list-style: none;
                                position: relative;
                                margin-top: 0.5rem;
                                margin-left: 1rem;
                                &.disabled {
                                    opacity: 0.3;
                                }

                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: -1px;
                                    border-left: 1px solid #bbbbbb;
                                }
                                .tree-checkbox-container {
                                    margin-bottom: 10px;
                                    padding-left: (
                                        1em + 0.5
                                    ); // indentation + .5em
                                    // horizontal line
                                    &:before {
                                        content: "";
                                        position: absolute;
                                        top: (
                                            2em / 2
                                        ); /* half the line-height */
                                        left: 0;
                                        width: 1em; /* same as indentation */
                                        height: 1px;
                                        border-top: 1px solid #bbbbbb;
                                    }
                                    &:last-child:before {
                                        background-color: white; /* same as body background */
                                        height: auto;
                                        top: (
                                            2em/ 2
                                        ); /* half the line-height */
                                        left: -1px;
                                        bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
