.react_iframebox {
    padding-bottom: 30px;
    width: 26%;
    ul {
        margin-left: 10px;
        li {
            a {
                text-decoration: none;
                color: #5a7e9d;
                padding: 5px 0px;
                display: block;
                text-rendering: optimizeLegibility !important;
                -webkit-font-smoothing: antialiased !important;
                transition: all .3s;
                &:hover {
                    color: #28b7ed;
                }
            }
        }
    }
}

.iframe-controller {
    .k-loading-mask {
        iframe {
            border: none;
            width: 100%;
            height: 100%;
            display: flex;
        }
    }
}