@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.context-menu-wrapper {
    padding: 5px 0px;
    box-shadow: 0px 0px 20px #a9a9a9 !important;
    border-radius: 5px;
    border: none;
    transition: none !important;
    &.k-menu.k-context-menu {
        .k-item {
            &.k-state-focused {
                box-shadow: unset !important;
            }
            &:hover {
                background: #c1e9ed !important;
                color: var(--primary-themecolor) !important;
            }
            .k-state-active {
                background: #c1e9ed !important;
                color: var(--primary-themecolor) !important;
                -webkit-text-stroke-width: 0.6px;
            }
            .k-menu-link {
                padding: 4px 12px;
                padding-right: 40px;
                font-size: 13px;
                .k-icon {
                    right: 12px;
                }
            }
            ul {
                padding: 5px 0px;
                box-shadow: 0px 0px 20px #a9a9a9 !important;
                border-radius: 5px;
                border: none;
                li {
                    cursor: pointer;
                    &:focus {
                        box-shadow: none !important;
                        outline: 0 !important;
                        border: none !important;
                    }
                    li {
                        cursor: pointer;
                    }
                }
            }
            &.mydisabled {
                opacity: 0.7;
                background-color: unset !important;
                color: inherit !important;
                cursor: not-allowed;
            }
        }
    }
}
