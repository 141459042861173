@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-text-area-component {
    label {
        @include label;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    textarea {
        background: $primary-white;
        width: 100%;
        max-height: 147px;
        padding: 10px;
        color: $input-color;
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
        border: 1px solid $border-color;
        border-radius: 4px;
        resize: none;
    }

    .wms_required_input {
        border: 1px solid $requireinput-border !important;

        &:focus {
            border-color: $primary-themecolor !important;
        }
    }
}