.super-nav-component {
    height: 40px;
    background: #4185c5;
    color: #ffffff;
    display: flex;
    flex-direction: row-reverse;
    z-index: 102;
    .main-wrapper {
        margin: auto 10px;
        border-left: 1px solid #ffffff;
        font-weight: bold;
    }
    .call-us {
        a.cust-central {
            color: #ffffff;
            padding: 0;
            &:hover {
                color: var(--primary-themecolor);
            }
        }
    }
    .customer-central {
        ul#customer-central-dropdown {
            a.cust-central {
                color: #ffffff;
                padding: 0;
                .k-menu-expand-arrow {
                    display: none;
                }
                &:hover {
                    color: var(--primary-themecolor);
                }
            }
            li {
                .k-animation-container {
                    top: 29px !important;
                    ul.k-group {
                        background: white;
                        padding-bottom: 2px;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;
                        li {
                            a.k-link {
                                font-weight: normal;
                                color: #3e3e3e;
                                font-size: 13px;
                                padding: 4px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .main-icon {
        padding: 0 13px;
    }
    .sand-box-alert {
        top: 0px;
        height: 30px;
        left: 560px;
        position: absolute;
        display: flex;
        background: repeating-linear-gradient(125deg, #febf32, #febf32 14px, #fec648 10px, #fec648 30px);
        border-radius: 0px 0px 4px 4px;
        letter-spacing: 0px;
        color: #ffffff;
        text-shadow: 0px 3px 6px #00000059;
        .expand-title {
            padding-left: 112px;
            padding-top: 4px;
            font-weight: 700;
            .fa {
                margin-right: 6px;
            }
        }
        .expand-msg {
            padding: 4px 0px 0px 5px;
            display: flex;
            flex: 1;
        }
        .collapse-expand-icon {
            margin: 8px;
            cursor: pointer;
        }

        &.expand-sandbox-msg {
            animation: expand-send-box 0.2s forwards;
            width: 800px;
            .expand-title {
                padding-left: 112px;
            }
        }
        &.collapse-sandbox-msg {
            animation: collapse-send-box 0.2s forwards;
            width: auto;
            .expand-title {
                padding-left: 5px;
            }
        }
    }
    @keyframes collapse-send-box {
        0% {
            margin-left: 0px;
        }
        100% {
            margin-left: -550px;
        }
    }

    @keyframes expand-send-box {
        0% {
            margin-left: -560px;
        }
        100% {
            margin-left: 0;
        }
    }
}
