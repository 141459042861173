
.inventory-hold-reason-model-wrapper {
    .wms-collapsible-content {
        display: flex !important;
        .wms-dropdown-component {
            width: 262px;
        }
        .wms-input-component {
            width: 449px;
            input:disabled {
                background-color: #FFF;
                border: 1px solid #BBBBBB;
            }
        }
        .disable-input {
            opacity: 0.2;
        }
    }
}
