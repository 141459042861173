@import "../../../../scss/variable";
@import "../../../../scss/mixin";

#wms-findreceipt-wrapper {
    height: 100%;
    width: 100%;
    .new-layout-filter-wrapper {
        position: relative;
        height: 100%;
        overflow: auto;

        .filter {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            border-radius: 0px;
            background: $tabel-headbg;
            border: 0px;
            z-index: 1;
            cursor: pointer;
            .fa-caret-left {
                font-size: 16px;
                padding: 4px 8px;
            }
            .fa-caret-left:before {
                color: #527999;
            }
            &:hover {
                background: #0079c4;
                .fa-caret-left:before,
                span {
                    color: #fff;
                }
            }
            span {
                display: none;
            }
        }

        .find-receipt-search-filter {
            height: 100%;
            border-right: none;
            transition: all 0.4s linear;
            overflow: hidden;
            display: inline-flex;
            flex-direction: column;
            -webkit-transition: all 0.4s ease-in;
            -moz-transition: all 0.4s ease-in;
            -o-transition: all 0.4s ease-in;
            transition: all 0.4s ease-in;
            margin-left: 0px;
            margin-right: 10px;
            // &.expand {
            //     transform: unset;
            //     margin-left: -396px;
            //     -webkit-transition: all 0.4s ease-in;
            //     -moz-transition: all 0.4s ease-in;
            //     -o-transition: all 0.4s ease-in;
            //     transition: all 0.4s ease-in;
            //     // @include desktop_1680 {
            //     //     margin-left: -340px;
            //     // }
            //     .collapse-box {
            //         position: absolute;
            //     }
            // }
            .title {
                padding: 5px 0 7px 0;
                label {
                    color: $filter-note-title !important;
                    font-size: 14px;
                    letter-spacing: 1px;
                    font-weight: 600;
                }
            }
            .wms-toolbar {
                display: flex;
                width: 100%;
                flex-direction: row-reverse;
            }
            .warehouse-form-boxes-wrapper {
                position: relative;
                width: 100%;
                overflow: hidden;
                overflow-y: auto;

                .warehouse-form-boxes {
                    transition: all ease-in-out 0.3s;
                    overflow: hidden;
                    .warehouse-are-content {
                        // display: inline-block;
                        border-radius: 4px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        .title {
                            width: 100%;
                            float: left;
                            margin: 0 0 8px 0;
                            label {
                                color: $form-title !important;
                                font-size: 11px;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                font-weight: 600;
                            }
                        }
                        .wms-collapsible-div {
                            .wms_collapsible_header {
                                .wms-title {
                                    padding: 5px 12px 0 20px;
                                }
                            }
                            .wms-collapsible-content {
                                margin-bottom: 0;
                                // padding: 15px 5px 5px;
                                .wms-dropdown-component {
                                    width: 100%;
                                    &.dropdown-width-75 {
                                        width: 75%;
                                    }
                                }
                                .wms-kendo-dropdown-list-component {
                                    width: 100%;
                                    flex-direction: row;
                                    .form_group {
                                        float: left;
                                        position: relative;
                                        padding: 0px 7px;
                                        margin-bottom: 11px;
                                    }
                                }
                                .add-clear-icon {
                                    .wms-kendo-dropdown-list-component {
                                        .k-dropdown {
                                            width: calc(100% - 28px);
                                        }
                                    }
                                }
                                ul#MultiSelectCustomerSkus_taglist {
                                    position: absolute;
                                }
                                .wms-datetimepicker-component {
                                    .border {
                                        width: 100%;
                                    }
                                }
                                .wms-input-component {
                                    width: 100%;
                                    &.half-width {
                                        width: 50%;
                                    }
                                }
                                .wms-radio-wrapper {
                                    padding: 0px 6px;
                                    display: flex;
                                    flex-direction: column;
                                }
                            }
                        }
                        .form-group {
                            width: 100%;
                            float: left;
                            padding: 0px;
                            margin-bottom: 15px;
                        }
                        .wms_area_border {
                            height: 100%;
                            overflow: hidden;
                            padding: 0 0 26px 0;
                            #gridContainer {
                                height: 100%;
                            }
                        }
                    }
                }
            }
            .filter-list-block {
                li.filter-list {
                    display: flex;
                    height: 32px;
                    margin: 0px 7px 12px;
                    background-color: #f5f5f5;
                    border-radius: 4px !important;
                    padding: 5px 5px 5px 10px;
                    a.title-text {
                        color: $input-color;
                        position: relative;
                        white-space: nowrap;
                        font-weight: bold;
                        width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        flex: 1;
                    }
                    .filter-icon {
                        padding: 2px 5px;
                        cursor: pointer;
                    }
                    .update-save-filter {
                        transition: all 0.3s ease-in-out;
                        i {
                            color: #90de2e;
                            &:hover {
                                color: #81c926;
                            }
                        }
                    }
                    .apply-save-filter {
                        transition: all 0.3s ease-in-out;
                        i {
                            color: #90de2e;
                            &:hover {
                                color: #81c926;
                            }
                        }
                    }
                    .clear-save-filter {
                        transition: all 0.3s ease-in-out;
                        i {
                            font-size: 16px;
                            color: rgba(255, 0, 0, 0.2);
                            transition: all 0.3s ease-in-out;
                        }
                        &:hover {
                            color: #2c4059;
                            i {
                                color: rgba(255, 0, 0, 1);
                            }
                        }
                    }
                }
            }
        }
    }
    .new-layout-grid-wrapper {
        transition-property: width;
        transition: all 0.4s linear;
        overflow: hidden;
        background: #ffffff;
        overflow-y: auto;
        &.find-receipt-grid {
            transition-property: width;
            -webkit-transition: all 0.4s linear;
            -moz-transition: all 0.4s linear;
            -o-transition: all 0.4s linear;
            transition: all 0.4s linear;
            overflow: hidden;
            background: $form-boxbg;
            overflow-y: auto;

            .title {
                margin: 0 0 0 2px;
            }
            .wms-toolbar-button {
                float: right;
                display: flex;
                flex-wrap: wrap;
                .receiptRefreshBtn {
                    .primary_button_color {
                        padding: 0px 16px;
                        .wms_toolbar_button_text {
                            display: none;
                        }
                    }
                }
            }
            .gridContainer {
                .k-grid-header-wrap {
                    table {
                        thead {
                            tr {
                                th {
                                    // padding: 0 30px 0 5px;
                                    .k-header-column-menu {
                                        margin-right: -4px;
                                        z-index: 1;
                                        bottom: 0;
                                        height: 100%;
                                    }
                                    .k-grid-filter {
                                        background: transparent;
                                        margin-top: 10px;
                                        margin-right: -4px;
                                        .k-icon {
                                            &:before {
                                                content: "\E031";
                                            }
                                        }
                                        .k-header-column-menu {
                                            bottom: 0 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .wms-virtual-grid-holder {
                margin: 0;
                .wms_area_content {
                    .wms-grid-details {
                        margin: 12px 0 0 0;
                    }
                }
                .wms-grid-area-wrapper {
                    .gridContainer {
                        .k-grid-header-wrap {
                            table {
                                thead {
                                    tr {
                                        th {
                                            // padding: 0 30px 0 5px;
                                            .k-header-column-menu {
                                                margin-right: -4px;
                                                z-index: 1;
                                                bottom: 0;
                                                height: 100%;
                                            }
                                            .k-grid-filter {
                                                background: transparent;
                                                margin-top: 10px;
                                                margin-right: -4px;
                                                .k-icon {
                                                    &:before {
                                                        content: "\E031";
                                                    }
                                                }
                                                .k-header-column-menu {
                                                    bottom: 0 !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .new-layout-grid-wrapper {
        .wms-grid-wrapper {
            flex: 1;
            height: 100%;
            margin-bottom: 0;
        }
    }
    &.expandFilter {
        &.animatedCollapse {
            .new-layout-filter-wrapper {
                animation: showCommonFilterSection 1s forwards;
            }
        }
    }
    &.collapsedFilter {
        &.animatedCollapse {
            .new-layout-filter-wrapper {
                animation: hideCommonFilterSection 1s forwards;
            }
        }
        &:not(.animatedCollapse) {
            .new-layout-filter-wrapper {
                margin-left: -390px;
            }
        }
        .new-layout-filter-wrapper {
            overflow: hidden;

            .filter {
                width: 40px;
                height: 100%;

                .fa-caret-left {
                    transform: rotate(180deg);
                    transition: all 0.4s;
                    -webkit-transition: all 0.4s;
                    -moz-transition: all 0.4s;
                    padding: 13px 17px;
                    @include desktop_1280 {
                        padding: 13px 20px;
                    }
                }
                span {
                    text-transform: uppercase;
                    font-size: 12px;
                    transform: rotate(90deg);
                    display: inline-block;
                    width: 120px;
                    position: absolute;
                    top: 90px;
                    left: -40px;
                }
            }
        }
    }
}
