@import "../../../scss/variable";
@import "../../../scss/mixin";

.small-parcel-settings-wrapper {
    height: 100%;
    width: 100%;
    .small-parcel-settings-save-btn {
        float: right;
        margin: 0;
        padding-bottom: 20px;
        .wms-button-tooltip {
          left: -92px;
          &:before {
            left: 130px;
          }
        }
    }
    .customer-selection-wrapper {
      width: 364px;
    }
    .carrier-account-settings-wrapper {
      .wms-grid-wrapper {
        margin: 0px 5px 5px 5px;
        .wms-grid-top {
          margin-top: 15px;
        }
        .wms-grid-holder {
          .k-grid {
            height: 400px !important;
          }
        }
      }
    }
    .disabled-section {
      opacity: 0.2;
      pointer-events: none;
    }
    .small-parcel-other-settings-wrapper {
      .shipping-cost-wrapper, .process-high-volume-default, .customize-duties-wrapper {
        padding: 20px 45px 0 5px;
      }
      .usps-3rd-party-wrapper {
        width: 172px;
      }
      .international-bill-type-wrapper,
      .label-reference-wrapper,
      .fedex-return-service-wrapper,
      .ups-return-service-wrapper,
      .city-wrapper {
        width: 212px;
      }
      .process-high-volume-default {
        margin-left: 20px;
      }
      #SmartParcelReturnSettings_main {
        .wms-collapsible-content {
          .inline-element {
            display: flex;
            .company-wrapper, .name-wrapper {
              width: 350px;
            }
            .phone-number-wrapper {
              width: 200px;
            }
            .code-wrapper {
              width: 150px;
            }
          }
          .return-label-default {
            padding: 17px 25px;
          }
        }
      }
      #smallParcelGeneralSettings_main {
        .wms-collapsible-content {
          .inline-element {
            display: flex;
          }
          .options-wrapper {
            margin-top: 15px;
          }
        }
      }
      .country-wrapper {
        width: 262px;
      }
      .street-address-wrapper {
        width: 462px;
      }
      .state-wrapper {
        width: 312px;
      }
      .zip-wrapper {
        width: 137px;
      }
      .return-street-address-chk-wrapper {
        padding: 20px 0px 0 20px;
      }
      #SmartParcelAutoRateShopping_main {
        .wms-collapsible-content {
          padding: 0;
          padding-top: 15px;
          .wms-container-wrapper {
            padding: 0 21px 0 21px;
          }
          .wms-rate-shopping-chk-wrapper {
              display: flex;
              flex-direction: column;
              .option-input {
                margin: 6px 6px 6px 0;
              }
              .select-carrier-label {
                font-size: 10px;
                line-height: 1.2;
                margin-left: 1px;
                color: #222222;
                font-weight: normal;
                display: inline-block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 3px;
              }
              .wms-checkbox-component {
                  display: inline-block;
                  margin-bottom: 12px;
              }
          }
          .wms-auto-rate-shopping-chk-wrapper {
            display: flex;
            flex-direction: column;
          }
          .wms-grid-wrapper {
            border-top: 1px solid #eeeeee;
            padding: 15px;
            .wms-grid-toolbar {
              margin-bottom: 20px;
            }
            .wms-grid-holder {
              min-height: 0 ;
              .k-grid {
                height: 153px !important;
                .k-command-cell {
                  padding: 0;
                  margin: 0;
                  .rate-shop-delete {
                    border: unset;
                    background: transparent;
                    color: #D91C03;
                    span {
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
          .wms-container-wrapper {
            .title {
              margin: 0;
              label {
                color: #222;
                text-transform: none;
                letter-spacing: 0;
                font-weight: 100;
              }
            }
            .wms-area-content-darker {
              border: none;
              padding: 0;
              .wms-checkbox-component {
                padding: 0;
                margin-right: 40px;
              }
            }
          }
        }
      }
      .wms-input-dropdown-btn-block{
          display: flex;
          align-items: center;
          .wms-add-and-remove-btn-block{
              margin-top: 10px;
          }
          .wms-input-component {
              @include desktop_1280{
                  width: 35%;
              }
          }
          .wms-dropdown-component {
              @include desktop_1280{
                  width: 35%;
              }
          }
      }
      .wms-form-grid-holder{
          margin-top: 10px;
      }
      .wms-smartparcel-note {
          margin: 7px 0;
          font-size: 12px;
          color: #5a7e9d;
          float: left;
      }
    }
}