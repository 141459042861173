@import "./../../scss/variable";
@import "./../../scss/mixin";

.adjust-customer-charges-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 !important;
    .adjust-customer-charges-search {
        width: 405px;
        min-width: 400px;
        max-width: 405px;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #b7d2e5;
        background: rgba(0, 0, 0, 0.03);
        position: relative;
        transition: all 1s linear;
        .title {
            padding: 5px 0 7px 0;
            label {
                color: $filter-note-title !important;
                font-size: 14px;
                letter-spacing: 1px;
                font-weight: 600;
            }
        }
        .btn-section {
            padding-bottom: 30px;
            .wms-button-component {
                float: right;
            }
        }
        .charges-type {
            width: 100%;
        }
        .amount-range-wrapper {
            .wms-input-component {
                width: 50%;
            }
        }
    }
    .adjust-customer-charges-grid {
        flex: 1 1;
        overflow-x: auto;
        margin: 0 0 20px 0;
        overflow-y: hidden;
        .customer-required {
            color: #D91C03;
            font-size: 14px;
            letter-spacing: 0.5px;
            font-weight: 600;
            width: 100%;
            position: fixed;
            margin: 0;
            margin-top: 4px;
            margin-left: 300px;
        }
        .customer-charge-refresh-btn {
            .wms_toolbar_button {
                padding: 0 16px;
                .wms_toolbar_button_text {
                    display: none;
                }
            }
        }
    }
}
#CreateNewChargeModal {
    .wms-collapsible-content {
        padding: 0;
        .charge-detail-wrapper {
            display: flex;
            flex-direction: column;
            .display-inline {
                display: flex;
                padding: 0 15px;
                &.charge-add-btn {
                    justify-content: center;
                    padding: 10px;
                }
            }
            .wms-datetimepicker-component {
                // eslint-disable-next-line
                float: right;
            }
            .charge-notes {
                width: 50%;
            }
            .wms-checkbox-component {
                padding-top: 17px;
                padding-left: 30px;
            }
            .charge-type{
                width: 27%;
            }
            .charge-amount {
                width: 14%;
            }
            .charge-description {
                width: 60%;
            }
            .charge-grid-wrapper {
                flex-direction: column;
                .k-grid-header-wrap {
                    padding-right: 20px;
                }
                .wms-form-grid-holder {
                    padding-bottom: 0;
                    .charge-delete {
                        border: none;
                        padding: 0;
                        margin: 0;
                        color: #EF0000;
                    }
                }
                .k-grid {
                    min-height: 400px;
                }
                .charge-totalWrapper {
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: 20px;
                    .total-text {
                        height: 32px;
                        font-weight: 700;
                        color: #222222;
                        padding: 6px 10px;
                        background-color: #e2effc;
                        background-color: #e2effc;
                        border: 1px solid #e2effc;
                        border-right: none;
                    }
                    .wms-input-component {
                        width: 100%;
                        padding: 0;
                        label {
                            display: none;
                        }
                        input {
                            border-left: none;
                            border: 2px solid #e2effc;
                        }
                    }
                }
            }
        }
    }
}
#CancelAdjustmentModal {
    .wms-collapsible-content {
        padding: 0;
        .display-inline {
            display: flex;
            padding: 0 15px;
        }
        .left-content-wrapper,
        .right-content-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0 6px;
            .content {
                display: flex;
                justify-content: space-between;
                label {
                    display: flex;
                    flex: 1;
                    font-size: 14px;
                    line-height: 19px;
                    top: 264px;
                    left: 610px;
                    height: 19px;
                    text-align: left;
                    letter-spacing: 0px;
                    color: #222222;
                    opacity: 1;
                }
                span {
                    display: flex;
                    top: 264px;
                    left: 752px;
                    height: 19px;
                    width: 190px;
                    text-align: left;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0px;
                    color: #222222;
                    opacity: 1;
                }
            }
        }
        .cancellation-reason {
            width: 588px;
        }
        .cancellation-fee {
            width: 100px;
        }
    }
}
