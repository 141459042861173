@import "../../../scss/variable";
@import "../../../scss/mixin";
#order-preferences-holder-react {
    width: 100%;
    display: inline-block;
    .wms-area-content {
        margin-top: 10px;
        .wms-area-content-darker {
            .wms-dropdown-component {
                width: 33.33%;
            }
        }
    }
    .wms-order-data-wrapper {
        .wms-area-content {
            .wms-area-content-darker {
                padding-bottom: 10px;
                .title {
                    margin-bottom: 0px;
                }
                .wms-dropdown-component {
                    width: 33.33%;
                }
                .wms-radiobtn-component {
                    .wms-radiobtn {
                        width: 100%;
                        margin-bottom: 0px;
                    }
                }
                .wms-checkbox-component {
                    width: 100%;
                    margin-bottom: 0px;
                }
                .wms-divider-light {
                    width: 100%;
                    margin: 14px 0px 10px;
                    display: inline-block;
                    border-bottom: 1px solid #d8e5ee;
                }
            }
        }
    }
}