#lasershipInfoScreen_lasership_attributes_information_main {
    .check-box {
        width: 200px;
    }
}
.displayFlex {
    display: flex;
}
#lasershipInfoScreen_lasership_company_information_main {
    .wms-timepicker-component-proto-kendo {
        div {
            width: 100% !important;
            margin-top: 1px;
        }
        width: 50% !important;
        display: inline-flex;
        flex-wrap: wrap;
    }
    .k-timepicker {
        width: 100% !important;
    }
    .k-picker-wrap {
        font-weight: 700 !important;
        color: #222222 !important;
        font-size: 14px !important;
        .k-input {
            padding: 0px 10px !important;
            height: 30px !important;
        }
    }
}