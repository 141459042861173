@import "../../../scss/variable";
@import "../../../scss/mixin";

#manage-orders-holder-virtual-react {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    padding: 0 !important;

    &>div {
        height: 100%;
        width: 100%;
    }

    .order-grid {
        width: 46%;
        border-right: 1px solid $seprator-color;
    }

    .order-form {
        position: relative;
        flex: 1;
        overflow: hidden;
        padding-left: 15px;

        .manage-order-holder-virtual-react {
            .wms-button-dropdown-component {
                .k-animation-container {
                    ul {
                        min-width: 170px;
                    }
                }
            }
        }
        .manage-order-holder-beta-virtual-react {
            .wms-virtual-grid-holder{
                .wms_area_content {
                    .wms_toolbar {
                        .button_list {
                            margin-right: 0;
                        }
                    }
                }
            }
            .fulfillment-invoicing-wrapper{
                display: flex;
                justify-content: flex-end;
                .fulfillment-left-section,
                .fulfillment-right-section{
                    display: flex;
                    flex-direction: column;
                    .wms-input-component {
                        width: 100%;
                        .form_group{
                            display: flex;
                        }
                    }
                }
                .fulfillment-right-section{
                    .wms-input-component {
                        label{
                            text-align: right;
                            padding: 10px;
                            font-size: 14px;
                        }
                        .extra-sign{
                            position: relative;
                            top: 8px;
                        }
                        input{
                            padding-left: 15px !important;
                        }
                    }
                    .bold-input-label{
                        label{
                            font-weight: bold;
                            letter-spacing: 0;
                            color: #222222;
                        }
                    }
                }
                .fulfillment-left-section{
                    justify-content: flex-end;
                    width: 43%;
                    .wms-input-component {
                        .form_group{
                            label{
                                padding-top: 9px;
                                text-align: right;
                                padding-right: 15px;
                                font-size: 14px;
                            }
                        }
                    }
                    .gift-message-input{
                        input{
                            width: 70%;
                        }
                    }
                    .discount-code-input{
                        input{
                            width: 50%;
                        }
                    }
                }
            }
            .wms-top-toolbar-wrapper {
                .message-sections {
                    margin-top: 0;
                }
            }
        }
        .form_clause {
            display: flex;
            flex-direction: column;

            .tab-content {
                overflow: auto;
                width: 100%;
                margin-top: 12px;
                flex: 1;

                .wms-radiobtn-component {
                    margin-bottom: 16px;
                    display: inline-block;
                    float: left;
                    width: 38%;

                    @include desktop_1440 {
                        width: 45%;
                    }

                    @include desktop_1280 {
                        width: 50%;
                    }

                    .wms-radiobtn {
                        margin: 0px;
                        padding: 0 5px;

                        @include desktop_1366 {
                            padding: 0 2px;
                        }
                    }
                }
            }
        }
    }
}