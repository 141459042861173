@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";
#massShipAndClose {
        .model-content-wrapper{
            .wms-collapsible-div{
                border: 1px solid #EEEEEE;
                border-top: 0;
                &#shipAndCloseRoutingInfo_main {
                    .collapse-wrapper-section {
                        padding: 15px 0;
                    }
                }
                .wms-collapsible-content{
                    padding: 0;
                    .title{
                        padding: 15px;
                        border-bottom: 1px solid #f5f5f5;
                        .wms-sprite {
                            color: #fcde5f;
                            margin-right: 10px;
                        }
                        span{
                            font-size: 12px;
                            color: $input-color;
                        }
                    }
                    .collapse-wrapper-section{
                        display: flex ;
                        flex-direction: column;
                        .element-parent-block{
                            display: flex;
                            padding: 0 9px
                        }
                        .wms-elements-wrapper {
                            padding: 0 9px;
                            .wms-dropdown-component{
                                .form_group{
                                    select{
                                        &.k_state_disabled{
                                            background-color: #f7f7f7;
                                            color: #222222;
                                        }
                                    }
                                }
                            }
                        }
                        .wms-datetimepicker-component{
                            width:40%;
                        }
                        .wms-input-component, .wms-dropdown-component {
                            width:25%;
                        }
                        .dropdown-width-40{
                            width:40%;
                            padding: 15px 9px 0px 9px;
                        }
                        .additional-charge-label-inp{
                            width: 33%;
                        }
                        .additional-charge-unit-type-inp{
                            width: 15%;
                        }
                        .additional-charge-per-unit-inp{
                            width: 13.5%;
                        }
                        .additional-charge-numberof-unit{
                            width: 13.5%;
                        }
                        .addtional-chrage-btn{
                            display: flex;
                            justify-content: space-around;
                            margin-top: 12px;
                            .wms-button-component {
                                margin-bottom: 20px;
                            }
                        }
                        .charge-success-msg{
                            padding: 15px;
                            border-top: 1px solid #eee;
                            .wms-sprite {
                                color: #b3de7d;
                                margin-right: 10px;
                            }
                            span{
                                color: #222222;
                            }
                        }
                        .wms-shipclose-grid-holder {
                            border-top: 1px solid #eee;
                            padding: 20px 15px 0 15px;
                            .charge-grid-note{
                                // margin-top: 20px;
                                border: 1px solid #b7d2e5;
                                background: #e2effc;
                                // letter-spacing: 0.5px;
                                color: #222222;
                                font-size: 12px;
                                font-weight: bold;
                                padding: 6px 0 5px 10px;
                                border-radius: 4px 4px 0 0;
                            }
                            .wms-grid-wrapper {
                                // display: flex;
                                .wms-grid-holder {
                                    min-height: 200px;
                                    #GridMassShipCharges {
                                        .k-grid-content{
                                            height:200px !important;
                                            .k-virtual-scrollable-wrap{
                                                table{
                                                    td{
                                                        &.k-command-cell{
                                                            .charge-delete{
                                                                border-radius: 50%;
                                                                margin: 0;
                                                                border-color: #db6353;
                                                                padding: 0;
                                                                .k-i-close{
                                                                    font-size: 12px;
                                                                    margin: 0;
                                                                    color: #db6353;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .totalcharges-section{
                                display: flex;
                                justify-content: flex-end;
                                .wms-input-component,.wms-dropdown-component {
                                    width: 30%;
                                }
                            }
                        }
                    }
                }
            }
            #additonalChargesRoutingInfo_main {
                margin-bottom: 0;
            }
        }
        .footer-btn-holder{
            .wmsi-button-holder{
                margin: auto;
                .wms-button-component {
                    margin-bottom: 0;
                }
            }
        }
}