
.print-batched-shipping-model-wrapper{
    .model-content-wrapper {
        display: flex;
        flex-direction: column;
        .custom-grid-wrapper{
            display: flex;
            flex-direction: column;
            flex: 1;
            height: calc(100% - 150px);
            &.expanded {
                .wms-virtual-grid-holder {
                    .wms-grid-area-wrapper {
                        max-height: calc(100% - 193px);
                    }
                }
            }
            &.callapse {
                .wms-virtual-grid-holder {
                    .wms-grid-area-wrapper {
                        max-height: calc(100% - 40px);
                    }
                }
            }
            .radio-btn-wrapper {                 
                width: 400px;
                margin-bottom: -30px;
            }
        }
        .disable-radio-section {
            opacity: 0.2;
            pointer-events: none;
        }
        .radio-btn-wrapper{
            text-transform: uppercase;
            .wms-radiobtn-component {
                .wms-radiobtn {
                    margin: 11px 45px 0px 0;
                    label {
                        input {
                            margin: 3px 5px 5px 0px;
                        }
                    }
                }
            }
        }
        .display-inline{
            display: flex;
        }
    }
    .wms-grid-wrapper{
        .wms-grid-holder {
            min-height: unset; 
            max-height: unset;
            .k-grid {
                .columnd-header-icon {
                    font-size: 12px;
                }
                .facility-exclamator{
                    padding-left: 5px;
                    color: #d91c03;
                }
                .error-icon-status{
                    cursor: pointer;
                }
            }
        }
    }
    .wms-virtual-grid-holder{
        margin: 0 ;
        flex: 1;
        .wms-grid-details, .wms-message-wrapper {
            display: none;
        }
        .wms_area_content {
            .wms_toolbar {
                display: flex;
                justify-content: flex-end;
                padding-right: 10px;
                .processedBatchedRefreshBtn {
                    .blue_button_color {
                        padding: 0px 16px;
                        .wms_toolbar_button_text {
                            display: none;
                        }
                    }
                }
            }
        }
        .wms-grid-area-wrapper {
            min-height: 180px; 
            // max-height: unset;
            .wms_area_border {
                height: auto;
                .gridContainer {
                    .k-grid {
                        .k-grid-header {
                            border: 1px solid #BCD2E3 !important;
                            border-radius: 4px 4px 0px 0px !important;
                        }
                    }
                }
            }
            .k-grid {
                .columnd-header-icon {
                    font-size: 12px;
                }
                .facility-exclamator{
                    padding-left: 5px;
                    color: #d91c03;
                }
                .error-icon-status{
                    cursor: pointer;
                }
            }
        }
    }
}