.edit-address-detail-model-wrapper {
    .display-inline{
        display: flex;
    }
    .recipient-wrapper-class,
    .country-wrapper-class {
        width: 250px;
    }
    .street-address-one-wrapper-class,
    .street-address-two-wrapper-class {
        width: 450px;
    }
    .chk-street-address {
        margin: 19px 0px 0px 25px;
    }
    .city-wrapper-class {
        width: 200px;
    }
    .state-wrapper-class {
        width: 300px;
    }
    .zip-wrapper-class {
        width: 125px;
    }
}
