.wms-model-new-ui-wrapper {
    .available-rates-model-wrapper {
        .flex-container {
            display: flex;
            padding: 12px;
        }
        .flex-item-left {
            flex-basis: 50%;
            text-align: left;
            padding: 10px 30px;
            color: #222;
        }
        .flex-item-right {
            flex-basis: 50%;
            text-align: right;
            padding: 10px 30px;
            color: red;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 600;
        }
        .wms-form-grid-holder {
            margin-top: 0;
        }
        .footer-btn-holder {
            height: 91px;
            min-height: 91px;
            .wms-button-component {
                margin-bottom: 0;
            }
        }
        .grid-section {
            border-top: 1px solid #eeeeee;
            padding: 30px 30px;
            height: calc(100% - 40px);
            .form-grid {
                display: flex !important;
                flex: 1;
                height: 100%;
                flex-direction: column;
                min-height: 200px;
                max-height: 320px;
                border-radius: 4px;
                .k-grid-content {
                    height: auto;
                    flex: 1;
                    background: none;
                }
            }
        }
        .model-content-wrapper {
            padding: 0px !important;
        }
    }
}
