@import "../../../scss/variable";
@import "../../../scss/mixin";


.item-copy-rate-wrapper{
    background: white !important;
    display: flex;
    flex-direction: column;
    .wms-model-footer{
        justify-content: center;
        display: flex;
    }
    .copy-rate-input-holder{
        display: flex;
        .wms-input-component { width:50%; }
        .wms-dropdown-component { width:33%; }
    }
    .model-content-wrapper {
        .wms-grid-wrapper {
            margin: 0;
            .wms-grid-holder {
                min-height:unset;
                max-height:unset;
                .k-grid-content {
                    height: 100% !important;
                }
            }
        }
    }
}