@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";

#manageReceiptAddNewCustomField {
    .wms-container-wrapper {
        margin-top: 10px;

        .wms-input-component {
            width: 100%;
        }
    }
}