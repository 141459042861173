@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-timepicker-component-proto-kendo {
    .wms-timepicker-disable{
        cursor: default;
        opacity: .6;
        pointer-events: none;
        outline: none;
        filter: grayscale(0.1);
        input:disabled {
            border-color: rgba(0, 0, 0, 0.06);
            background: rgba(0, 0, 0, 0.06);
            opacity: 1;
        }
    }
    position: relative;
    padding: 0px 6px;
    margin-bottom: 14px;
    label {
        @include label;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .timePicker_options {
        .wms-TimePicker-main {
            .k-picker-wrap {
                border-radius: 4px;
                border: 1px solid #bbbbbb;
                span.k-select {
                    background: transparent;
                }
            }
        }
    }
}