@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.wms-checkbox-component {
    &.wms-check-box-disable {
        opacity: 0.2;
        cursor: not-allowed;
        pointer-events: none;
    }
    &.wms-rounded-checkbox {
        float: left;
        padding: 0 5px;
        input[type="checkbox"]:not(:checked) {
            border: 1px solid #a9aaab;
        }
        input[type="checkbox"]:checked {
            border: 1px solid var(--primary-themecolor);
        }
        input[type="checkbox"]:focus {
            border: 2px solid var(--primary-themecolor);
        }
        &.black_Chk {
            input[type="checkbox"]:not(:checked) {
                border: 1px solid #222222;
            }
            input[type="checkbox"]:checked {
                border: 1px solid #222222;
            }
            .option-input {
                &:checked::before {
                    background-color: #222222;
                }
                &:checked::after {
                    background: #222222;
                }
            }
        }
        .option-input {
            background: white;
            border: 1px solid #d8e5ee;
            border-radius: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            height: 20px;
            width: 20px;
            transition: all 0.15s ease-out 0s;
            cursor: pointer;
            display: inline-block;
            margin-right: 0.5rem;
            outline: none;
            position: relative;
            z-index: 1;
            vertical-align: middle;
            margin: 6px 10px 10px 0;
            min-width: 20px;
            &:checked::before {
                height: 10px;
                width: 10px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                position: absolute;
                content: "";
                display: inline-block;
                background-color: var(--primary-themecolor);
                border-radius: 2px;
            }

            &:checked::after {
                animation: select-click-wave 0.65s;
                content: "";
                display: block;
                position: relative;
                z-index: 100;
                background: var(--primary-themecolor);
                border-radius: 4px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        label {
            cursor: pointer;
            color: $input-color;
            font-weight: 700;
            font-size: 14px;

            :local(.option_input) {
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                appearance: none;
                height: 28px;
                width: 28px;
                transition: all 0.15s ease-out 0s;
                background: white;
                border: 1px solid #a9aaab;
                cursor: pointer;
                display: inline-block;
                margin: 16px 8px 16px 16px;
                outline: none;
                position: relative;
                z-index: 1;
                vertical-align: middle;
                border-radius: 50%;
            }

            &.wms-disable-label {
                opacity: 0.6;
                cursor: not-allowed;
                pointer-events: none;
            }
            .tooltip-wrapper {
                transition: opacity 0.4s;
                visibility: hidden;
                opacity: 0;
                position: fixed;
                height: auto;
                width: auto;
                max-width: 300px;
                max-height: 300px;
                font-size: 14px;
                color: white !important;
                font-weight: 100;
                z-index: 12000;
                div {
                    background: black !important;
                    border: 1px solid black !important;
                    padding: 4px 8px;
                    border-radius: 10px;
                    &:before {
                        content: "\A";
                        border-style: solid;
                        border-width: 11px 11px 0px 20px;
                        border-color: transparent black transparent transparent;
                        position: absolute;
                        -webkit-transform: rotate(-135deg);
                        left: 45%;
                        top: 2px;
                    }
                }
            }
            &:hover .tooltip-wrapper {
                visibility: visible;
                opacity: 1;
            }
        }
        .div_disable1 {
            .div_disable {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                cursor: not-allowed;
                height: 40px;
                display: inline-block;
            }
            .div_tooltip {
                position: absolute;
                top: 40px;
                left: 0;
                min-width: 180px;
                z-index: 12;
                font-size: 14px;
                padding: 4px 8px;
                background: black !important;
                color: white !important;
                border: 1px solid black !important;
                &:before {
                    content: "\A";
                    position: absolute;
                    top: 1px;
                    left: 30px;
                    border-style: solid;
                    border-width: 11px 11px 0px 20px;
                    -webkit-transform: rotate(-135deg);
                    border-color: transparent black transparent transparent;
                }
            }

            .div_tooltip:before {
                content: "\A";
                border-style: solid;
                border-width: 11px 11px 0px 20px;
                position: absolute;
                -webkit-transform: rotate(-135deg);
                left: 75px;
                top: 1px;
            }
        }
    }
    &.wms-toggle-checkbox {
        input {
            --focus: 2px rgba(39, 94, 254, 0.3);
            --border: #bbc1e1;
            -webkit-appearance: none;
            -moz-appearance: none;
            --ab: #bbbbbb;
            --left: 1px;
            height: 21px;
            outline: none;
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin: 0;
            cursor: pointer;
            border: 1px solid var(--bc, var(--border));
            background: #fff;
            transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
            &:after {
                content: "";
                display: block;
                left: var(--left);
                top: 1px;
                position: absolute;
                background: var(--ab) 0% 0% no-repeat padding-box;
                border: 1px solidv var(--ab);
                border-radius: 10px;
                transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            }
            &:focus {
                border: 1px solid #81c92e !important;
            }
            &.switch {
                width: 30px;
                height: 20px;
                border-radius: 11px;
                &:after {
                    left: var(--left);
                    top: 1px;
                    border-radius: 10px;
                    width: 16px;
                    height: 16px;
                    background: var(--ab, var(--border));
                    transform: translateX(var(--x, 0));
                }
                &:checked {
                    --ab: #81c92e;
                    --x: 8px;
                    --left: 3px;
                }
            }
        }
    }
    &.disabled-chk,
    &.disabled-option {
        opacity: 1 !important;
        .option-input,
        span {
            opacity: 0.2;
        }
    }

    // &.wms-square-checkbox {
    //     float: left;
    //     line-height: 40px;
    //     margin-bottom: 5px;
    //     padding: 0 5px;
    //     position: relative;

    //     &:focus {
    //         box-shadow: inset 0 0 0 1px $primary-themecolor;
    //         outline: none;
    //     }

    //     .option-input {
    //         -webkit-appearance: none;
    //         -moz-appearance: none;
    //         -ms-appearance: none;
    //         -o-appearance: none;
    //         appearance: none;
    //         height: 30px;
    //         width: 30px;
    //         transition: all 0.15s ease-out 0s;
    //         background: $checked-bg;
    //         border: 1px solid $input-border;
    //         cursor: pointer;
    //         display: inline-block;
    //         margin-right: 0.5rem;
    //         outline: none;
    //         position: relative;
    //         z-index: 1;
    //         vertical-align: middle;
    //     }

    //     .option-input:checked::before {
    //         height: 30px;
    //         width: 30px;
    //         position: absolute;
    //         content: '✔';
    //         display: inline-block;
    //         font-size: 16px;
    //         text-align: center;
    //         line-height: 28px;
    //         color: $window-title;
    //     }

    //     .option-input:checked::after {
    //         -webkit-animation: click-wave 0.65s;
    //         -moz-animation: click-wave 0.65s;
    //         animation: click-wave 0.65s;
    //         content: '';
    //         display: block;
    //         position: relative;
    //         z-index: 100;
    //         background: $checked-wavebg;
    //     }

    //     label {
    //         cursor: pointer;
    //         display: block;
    //         color: $input-color;
    //         word-break: break-word;
    //         text-overflow: ellipsis;
    //         overflow: hidden;
    //     }

    //     label.disabled-checkbox {
    //         opacity: 0.6;
    //         cursor: not-allowed;
    //         pointer-events: none;
    //     }
    // }
}
