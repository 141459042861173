
.wms-inventory-model-wrapper {
    display: flex;
    width: 100%;
    // margin-top: 25px;

    .wms-hide {
        visibility: hidden;

        &.wms-show {
            visibility: visible !important;
        }
    }

    .wms-container-wrapper {
        .wms-area-content {
            .title {
                margin-left: 10px;
            }

            .wms-area-content-darker {
                border-width: 3px;
                border-radius: 14px;

                .description_box {
                    margin-bottom: 20px;
                    display: inline-block;
                    width: 100%;

                    .wms-input-component {
                        width: 100%;
                    }

                    .wms-input-component {
                        width: 75%;
                    }
                }

                .wms-mb-20 {
                    margin-bottom: 20px;
                    display: inline-block;
                    width: 100%;

                    .input-required {
                        input {
                            border: 1px solid #d79093 !important;
                        }
                    }
                }
            }
        }
    }

    .inventory-transfer-source {
        margin-right: 30px;
        width: 50%;
        display:table;
        .tooltip-div-group{
            display: flex;
            .wms-tooltip-component{
                position: relative;
                margin-top: 13px;
            }
        }
    }

    .inventory-transfer-destination {
        width: 50%;
        display:table;
        .tooltip-div-group{
            display: flex;
            width: 100%;
            .wms-tooltip-component{
                position: relative;
                margin-top: 13px;
                .tooltip-main {
                    left: -100px;
                }

                .tooltip-main::before {
                    left: 106px;
                }
            }
        }
        .loading-section {
            // position: relative;

            .k-loading-mask {
                background: white;
            }
        }
    }

    .wms-kendo-dropdown-list-component {
        width: 75%;
        padding: 0px 7px;
        margin-bottom: 8px;
        display: inline-block;
    }

    .desctipion_box {
        .wms-input-component {
            width: 100%;
        }
    }

    .blank-div-height48 {
        height: 48px;
        display: block;
        position: relative;
        float: left;
        width: 100%;
    }

    .wms-input-component,
    .wms-dropdown-component {
        width: 75%;

        .form_group::before,
        .dl_input_sl::before {
            left: 0;
            right: 0;
        }
    }

    .wms-dropdown-component {
        .form_group {
            &::before {
                left: 7px;
                right: 7px;
            }
        }
    }

    .error-text {
        color: red;
        margin-right: 12px;
        margin-bottom: 11px;
        float: right;
    }

    .wms-error {
        color: red;
        padding: 10px 5px;
        font-size: 18px;
        float: left;
        cursor: pointer;
    }
}
