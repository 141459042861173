.billoflading {

    #orderInformationSection_main {
        margin-bottom: 0px;
    }
    .bol-dropdown-wrapper{
        width: 40%;
    }
    .general-section-wrapper{
        display: flex;
        flex-direction: column;
        .content-wrapper{
            display: flex;
            .wms-checkbox-component{
                margin-top: 17px;
            }
            .carrier-dropdown-wrapper{
                width: 33.33%;
            }
            .scacode-input-wrapper{
                width: 20%;
            }
            .service-level-dropdown-wrapper,
            .billing-type-dropdown-wrapper{
                width: 23%;
            }
            .trailer-input-wrapper,
            .seal-input-wrapper{
                width: 23%;
            }
            .tracking-input-wrapper{
                width: 40%;
            }

            .shipping-textarea-wrapper {
                padding: 0 6px;
            }
        }
    }
    .order-info-section-wrapper{
        display: flex;
        .wms-input-component {
            width: 20%;
        }
    }
}