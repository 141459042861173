@import "../../../../../../scss/variable";
@import "../../../../../../scss/mixin";
#proto-mass-add{
    .model-content-wrapper{
        display: flex;
    }
}
#proto-mass-add-holder-react {
    width: 100%;
    //height: 100%;
    display: flex;
    .wms-kendo-dropdown-list-component {
        .k-dropdown{
            background-color: white;
        }
    }
    &.collapsedFilter {
        .manage-inventory-search-holder {
          overflow: hidden;
          animation: hideMassAddFilterSection 1s forwards;

          .filter {
            width: 40px;
            height: 100%;

            .fa-caret-left {
                transform: rotate(180deg);
                transition: all 0.4s;
                -webkit-transition: all 0.4s;
                -moz-transition: all 0.4s;
                padding: 13px 17px;
                @include desktop_1280 {
                    padding: 13px 20px;
                }
            }
            span {
                text-transform: uppercase;
                font-size: 12px;
                transform: rotate(90deg);
                display: inline-block;
                width: 120px;
                position: absolute;
                top: 90px;
                left: -40px;
            }
        }
        }
    }
    @keyframes hideMassAddFilterSection {
        0% {
          margin-left: 0;
        }
        50% {
          margin-left: -420px;
        }
        100% {
          margin-left: -385px;
        }
    }

    @keyframes showMassAddFilterSection {
        0% {
            margin-left: -385px;
        }
        50% {
            margin-left: -420px;
        }
        100% {
            margin-left: 0;
        }
    }
    .sectionToggled {
        animation: showMassAddFilterSection 1s forwards;
    }
    &.is-proto-mass {
        .manage-inventory-search-holder {
            width: 420px;
            min-width: 420px;
            max-width: 420px;
            padding-right: 0;
        }
    }
    .manage-inventory-search-holder {
        width: 400px;
        min-width: 400px;
        max-width: 400px;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #b7d2e5;
        background: rgba(0, 0, 0, 0.03);
        position: relative;
        transition: all 1s linear;

        .inventory-filter {
            height: 100%;
            display: flex;
            flex-direction: column;

            .default-section {
                margin: 15px 0;
                padding: 0px 15px 0px 5px;

                .wms-button-component {
                    margin-bottom: 15px;
                }

                .select-field {
                    width: 100%;
                    float: left;
                    border-bottom: 1px solid #b7d2e5;

                    .wms-dropdown-component {
                        max-width: 275px;

                        @include desktop_1600 {
                            max-width: 225px;
                        }
                    }
                }
            }

            .filter-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;

                .btn-section {
                    margin-top: 5px;
                }

                .title {
                    width: 100%;
                    float: left;
                    margin: 8px 0 10px 2px;

                    label {
                        font-size: 11px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;
                        color: #5a7e9d;
                    }
                }

                .warehouse-form-boxes {
                    flex: 1;
                    padding: 0px 15px 0px 5px;
                    transition: all ease-in-out 0.3s;
                    overflow: auto;
                    .warehouse-are-content {
                        display: inline-block;
                        border-radius: 4px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        .title {
                            width: 100%;
                            float: left;
                            margin: 0 0 8px 0;
                            label {
                                font-size: 11px;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                font-weight: 600;
                            }
                        }
                        .wms-collapsible-div {
                            .wms_collapsible_header {
                                .wms-title {
                                    padding: 5px 12px 0 20px;
                                }
                            }
                            .wms-collapsible-content {
                                margin-bottom: 0;
                                padding: 15px 5px 5px;
                                .wms-dropdown-component {
                                    width: 100%;
                                }
                                .wms-input-group-wrapper{
                                    .wms-dropdown-component {
                                        width: 125px;
                                    }
                                }
                                .wms-kendo-dropdown-list-component {
                                    width: 100%;
                                    flex-direction: row ;
                                    .form_group {
                                        float: left;
                                        position: relative;
                                        padding: 0px 7px;
                                        margin-bottom: 11px;
                                    }
                                }
                                ul#MultiSelectCustomerSkus_taglist {
                                    position: absolute;
                                }
                                .wms-datetimepicker-component {
                                    .border {
                                        width: 100%;
                                    }
                                }
                                .wms-input-component {
                                    width: 100%;
                                }
                            }
                        }
                        .form-group {
                            width: 100%;
                            float: left;
                            padding: 0px;
                            margin-bottom: 15px;
                        }
                        .wms_area_border {
                            height: 100%;
                            overflow: hidden;
                            padding: 0 0 26px 0;
                            #gridContainer {
                                height: 100%;
                            }
                        }

                        .filter-list-block {
                            padding-bottom: 8px;
                            margin-top: -4px;
                            li.filter-list {
                                display: flex;
                                height: 48px;
                                margin: 0px 7px 12px;
                                background-color: transparent;
                                border-radius: 4px !important;
                                padding: 13px 5px 13px 12px;
                                border: 1px solid #bbbbbb;
                                a.title-text {
                                    // position: relative;
                                    white-space: nowrap;
                                    width: 100px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    // display: inline-block;
                                    flex: 1;
                                }
                                .wms-apply-btn {
                                    // float: right;
                                    margin: -6px 0px;
                                    // .wms-button-dropdown-component {
                                    //     margin: 0;
                                    //     .wms_dropdown_button {
                                    //         .k-animation-container {
                                    //             ul {
                                    //                 min-width: 0;
                                    //             }
                                    //         }
                                    //     }
                                    // }
                                    .wms-button-component {
                                        margin-right: 5px;
                                        .button_holder {
                                            .wms_toolbar_button {
                                                .wms_toolbar_button_text {
                                                    font-size: 12px;
                                                    line-height: 25px;
                                                }
                                            }
                                        }
                                    }
                                    .wms-button-component:last-child  {
                                        margin-right: 0px;
                                    }
                                }
                                .clear-save-filter {
                                    order: 1;
                                    padding: 2px 5px;
                                    cursor: pointer;
                                    transition: all 0.3s ease-in-out;
                                    i {
                                        font-size: 16px;
                                        color: rgba(255, 0, 0, 0.2);
                                        transition: all 0.3s ease-in-out;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .wms-dropdown-component,
            .wms-input-component {
                width: 100%;
            }
        }

        .filter {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            border-radius: 0px;
            border: 0px;
            z-index: 1;
            background: #cee1f2;
            cursor: pointer;
            &:hover {
                background: #0079c4;

                .fa-caret-left:before, span {
                    color: #fff;
                }
            }
            .fa-caret-left {
                font-size: 16px;
                padding: 4px 8px;
            }
            span {
                display: none;
            }
        }
    }

    .inventory-grid {
        flex: 1;
        overflow-x: auto;
        margin: 17px 0;

        .wms-inventory-grid-holder {
            height: 100%;
            display: flex;
            flex-direction: column;
            .wms-virtual-grid-holder {
                flex: 1;
                .wms_area_content {
                    .wms-toolbar-button {
                    display: flex;
                    justify-content: flex-end;
                    float: right;

                    .wms-button-component {
                        margin-right: 15px;
                    }
                    }
                }

                .wms-grid-area-wrapper{
                    max-height: none;
                    min-height: 300px;
                }
            }
        }
    }
}

.assign-roles-model {
    display: inline-block;
    width: 100%;
    .wms-container-wrapper {
        .wms-area-content{
            .wms-area-content-darker{
                padding: 0px;
                .wms-combobox-component{
                    padding: 5px;
                }
            }
        }
    }
    .wms-area-content {
        .wms-area-content-darker {
            .wms-dropdown-component {
                width: 100%;
            }
        }
    }
}

#saveCurrentProtoMassAddInventoryFilter {
    .save-filter-button-wrapper {
        display: inline-flex;
        .wms-button-component  {
            .button_holder_proto {
                display: inline-block;
            }
        }
    }
    .wms-area-content {
        margin-top: 15px;
        .wms-area-content-darker {
            padding: 12px;
            .wms-dropdown-component {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}