#itemsExportModal {
    .model-content-wrapper {
        .wms-collapsible-div {
            .wms-collapsible-content {
                padding: 0;
                hr {
                    border: 0;
                    border-top: 1px solid #eeeeee;
                }
                .message-section {
                    margin: 20px;
                    font: normal normal normal 11px/15px Open Sans;
                    letter-spacing: 0px;
                    color: #222222;
                }
                .file-format-section {
                    display: flex;
                    align-items: center;
                    margin: 20px;
                    .wms-dropdown-component {
                        width: 206px;
                        margin-right: 25px;
                        label,
                        .form_group {
                            padding-left: 0px;
                        }
                    }
                }
            }
        }
    }
}
