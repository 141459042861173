@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.manage-order-proto-holder {
    margin-right: 16px;
    height: 100%;

    &:not(.manage-order-proto-holder-newtab) {
        .customer-options,
        .facility-options,
        .reference-number-input,
        .transaction-input {
            width: 180px !important;
        }
        .purchase-order-option {
            width: 280px !important;
        }
        .wms-datetimepicker-component-proto-kendo {
            width: 273px;
        }
        .wms-collapsible-div {
            padding: 0px 30px 0px 30px;
            box-shadow: none;
            .wms_collapsible_header {
                border: 1px solid #f5f5f5;
            }
            .wms-collapsible-content {
                border: 1px solid #f5f5f5;
                .wms-button-component {
                    margin-left: 0 !important;
                }
            }
        }
        .tab-listing {
            display: flex;
            justify-content: center;
            padding: 25px 0px 20px 0px;
            border-top: 1px solid #eeeeee;
            order: 3;
            ul {
                li {
                    label {
                        @include desktop_1600 {
                            font-size: 12px !important;
                            padding: 16px 8px !important;
                        }
                    }
                }
            }
        }

        .order-info-main-wrapper {
            flex-wrap: wrap;
            display: flex;
        }
        #manageOrderCarrier_main {
            .wms-collapsible-content {
                .order-info-tracking-number {
                    width: 380px !important;
                }
            }
        }
        @include desktop_1440 {
            .wms-order-line-items-wrapper {
                .wms-collapsible-content {
                    .wms_area_content {
                        .wms_toolbar {
                            flex-direction: column;
                        }
                    }
                }
            }

            #manageOrderSmallParcelShipping_main {
                .wms-collapsible-content {
                    .wms-checkbox-component {
                        width: 26% !important;
                    }
                }
            }
        }
    }

    .wms-top-toolbar-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .message-sections {
            min-height: 30px;
            padding: 3px 0;
            margin-top: -12px;
            .message-txt {
                color: $redbtn;
                font-size: 14px;
                letter-spacing: 0.5px;
                font-weight: 600;
                width: 100%;
                display: inline-block;
                text-align: center;
            }
        }

        .wms-top-toolbar-btn-wrapper {
            display: flex;
            width: 100%;
            margin-bottom: 8px;
            flex-direction: row-reverse;
            .wms-button-component {
                display: inline-block;
            }
        }
        // contact Information Section
        .contact-Info-wrapper {
            display: flex;
            flex-direction: column;
            .wms-checkbox-component {
                margin-top: 11px;
            }
        }
        .wms-order-line-items-wrapper {
            .lable {
                display: block;
                width: 100%;
            }
            .wms-collapsible-content {
                .wms-container-wrapper {
                    .wms-area-content {
                        .wms-area-content-darker {
                            .wms-button-component {
                                display: inline-block;
                                width: 100%;
                                padding-top: 12px;
                                padding-bottom: 5px;
                                margin-left: 7px;
                            }

                            .wms_area_border {
                                display: inline-block;
                                width: 100%;
                                height: 245px;
                                margin-left: 8px;
                                margin-right: 8px;
                            }
                        }
                    }
                }
                .wms-virtual-grid-holder {
                    margin: 0 0 30px !important;
                    .wms-grid-area-wrapper {
                        min-height: unset;
                    }
                    .wms-message-wrapper {
                        display: none;
                    }
                    #OrderItemsGrid {
                        border: 1px solid #00000014;
                        border-radius: 4px 4px 0px 0px;
                        .k-grid-header {
                            thead {
                                tr {
                                    th {
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                        .k-grid-content {
                            tbody {
                                .k-command-cell {
                                    padding: 0;
                                    .charge-delete {
                                        border: none;
                                        color: red;
                                        background-color: unset !important;
                                        background-image: unset !important;
                                        padding: 0;
                                        &:hover {
                                            background-color: unset !important;
                                            background-image: unset !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .wms_toolbar {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                    }
                    .wms-toolbar-button {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
                .fulfillment-invoicing-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    .fulfillment-left-section,
                    .fulfillment-right-section {
                        display: flex;
                        flex-direction: column;
                        .wms-input-component {
                            width: 100%;
                            margin-bottom: 9px;
                            .form_group {
                                display: flex;
                            }
                        }
                    }
                    .fulfillment-right-section {
                        .wms-input-component {
                            label {
                                text-align: right;
                                padding: 10px;
                            }
                        }
                        .bold-input-label {
                            label {
                                font-weight: bold;
                                letter-spacing: 0;
                                color: #222222;
                            }
                        }
                    }
                    .fulfillment-left-section {
                        justify-content: flex-end;
                        width: 35%;
                        .wms-input-component {
                            .form_group {
                                label {
                                    padding-top: 9px;
                                    width: 120px;
                                }
                            }
                        }
                        .gift-message-input {
                            input {
                                width: 70%;
                            }
                        }
                        .discount-code-input {
                            input {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
        .wms-grid-wrapper {
            .wms-grid-holder {
                min-height: 200px;
            }
        }

        .wms-dropdown-component,
        .wms-combobox-component,
        .wms-input-component {
            width: 24.5%;
            float: left;
        }

        .wms-customer-order-details-wrap {
            width: 100%;

            .wms-dropdown-component,
            .wms-combobox-component,
            .wms-input-component {
                width: 33.33%;
                @include desktop_1280 {
                    width: 50%;
                }
            }
        }
    }
    .wms-dropdown-component,
    .wms-combobox-component,
    .wms-input-component {
        width: 25%;
    }
    .form_clause {
        position: relative;
        display: flex;
        flex-direction: row;
        .tab-listing {
            padding: 0 0 10px 0;
            text-align: center;
        }
    }
    .wms-collapsible-div {
        margin-bottom: 15px;

        .wms-combobox-component {
            .k-dropdown-wrap {
                border-radius: 4px;
                input {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }
    #ManageOrdersScrollBox {
        overflow: auto;
        flex: 1;
        .collapseOrderTabContent_wrapper{
            display: flex;
            flex-direction: column;
        }
        #contactTabStrip {
            .k-content {
                padding: 30px 20px;
            }
        }
    }
    .contact-detail-section {
        .title-block {
            border: none;
            background: transparent;
            margin: 0px 0 0;
            padding: 0;
            width: 100%;
            float: left;
            .wms-kendo-dropdown-list-component {
                width: 25%;
                margin: 4px 10px 10px 0;
                max-height: 100px;
                float: left;
            }
            .wms-button-component {
                margin-top: 16px;
                padding: 0 6px;
                display: inline-block;
            }
        }
        .wms-container-wrapper {
            margin-top: 15px;
        }
        .wms-input-component,
        .wms-dropdown-component {
            width: 25%;
            @include desktop_1600 {
                width: 25%;
            }
        }
    }
    .carrier-and-routing-section {
        .disable-section {
            opacity: 0.2;
            .wms-collapsible-div {
                .wms-collapsible-content {
                    pointer-events: none;
                }
            }
        }
        .wms-collapsible-content {
            padding: 15px 0;
            .display-inline {
                display: flex;
                flex-wrap: wrap;
                padding: 15px 14px 15px 14px;
                .wms-text-area-component {
                    padding: 0 6px;
                }
            }
            flex-direction: column;
            .order-info-carrier,
            .order-info-service {
                width: 250px;
            }
            .order-info-scac {
                width: 175px;
            }
            .order-info-billing-code {
                width: 132px;
            }
            .order-info-account-zip {
                width: 130px;
            }
            .order-info-tracking-number {
                flex-basis: 100%;
                .form_group {
                    width: 500px;
                }
            }
            .order-info-warehouse-instruction {
                width: 50%;
            }
            .order-info-carrier-instruction {
                width: 50%;
            }
            textarea {
                height: 100px;
                width: 100%;
            }
            .shipping-three-checkbox {
                .wms-checkbox-component {
                    margin-right: 50px;
                }
                .chk-require-return-label {
                    width: 205px;
                }
                .chk-saturday-delivery-flag {
                    width: 170px;
                }
                .chk-residential-delivery {
                    width: 185px;
                }
            }
            .chk-is-insured {
                margin-right: 50px;
            }
        }
        #manageOrderSmallParcelShipping_main {
            .display-inline:not(:last-child) {
                border-bottom: 1px solid #eeeeee;
                // padding: 15px 0px 15px 15px;
                align-items: center;
            }
        }
        .order-routing-load-number,
        .order-routing-bol-number,
        .order-routing-trailer-number,
        .order-routing-seal-number {
            width: 150px !important;
        }
        .order-routing-door {
            width: 100px !important;
        }
        .order-small-delivery-confirmation-type {
            width: 270px !important;
        }
        .order-capacity-type {
            width: 300px !important;
        }
        .wms-datetimepicker-component-proto {
            width: 295px !important;
        }
        .order-insurance-type {
            width: 250px !important;
        }
        .order-insurance-amount {
            width: 140px !important;
            .extra-sign {
                top: 25px;
            }
        }
        .order-small-parcel-COD-type {
            width: 200px !important;
        }
        .order-small-parcel-dry-ice-weight {
            width: 130px !important;
        }
        .order-small-parcel-init-content-type {
            width: 150px !important;
        }
        .order-small-parcel-init-non-delivery {
            width: 245px !important;
        }

        // ======================================

        .wms-dropdown-component,
        .wms-combobox-component,
        .wms-input-component {
            width: 25%;
        }
        .wms-datetimepicker-component {
            width: 50%;
            float: left;
            .border {
                width: 50%;
            }
        }
        .wms-content-wrapper {
            display: inline-block;
            width: 100%;
            .txtarea-list {
                width: 50%;
                padding-left: 7px;
                float: left;
                margin-bottom: 10px;
                .wms-text-area-component {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 2px;
                    margin-right: 10px;
                }
            }
        }
        .wms-checkbox-component {
            width: auto;
            display: block;
            float: none;
            margin-bottom: 0;
            padding: 0 6px;
        }
        .accountSelectedTextHolder {
            width: 300px;
            float: left;
            .wms-input-component {
                width: 100%;
            }
            .accountSelectedText {
                position: relative;
                left: 10px;
                font-size: 16px;
                top: -8px;
            }
        }
    }
    .calculate-charge-section {
        .wms_area_border {
            position: relative;
            padding: 0px;
            top: 0px;
        }
        #GridAppliedCharges {
            height: 400px !important;
        }
        .wms-input-component {
            width: 33.33%;
            float: right;
            margin: 10px 9px 0;
            padding: 0px;
            max-height: 100px;
        }
    }
}

// css for manage order in new tab
.manage-order-proto-holder-newtab {
    margin-right: 0px;
    .wms-top-toolbar-wrapper {
        .message-sections {
            min-height: 40px;
            padding: 3px 0;
            .message-txt {
                color: $redbtn;
                font-size: 14px;
                letter-spacing: 0.5px;
                font-weight: 600;
                width: 100%;
                display: inline-block;
                text-align: center;
            }
        }
        .form_clause {
            flex-direction: column;
            display: block;
            .tab-listing {
                text-align: unset;
                width: auto;
                float: left;
            }
        }
        @include desktop_1440 {
            .form_clause {
                .tab-listing {
                    width: 100%;
                }
                .order-info-main-wrapper {
                    flex-wrap: wrap;
                    display: flex;
                }
            }
        }

        .wms-top-toolbar-btn-wrapper {
            float: right;
            width: auto;
            .wms-button-component {
                float: left;
            }
        }
        .wms-dropdown-component,
        .wms-combobox-component,
        .wms-input-component {
            width: 12.5%;
            float: left;
            @include desktop_1440 {
                width: 12.5%;
            }
            @include desktop_1280 {
                width: 24.5%;
            }
        }
        .wms-combobox-component {
            .k-combobox {
                border-radius: 4px;
            }
        }
        .fulfillment-invoicing-wrapper {
            display: flex;
            justify-content: flex-end;
            .fulfillment-left-section,
            .fulfillment-right-section {
                display: flex;
                flex-direction: column;
                .wms-input-component {
                    width: 100%;
                    .form_group {
                        display: flex;
                    }
                }
            }
            .fulfillment-right-section {
                .wms-input-component {
                    label {
                        text-align: right;
                        padding: 10px;
                        font-size: 14px;
                    }
                    .extra-sign {
                        position: relative;
                        top: 8px;
                    }
                    input {
                        padding-left: 15px !important;
                    }
                }
                .bold-input-label {
                    label {
                        font-weight: bold;
                        letter-spacing: 0;
                        color: #222222;
                    }
                }
            }
            .fulfillment-left-section {
                justify-content: flex-end;
                width: 35%;
                .wms-input-component {
                    .form_group {
                        label {
                            padding-top: 9px;
                            text-align: right;
                            padding-right: 15px;
                            font-size: 14px;
                        }
                    }
                }
                .gift-message-input {
                    input {
                        width: 70%;
                    }
                }
                .discount-code-input {
                    input {
                        width: 50%;
                    }
                }
            }
        }
        .wms-customer-order-details-wrap {
            width: 54%;
            @include desktop_1440 {
                width: 85%;
            }
            @include desktop_1280 {
                width: 100%;
            }
            .wms-dropdown-component,
            .wms-combobox-component,
            .wms-input-component {
                width: 26%;
            }
        }
        .wms-datetimepicker-component {
            width: 15%;
            float: left;

            @include desktop_1280 {
                width: 25%;
            }

            &::before {
                right: 40px;
                background-color: #ffffff;
            }

            .form_group {
                width: 100%;
            }
        }
    }
    .wms-dropdown-component,
    .wms-combobox-component,
    .wms-input-component {
        width: 25%;
    }
    .disable-options {
        opacity: 0.2;
    }
    .contact-detail-section {
        .title-block {
            border: none;
            background: transparent;
            margin: 0px 0 0;
            padding: 0;
            width: 100%;
            float: left;
            .wms-kendo-dropdown-list-component {
                width: 25%;
                margin: 4px 10px 10px 0;
                max-height: 100px;
                float: left;
            }
            .wms-button-component {
                margin-top: 17px;
                padding: 0 6px;
                display: inline-block;
            }
        }
        .wms-input-component,
        .wms-dropdown-component {
            width: 25%;
            @include desktop_1600 {
                width: 25%;
            }
        }
    }
    .carrier-and-routing-section {
        .order-info-warehouse-instruction {
            width: 459px !important;
        }
        .order-info-carrier-instruction {
            width: 459px !important;
        }
        textarea {
            height: 100px;
            width: 100%;
        }
    }
    .calculate-charge-section {
        .wms_area_border {
            position: relative;
            padding: 0px;
            top: 0px;
        }
        #GridAppliedCharges {
            height: 400px !important;
        }
        .wms-input-component {
            width: 33.33%;
            float: right;
            margin: 10px 9px 0;
            padding: 0px;
            max-height: 100px;
        }
    }
}
