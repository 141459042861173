@import "../../../scss/variable";
@import "../../../scss/mixin";
#location-holder-react {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 !important;
    .locations-main-content {
        width: 100%;
        flex: 1;
        position: relative;
        margin-left: 5px;
        margin-top: 4px;
        display: flex;
        .locationRefreshBtn {
            .blue_button_color {
                padding: 0px 16px;
                .wms_toolbar_button_text {
                    display: none;
                }
            }
        }
        .location-grid {
            width: 46.5%;
            height: 100%;
            padding: 30px;
            background: rgba(0, 0, 0, 0.03);
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            .location-topbtn-holder {
                .search-applied {
                    float: right;
                    margin: 8px 0 10px 2px;
                    label {
                        color: #5a7e9d;
                        font-size: 11px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;
                    }
                }
            }
            .wms-collapsible-div {
                margin: 25px 0 20px;
                .wms-input-group-wrapper {
                    width: 50%;
                    .wms-dropdown-component{
                        width: 50%;
                    }
                }
            }

            .wms-location-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                .wms-grid-toolbar {
                    flex-wrap: wrap-reverse;

                    .wms-grid-checkbox-content {

                        @include desktop_1440 {
                            width: 100%;
                        }
                    }

                    .wms-toolbar-button {

                        .wms-button-component {
                            @include desktop_1280 {
                                margin: 0 5px 5px 0;
                            }
                        }
                    }
                }
                .wms-grid-wrapper {
                    .wms-grid-top {
                        margin-top: 0px;
                    }
                }
            }

        }
        .location-form {
            position: relative;
            flex: 1;
            width: 53.5%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .loc-form-header{
                display: flex;
                width: 100%;
                align-items: center;
                flex-wrap: wrap-reverse;
                padding: 15px 30px 14px 30px;
                justify-content: space-between;
            }
                .tab-content {
                    overflow-y: scroll;
                    padding: 0 14px 0px 30px;
                    flex: 1 1 auto;

                    #GridLocationZones {
                        &.k-grid {
                            .k-grid-content {
                                top: 0px !important;
                                height: 159px !important;
                            }
                        }
                    }
                    .wms-input-label {
                        margin-left: 7px !important;
                        @include label;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .measurement-wrapper,
                    .quantity-wrapper {
                        .wms-input-component  {
                            width: 20%;
                        }
                    }
                    .wms_small_input {
                        width: 33.33%;
                        float: left;
                        .wms-input-component {
                            width: 23.5%;
                            padding-right: 0px;
                            .dl_input_sl {
                            .inputlabel {
                                display: none;
                            }
                            input {
                                width: 100%;
                                padding-top: 0px;
                                background-color: transparent;
                                border: 1px solid $border-color;
                                border-radius: 4px !important;
                                height: 32px;
                                font-weight: 600;
                                font-size: 14px;
                                color: $input-color;
                                padding: 0 10px;
                            }
                            }
                            @include desktop_1366 {
                                width: 25%;
                                padding-right: 0px;
                            }
                            @include desktop_1280 {
                                width: 25%;
                                padding-right: 7px;
                            }
                        }
                        @include desktop_1280 {
                            width: 50%;
                        }
                    }
                    .non-pickable-wrapper {
                        width: 33.33%;
                    }
                    .wms-button-component {
                        display: inline-block;
                        margin-top: 10px;
                        padding-left: 10px;
                    }
                    .wms-form-grid-holder {
                        margin-left: 7px;
                    }
                    .GridLocationZones {
                        width: 94%;
                        margin-left: 7px;
                        margin-bottom: 12px;
                    }
                    .wms_col_4 {
                        width: 33.33%;
                        float: left;
                        .wms-input-component {
                            width: 100%;
                            display: block;
                        }
                    }
                    .wms_col_6 {
                        width: 66%;
                        float: left;
                        .wms-input-component {
                            width: 50%;
                        }
                        .checkbox {
                            width: 100%;
                            margin-top: 10px;
                        }
                    }
                    .wms-input-component {
                        @include desktop_1280 {
                            width: 50%;
                        }
                    }
                    .wms-dropdown-component {
                        width: 33.33%;
                        @include desktop_1280 {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }