
@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.new-carrier-connection-wrapper{
    display: flex;
    flex-direction: column;
    .wms-carrier-connection{
        overflow-y: auto;
        flex: 1;
        .wms-collapsible-div{
            .wms-collapsible-content{
                .new-connection-first-element{ width: 28%; }
                .new-connection-second-element{ width: 52%; }
                .new-connection-third-element{ width: 20%; }
                .disabled-facility-opt{
                    opacity: 0.3;
                    cursor: not-allowed;
                    .wms-dropdown-component{
                        select{
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
    .connection-step-block{
        overflow-y: auto;
        flex: 1;
        .image-section {
            margin: auto;
            text-align: center;
            padding-bottom: 10px;
            width: 300px;
            height: 140px;
            max-width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        .default-section {
            padding: 10px 30px;
        }
        .note-section {
            padding: 10px 7px;
        }
        .img-amazon {
            width: 240px;
            height: 75px;
        }
        .amazon-info {
            display: flex;
            flex-direction: column;
            .section-label {
                color: #656565;
                font-weight: bold;
                margin: 12px 0 13PX 0;
            }
            .wms-collapsible-div {
                .wms-collapsible-content {
                    .account-info-section {
                        .amazon-auth-token {
                            width: 80%;
                        }
                        .amazon-email {
                            width: 60%;
                        }
                    }
                }
            }
        }
        .wms-collapsible-div{
            border-radius: 4px;
            .wms-collapsible-content{
                .display-inline{
                    display: inline;
                    .ups-first-name{
                        width: 30% !important;
                    }
                }
                .ups-company-name{
                    width: 26% !important;
                }
                .account-info-section{
                    display: flex;
                    flex-direction: column;
                    .w-42 {
                        width: 42%;
                    }
                    .w-75 {
                        width: 75%;
                    }
                }
                p{
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 5px;
                    color: #7f7f7f;
                    font-weight: 400;
                }

                .ups-eula-agreement {
                    p {
                        margin-top: 5px;
                        margin-bottom: 15px;
                    }
                }
                .wms-checkbox-component{
                    display: inline-block;
                    margin-top: 10px;
                }
                .wms-input-component{
                    &.wms-input-names-box{
                        width: 39%;
                    }
                    &.wms-input-title-box{
                        width: 22%;
                    }
                    &.wms-input-address-box{
                        width: 78%;
                    }
                    &.wms-input-address-half-box{
                        width: 50%;
                    }
                    &.wms-input-city-box{
                        width: 39%;
                    }
                    &.wms-input-zip-postal-code-box{
                        width: 20%;
                    }
                    &.wms-input-account-zip-box{
                        width: 25%;
                    }
                }
                .wms-dropdown-component{
                    float: unset;
                    position: unset;
                    display: inline-block;
                    &.wms-dropdown-state-box{
                        width: 15%;
                        display: inline-block;
                    }
                    &.wms-dropdown-country-box{
                        width: 26%;
                        display: inline-block;
                    }
                    &.wms-dropdown-account-country-box{
                        width: 35%;
                    }
                }
                .wms-datetimepicker-component{
                    width: 50%;
                    display: inline-block;
                }
                .wms-invoice-block{
                    .wms-input-component {
                        width: 28%;
                    }
                }
            }
        }
        #dhl-express-au_main {
            .dhlexp-au-nikcname {
                width: 265px;
            }
            .dhlexp-au-accountnumber {
                width: 315px;
            }
        }
        #SekoInformation_main {
            .seko-nikcname {
                width: 250px;
            }
            .seko-accesskey {
                width: 350px;
            }
        }
        #CanadaPost_main {
            .cp-nickname {
                width: 260px;
            }
            .cp-account-number {
                width: 310px;
            }
            .cp-contract-id {
                width: 310px;
            }
        }

        #carrierSettings_main {
            .wms-collapsible-content {
                .dropdown_section, .mail_section {
                    display: flex;
                }
                .radio_section {
                    .wms-checkbox-component {
                        width: 50%;
                    }
                }
            }
        }

        #FedexEditComponent_main {
            .fedex-edit-nickname {
                width: 300px;
            }
            .fedex-edit-accoutnumber {
                width: 200px;
            }
            .fedex-edit-zip {
                width: 120px;
            }
            .fedex-edit-smart-post-hub {
                width: 172px;
            }
            .fedex-edit-smart-post-endorsment {
                width: 400px;
            }
        }
        #UPSEditComponent_main, #carrierEditInvoiceInformation_main {
            .UPS-edit-nickname {
                width: 300px;
            }
            .UPS-edit-accoutnumber {
                width: 200px;
            }
            .UPS-edit-zip {
                width: 120px;
            }
            .UPS-edit-pickup-type {
                width: 200px;
            }
            .chk-inline {
                display: flex;
                .wms-checkbox-component {
                    margin-left: 5px;
                    margin-right: 10px;
                    padding: 0 0px;
                    label {
                        font-size: 13px;
                    }
                    input {
                        margin: 2px 7px 6px 0;
                    }
                }
            }
            .disabled-checkbox {
                opacity: 0.2;
            }
        }
        #carrierEditInvoiceInformation_main {
            .invoice-info-section {
                display: flex;
                flex-direction: column;
                .wms-checkbox-component {
                    margin-bottom: 20px;
                }
                .wms-invoice-elements {
                    display: flex;
                }
            }
        }

    }
    .wms-button-holder-block{
        display: flex;
        height: 40px;
        z-index: 1;
        justify-content: center;
        .wmsi-button-holder{
            margin: auto;
        }
    }
    .display-inline{
        display: flex;
    }
    .width-40 {
        width: 40%;
    }
    .width-100 {
        width: 70%;
    }
}