.auto-rate-shopping-rule-wrapper {
    .wms-collapsible-content {
        display: flex !important;
        .ship-service-keyword-wrapper {
            width: 361px;
        }
        .ship-rate-type-wrapper {
            width: 262px;
        }
    }
}