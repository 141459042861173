.proto-mass-add {
    .model-content-wrapper {
        padding: 0px !important;
        #proto-mass-add-holder-react{
            .k-grid-content-locked{
                tbody{
                    tr{
                        td:first-child{
                            opacity: unset !important;
                            cursor: pointer !important;
                            pointer-events: all !important;
                        }
                        td{
                            .k-tooltip{
                                white-space: initial;
                            }
                        }
                    }
                }
            }
        }
    }
}