
.consolidate-packages-wrapper {
    .wms-collapsible-content {
        display: flex !important;
        flex-direction: column;
        padding: 0px;
        .wms-dropdown-component {
            width: 300px;
            padding: 15px 15px 0 15px;
        }
        .dimension-wrapper{
            .dimension-title {
                label {
                    border-bottom: 1px solid #F5F5F5;
                    cursor: default;
                    display: block;
                    font-size: 11px;
                    line-height: 17px;
                    font-weight: normal;
                    text-transform: uppercase;
                    color: black;
                    flex: 1;
                    padding: 10px 0px 6px 12px;
                }
            }
            .dimension-inputs {
               padding: 15px 15px 0 15px;
               .wms-input-component {
                   width: 100px;
               }
            }
        }
    }
}