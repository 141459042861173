@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.wms-radiobtn-component {
    .wms-radiobtn {
        width: auto;
        float: left;
        margin: 0 20px 15px 0;
        // padding: 0 7px;
        position: relative;
        @include desktop_1199 {
            margin: 0 15px 10px 0;
            line-height: 30px;
        }
        &:focus {
            box-shadow: inset 0 0 0 1px $primary-themecolor;
            outline: none;
        }
        label {
            cursor: pointer;
            display: block;
            color: #333333;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        label.disabled-checkbox {
            opacity: 0.6;
            cursor: not-allowed;
            pointer-events: none;
        }
        input[type="radio"]:not(:checked) {
            border: 1px solid #a9aaab;
        }
        input[type="radio"]:checked {
            border: 1px solid var(--primary-themecolor);
        }
        .wms-option-radiobtn {
            width: 20px;
            height: 20px;
            background: $checked-bg;
            border: 1px solid $input-border;
            display: inline-block;
            margin-right: 0.5rem;
            outline: none;
            position: relative;
            z-index: 1;
            vertical-align: bottom;
            cursor: pointer;
            transition: all 0.15s ease-out 0s;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            vertical-align: middle;
        }
        .wms-option-radiobtn:checked::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 10px;
            height: 10px;
            display: inline-block;
            font-size: 16px;
            text-align: center;
            line-height: 28px;
            background: var(--primary-themecolor);
            border-radius: 50%;
            margin: auto;
        }
        .wms-option-radiobtn:checked::after {
            content: "";
            display: block;
            position: relative;
            z-index: 100;
            background: $checked-wavebg;
            -webkit-animation: new-click-wave 0.65s;
            -moz-animation: new-click-wave 0.65s;
            animation: new-click-wave 0.65s;
        }
        .wms-option-radiobtn.radio {
            border-radius: 50%;
        }
        .wms-option-radiobtn.radio::after {
            border-radius: 50%;
        }
    }
}
