@import "../../../scss/variable";
@import "../../../scss/mixin";
#customers-holder-react {
    width: 100%;
    height: 100%;
    display: flex;
    background: #fafbfc;
    padding: 0 !important;
    .customers-grid {
        width: 46.5%;
        height: 100%;
        padding: 25px;
        background: rgba(0, 0, 0, 0.03);
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        .topbtn-holder {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            .search-applied {
                float: right;
                margin: 8px 0 10px 2px;
                label {
                    color: #5a7e9d;
                    font-size: 11px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 600;
                }
            }
        }
        .wms-collapsible-div {
            margin: 15px 0 15px;
            .wms-collapsible-content {
                display: flex !important;
            }
        }

        .mail-content {
            font-size: 13px;
            margin-bottom: 15px;
            display: inline-block;
            color: #5a7e9d;
            a {
                margin: 0;
                color: #28b7ed;
                cursor: pointer;
                transition: all ease-in-out 0.3s;
                &:hover {
                    color: #3f536e;
                }
            }
        }
        .wms-grid-wrapper {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            .wms-grid-toolbar {
                flex-wrap: wrap-reverse;

                .wms-grid-checkbox-content {
                    @include desktop_1440 {
                        width: 100%;
                    }
                }

                .wms-toolbar-button {
                    .wms-button-component {
                        @include desktop_1280 {
                            margin: 0 5px 5px 0;
                        }
                    }
                }
            }
            .wms-grid-wrapper {
                .wms-grid-top {
                    margin-top: 0px;
                }
            }
        }
    }

    .customers-form {
        position: relative;
        flex: 1;
        width: 53.5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .cust-form-header {
            display: flex;
            width: 100%;
            align-items: center;
            flex-wrap: wrap-reverse;
            justify-content: space-between;
            padding: 15px 30px 14px 30px;
            .tab-listing ul {
                margin-top: 0px;
            }
            .cust-topbtn-holder {
                .wms-button-component {
                    margin-bottom: 0px;
                }
            }
        }
        .tab-content {
            overflow-y: scroll;
            padding: 0 14px 0px 30px;
            flex: 1 1 auto;
            .wms-radiobtn-component {
                margin-bottom: 16px;
                display: inline-block;
                float: left;
                width: 15%;
                @include desktop_1280 {
                    width: 25%;
                }
                .wms-radiobtn {
                    margin: 0px;
                    padding: 0 5px;
                    @include desktop_1366 {
                        padding: 0 2px;
                    }
                }
            }
            .wms-input-component {
                width: 33.33%;
                @include desktop_1280 {
                    width: 50%;
                }
            }
            .wms-dropdown-component {
                width: 33.33%;
                float: left;
                padding: 0px 0px;
                @include desktop_1280 {
                    width: 50%;
                }
            }
            .disabled-chk {
                opacity: 0.2;
            }
            .allow-moving-open-receipts-wrapper {
                width: 100%;
            }
            .custom-fields-wrapper {
                .allow-required-custom-fields  {
                    margin-left: 30px;
                    width: 100%;
                }
                .disabled-all-custom-fields {
                    opacity: 0.2;
                }
            }
            .wms-checkbox-component {
                float: left;
                margin-right: 10px;
            }
            .wms-multi-dorpdown-list {
                width: 40%;
                float: left;
                &.disable_facility {
                    .k-multiselect {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }
                .div_tooltip {
                    top: unset !important;
                    bottom: 45px !important;
                    width: 350px !important;
                }

                .div_tooltip:before {
                    -webkit-transform: rotate(404deg) !important;
                    left: 75px !important;
                    top: 70px !important;
                }
            }
            .shipping-tab-wrapper {
                .wms-collapsible-content {
                    .wms-area-content {
                        .title {
                            margin: 0;
                            label {
                                color: #222222;
                                text-transform: none;
                                letter-spacing: 0;
                                font-weight: 100;
                            }
                        }
                    }
                    .wms-area-content-darker {
                        display: flex;
                        flex-direction: column;
                        border: none;
                        padding: 0;
                        .wms-checkbox-component {
                            padding: 0 0px 5px;
                        }
                        .fulfillment-wrapper-class {
                            display: flex;
                            justify-content: space-between;
                            .wms-radiobtn-component {
                                width: 100%;
                                .wms-radiobtn {
                                    padding: 0;
                                    .disabled-checkbox {
                                        opacity: 0.2;
                                    }
                                }
                            }
                        }
                        .add-top-margin {
                            margin-top: 20px;
                        }

                        .mobile-processing-wrapper {
                            display: flex;

                            .reallocate-wrapper {
                                width: 300px;
                            }

                            .auto-advance-fill-wrapper {
                                display: flex;
                                flex-direction: column;
                                &.disabled-auto-advance-section {
                                    opacity: 0.2;
                                    pointer-events: none;
                                }

                                .auto-fill-sku-wrapper {
                                    margin-left: 30px;

                                    &.disabled-auto-fill {
                                        opacity: 0.2;
                                        pointer-events: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .disabled-option {
                opacity: 0.2;
            }
            .customer-specific-notification-wrapper {
                .wms-area-content-darker {
                    display: flex;
                    flex-direction: column;
                    .send-from-name-wrapper {
                        display: flex;
                        .sender-name-input {
                            width: 190px;
                            padding-right: 0;
                            input {
                                border-top-right-radius: unset !important;
                                border-bottom-right-radius: unset !important;
                                border-right: unset;
                            }
                        }
                        .sender-name-label {
                            padding-left: 0;
                            flex: 1;
                            input {
                                border-top-left-radius: unset !important;
                                border-bottom-left-radius: unset !important;
                            }
                        }
                    }
                    .disabled-notification-section {
                        opacity: 0.2;
                        pointer-events: none;
                    }
                    .from-email-address {
                        width: 60%;
                    }
                }
            }
            .system-general-settings-wrapper {
                .wms-area-content-darker {
                    display: flex;
                    flex-direction: column;
                }
            }

            .autobatch-tab-wrapper {
                .display-flex {
                    display: flex;
                    .enable-autobatch {
                        margin-top: 15px;
                    }
                    .cut-off-time-wrapper {
                        margin-left: 50px;
                    }
                    .batchsize-wrapper {
                        width: 90px;
                        margin-left: 12px;
                    }
                }
                #collapseAutobatchRulesOptionContent_main {
                    .wms-collapsible-content {
                        padding: 0;
                        .title {
                            margin: 15px 0;
                            padding-left: 15px;
                            padding-right: 15px;
                            .wms-sprite {
                                color: #f48831;
                                margin-right: 10px;
                            }
                            span {
                                font-size: 16px;
                                color: #000;
                            }
                        }
                        .auto-batch-setting-options-wrapper {
                            border-top: 1px solid #f5f5f5;
                            margin-bottom: 30px;
                            .auto-batch-setting-wrapper {
                                margin-top: 20px;
                                padding-left: 15px;
                                padding-right: 15px;
                                .autobatch-setting-container {
                                    .wms-area-content {
                                        .title {
                                            margin-bottom: 0;
                                            background: var(--tabel-headbg);
                                            border: 1px solid var(--tableheader-rowbg);
                                            border-radius: 4px 4px 0 0;
                                            width: 250px;
                                            vertical-align: middle;
                                            label {
                                                font-size: 13px;
                                                color: #222222;
                                                text-transform: none;
                                                letter-spacing: 0;
                                                display: block;
                                                padding: 6px 0;
                                            }
                                        }
                                        .wms-area-content-darker {
                                            padding-top: 0;
                                            padding-bottom: 0;
                                            .inline-flex {
                                                display: flex;
                                            }
                                            .autobatch-checkbox {
                                                width: 130px;
                                                border-right: 1px solid #bbb;
                                                .wms-checkbox-component {
                                                    //margin-top: 25px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    height: 100%;
                                                    margin-left: 8px;

                                                    .label {
                                                        position: relative;
                                                    }
                                                }
                                                .tooltip-wrapper {
                                                    transition: opacity 0.4s;
                                                    position: absolute;
                                                    height: auto;
                                                    width: auto;
                                                    max-width: 400px;
                                                    max-height: 300px;
                                                    font-size: 14px;
                                                    color: white !important;
                                                    font-weight: 100;
                                                    z-index: 12000;
                                                    top: 65px;
                                                    left: -15px;
                                                    div {
                                                        background: black !important;
                                                        border: 1px solid black !important;
                                                        padding: 4px 8px;
                                                        border-radius: 10px;
                                                        &:before {
                                                            content: "\A";
                                                            border-style: solid;
                                                            border-width: 11px 11px 0px 20px;
                                                            border-color: transparent black transparent transparent;
                                                            position: absolute;
                                                            -webkit-transform: rotate(-135deg);
                                                            left: 33px;
                                                            top: 2px;
                                                        }
                                                    }
                                                }
                                            }
                                            .autobatch-quantity {
                                                margin-left: 20px;
                                                margin-bottom: 20px;
                                                max-width: 651px;
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                &.disabled-wrapper {
                                                    opacity: 0.2;
                                                }
                                                .autobatch-qty {
                                                    font-size: 14px;
                                                    line-height: 19px;
                                                    color: #222222;
                                                    margin-bottom: 4px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
