@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.confirmation-wrapper {
    border-radius: 4px 4px 4px 4px;
    background-color: #fff;
    transition: all ease-in-out 1s;
    padding-top: 0px !important;
    // max-height: 324px;
    margin: 20px auto;
    .confirmation-model {
        padding: 0 0 0px !important;
        .wms-notification-warning-header {
            font-size: 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            padding: 0 23px 0px 30px;
            display: flex;
            height: 80px;
            min-height: 80px;
            line-height: 80px;
            color: var(--primary-white);
            font-weight: bold;
            background-color: #849eb0 !important;
            justify-content: space-between;
            text-transform: uppercase;
        }
        .wms-area-content {
            word-break: break-word;
            overflow: auto;
            color: #222222;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0px;
            flex: 1;
            display: flex;
            flex-direction: column;
            min-height: 150px;
            max-height: calc(100vh - 375px);
            .note {
                margin: 28px 40px 40px 40px;
                display: flex;
                flex-direction: column;
            }
            .wms-checkbox-component {
                padding-left: 40px;
            }
        }
        .wms-notification-warning-toolbar {
            display: flex;
            height: 94px;
            min-height: 94px;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
        }
    }
}
