
.add-line-item-modal-wrapper{
    .model-content-wrapper{
        .content-wrapper{
            display: flex !important;
            flex-direction: column;
            .image-content-wrapper{
                display: flex;
                .form-image-right-side{
                    width: 300px;
                    height: 185px;
                    border: 1px solid lightgrey;
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        cursor: zoom-in;
                        padding: 5px;
                    }
                    .wms_sprite {
                        font-size: 20px;
                        color: black;
                        position: absolute;
                        top: 30%;
                        right: 11%;
                    }
                }
            }
            .inline-elements{
                display: flex;
            }
            .sku-dropdown-wrapper{
                margin-top: 5px;
            }
            .width-15 {
                width: 15%;
            }
            .width-20 {
                width: 20%;
            }
            .width-25 {
                width: 25%;
            }
            .width-30 {
                width: 30%;
            }
            .width-35{
                width: 35%;
            }
            .width-40 {
                width: 40%;
            }
            .width-60 {
                width: 60%;
            }
            .width-100 {
                width: 100%;
            }
            .clear-icon{
                display: flex;
                align-items: center;
                margin-top: 10px;
                color: red;
                cursor: pointer;
            }
            .center-content{
                display: flex;
                justify-content: space-around;
            }
            .custom-field-error-msg{
                padding-left: 6px;
                height: 25px;
                .wms-sprite {
                    color: red;
                    margin-right: 10px;
                }
                span{
                    color: red
                }
            }
        }
        .opacity-content{
            opacity: 0.2;
            pointer-events: none;
        }
    }
}
.zommed-image-model{
    .image-wrapper{
        height: 100%;
        width: 100%;        
        position: relative;
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}