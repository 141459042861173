@import "../../../scss/variable";
@import "../../../scss/mixin";

#attach-file-assembly-holder {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 !important;
    .attach-file-assembly-search-holder {
        width: 405px;
        min-width: 400px;
        max-width: 405px;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #b7d2e5;
        background: rgba(0, 0, 0, 0.03);
        position: relative;
        transition: all 1s linear;

        .attach-file-assembly-filter {
            height: 100%;
            display: flex;
            flex-direction: column;

            .default-section {
                margin: 15px 0;
                padding: 0px 15px 0px 5px;

                .wms-button-component {
                    margin-bottom: 15px;
                }
            }

            .filter-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;

                .btn-section {
                    margin-top: 5px;
                    display: flex;
                    justify-content: flex-end;
                }

                .title {
                    width: 100%;
                    float: left;
                    margin: 8px 0 10px 2px;

                    label {
                        font-size: 11px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;
                        color: #5a7e9d;
                    }
                }

                .warehouse-form-boxes {
                    flex: 1;
                    padding: 0px 15px 0px 5px;
                    transition: all ease-in-out 0.3s;
                    overflow: auto;
                    .warehouse-are-content {
                        display: inline-block;
                        border-radius: 4px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        .title {
                            width: 100%;
                            float: left;
                            margin: 0 0 8px 0;
                            label {
                                font-size: 11px;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                font-weight: 600;
                            }
                        }
                        .wms-collapsible-div {
                            .wms_collapsible_header {
                                .wms-title {
                                    padding: 5px 12px 0 20px;
                                }
                            }
                            .wms-collapsible-content {
                                margin-bottom: 0;
                                padding: 15px 5px 5px;
                                .wms-dropdown-component {
                                    width: 100%;
                                }
                                .wms-input-group-wrapper{
                                    .wms-dropdown-component {
                                        width: 125px;
                                    }
                                }
                                ul#MultiSelectCustomerSkus_taglist {
                                    position: absolute;
                                }
                                .wms-datetimepicker-component {
                                    .border {
                                        width: 100%;
                                    }
                                }
                                .wms-input-component {
                                    width: 100%;
                                }
                            }
                        }
                        .form-group {
                            width: 100%;
                            float: left;
                            padding: 0px;
                            margin-bottom: 15px;
                        }
                        .wms_area_border {
                            height: 100%;
                            overflow: hidden;
                            padding: 0 0 26px 0;
                            #gridContainer {
                                height: 100%;
                            }
                        }

                        .filter-list-block {
                            padding-bottom: 8px;
                            margin-top: -4px;
                            li.filter-list {
                                height: 48px;
                                margin: 0px 7px 12px;
                                background-color: transparent;
                                border-radius: 4px !important;
                                padding: 13px 5px 13px 12px;
                                border: 1px solid #bbbbbb;
                                a.title-text {
                                    position: relative;
                                    white-space: nowrap;
                                    width: 100px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: inline-block;
                                }
                                .wms-apply-btn {
                                    float: right;
                                    margin: -7px 0px;
                                    .wms-button-component {
                                        margin-right: 5px;
                                    }
                                    .wms-button-component:last-child  {
                                        margin-right: 0px;
                                    }
                                }
                                .clear-save-filter {
                                    float: right;
                                    padding: 2px 5px;
                                    cursor: pointer;
                                    transition: all 0.3s ease-in-out;
                                    i {
                                        font-size: 16px;
                                        color: rgba(255, 0, 0, 0.2);
                                        transition: all 0.3s ease-in-out;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .wms-dropdown-component,
            .wms-input-component {
                width: 100%;
            }
        }

        .filter {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            border-radius: 0px;
            border: 0px;
            z-index: 1;
            background: #cee1f2;
            cursor: pointer;
            &:hover {
                background: #0079c4;

                .fa-caret-left:before, span {
                    color: #fff;
                }
            }
            .fa-caret-left {
                font-size: 16px;
                padding: 4px 8px;
            }
            span {
                display: none;
            }
        }
    }
    .attach-file-assembly-grid-holder {
        flex: 1;
        overflow-x: auto;
        margin: 0 0 20px 0;

        .wms-attach-file-assembly-grid-holder {
            height: 100%;
            .wms-virtual-grid-holder {
                .wms_area_content {
                    .wms-toolbar-button {
                        display: flex;
                        justify-content: flex-end;
                        flex-wrap: wrap;
                        .attach-file-assembly-refresh-btn {
                            .blue_button_color {
                                padding: 0px 16px;
                                .wms_toolbar_button_text {
                                    display: none;
                                }
                            }
                        }
                        .wms-button-component {
                            margin-right: 15px;
                        }
                    }
                }

                .wms-grid-area-wrapper{
                    max-height: none;
                }
            }
        }
    }
}