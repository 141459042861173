@import "../../../scss/variable";
@import "../../../scss/mixin";

#audit-inventory-beta-holder-react {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 !important;
    .wms-kendo-dropdown-list-component {
        .k-dropdown{
            background-color: white;
        }
    }
    &.expandFilter{
        &.animatedCollapse{
            .audit-inventory-search-holder{
                animation: showFilterSection 1s forwards;
            }
        }
    }
    &.collapsedFilter {
        &.animatedCollapse{
            .manage-inventory-search-holder{
                animation: hideFilterSection 1s forwards;
            }
        }
        &:not(.animatedCollapse){
            .manage-inventory-search-holder{
                margin-left: -365px;
            }
        }
        .manage-inventory-search-holder {
          overflow: hidden;
        }
    }

    .audit-inventory-search-holder {
        width: 405px;
        min-width: 400px;
        max-width: 405px;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #b7d2e5;
        background: rgba(0, 0, 0, 0.03);
        position: relative;
        transition: all 1s linear;

        .inventory-filter {
            height: 100%;
            display: flex;
            flex-direction: column;

            .default-section {
                margin: 15px 0;
                padding: 0px 15px 0px 5px;

                .wms-button-component {
                    margin-bottom: 15px;
                }
            }

            .filter-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;

                .btn-section {
                    margin-top: 5px;
                    display: flex;
                    justify-content: flex-end;
                }

                .title {
                    width: 100%;
                    float: left;
                    margin: 8px 0 10px 2px;

                    label {
                        font-size: 11px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;
                        color: #5a7e9d;
                    }
                }

                .warehouse-form-boxes {
                    flex: 1;
                    padding: 0px 15px 0px 5px;
                    transition: all ease-in-out 0.3s;
                    overflow: auto;
                    .warehouse-are-content {
                        display: inline-block;
                        border-radius: 4px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        .title {
                            width: 100%;
                            float: left;
                            margin: 0 0 8px 0;
                            label {
                                font-size: 11px;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                font-weight: 600;
                            }
                        }
                        .wms-collapsible-div {
                            .wms_collapsible_header {
                                .wms-title {
                                    padding: 5px 12px 0 20px;
                                }
                            }
                            .wms-collapsible-content {
                                margin-bottom: 0;
                                padding: 15px 5px 5px;
                                .wms-dropdown-component {
                                    width: 100%;
                                }
                                .wms-input-group-wrapper{
                                    .wms-dropdown-component {
                                        width: 125px;
                                    }
                                }
                                .wms-kendo-dropdown-list-component {
                                    width: 100%;
                                    // flex-direction: row ;
                                    .form_group {
                                        float: left;
                                        position: relative;
                                        padding: 0px 7px;
                                        margin-bottom: 11px;
                                    }
                                }
                                ul#MultiSelectCustomerSkus_taglist {
                                    position: absolute;
                                }
                                .wms-datetimepicker-component {
                                    .border {
                                        width: 100%;
                                    }
                                }
                                .wms-input-component {
                                    width: 100%;
                                }
                            }
                        }
                        .form-group {
                            width: 100%;
                            float: left;
                            padding: 0px;
                            margin-bottom: 15px;
                        }
                        .wms_area_border {
                            height: 100%;
                            overflow: hidden;
                            padding: 0 0 26px 0;
                            #gridContainer {
                                height: 100%;
                            }
                        }

                        .filter-list-block {
                            padding-bottom: 8px;
                            margin-top: -4px;
                            li.filter-list {
                                height: 48px;
                                margin: 0px 7px 12px;
                                background-color: transparent;
                                border-radius: 4px !important;
                                padding: 13px 5px 13px 12px;
                                border: 1px solid #bbbbbb;
                                a.title-text {
                                    position: relative;
                                    white-space: nowrap;
                                    width: 100px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: inline-block;
                                }
                                .wms-apply-btn {
                                    float: right;
                                    margin: -7px 0px;
                                    .wms-button-component {
                                        margin-right: 5px;
                                    }
                                    .wms-button-component:last-child  {
                                        margin-right: 0px;
                                    }
                                }
                                .clear-save-filter {
                                    float: right;
                                    padding: 2px 5px;
                                    cursor: pointer;
                                    transition: all 0.3s ease-in-out;
                                    i {
                                        font-size: 16px;
                                        color: rgba(255, 0, 0, 0.2);
                                        transition: all 0.3s ease-in-out;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .wms-dropdown-component,
            .wms-input-component {
                width: 100%;
            }
        }

        .filter {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            border-radius: 0px;
            border: 0px;
            z-index: 1;
            background: #cee1f2;
            cursor: pointer;
            &:hover {
                background: #0079c4;

                .fa-caret-left:before, span {
                    color: #fff;
                }
            }
            .fa-caret-left {
                font-size: 16px;
                padding: 4px 8px;
            }
            span {
                display: none;
            }
        }
    }

    .audit-inventory-grid {
        flex: 1;
        overflow-x: auto;
        margin: 0 0 20px 0;

        .wms-audit-inventory-grid-holder {
            height: 100%;
            margin: 0 20px;
            .wms-grid-wrapper {
                .wms-grid-toolbar {
                    padding-top: 10px;
                }
                .wms_area_content {
                    .wms-toolbar-button {
                    display: flex;
                    justify-content: flex-end;
                    float: right;

                    .wms-button-component {
                        margin-right: 15px;
                    }
                    }
                }

                .wms-grid-area-wrapper{
                    max-height: none;
                }
            }
        }
    }
}

#ManageAuditInventoryModal {
    .model-content-wrapper {
        padding: 14px 0px 7px;
        .general-fields-Wrapper {
            display: flex;
            border-bottom: 1px solid #e3d8d8;
            .job-name-wrapper {
                width: 283px;
                margin-left: 15px;
            }
            .audit-type-wrapper {
                width: 172px;
            }
            .assigned-to-wrapper {
                width: 252px;
                padding: 4px 6px 0;
            }
            .priority-wrapper {
                width: 72px;
            }
            .wms-checkbox-component {
                margin-top: 18px;
            }
        }
        .grid-fields-Wrapper {
            display: flex;
            margin-top: 10px;
            .item-filter-wrapper {
                display: flex;
                .customer-options-wrapper {
                    width: 342px;
                    margin-left: 15px;
                }
                .select-an-item-wrapper,
                .item-activity-wrapper  {
                    width: 192px;
                    &.wms-kendo-dropdown-list-component {
                        margin-top: 2px;
                        label {
                            margin-bottom: 5px;
                        }
                    }
                }
                .wms-tooltip-component {
                    position: unset;
                    .div_tooltip {
                        left: 280px;
                        top: 150px;
                    }
                }
            }

            .wms-checkbox-component {
                margin-top: 18px;
            }
            .locations-filter-wrapper {
                display: flex;
                margin-left: 15px;
                .wms-input-group-wrapper {
                    width: auto;
                    padding-bottom: 6px;
                    align-items: flex-start;
                }
                .wms-datetimepicker-component,
                .wms-dropdown-component  {
                    width: 192px;
                }
                .inputlabel {
                    display:inline-block;
                }
            }
        }
        .location-icon-wrapper {
            display: flex;
            .location-type-Wrapper{
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                width: 15%;
                .wms-checkbox-component  {
                    padding: 0 20px 0 0;
                    input {
                        border-radius: 50%;
                        &::before {
                            border-radius: 50%;
                        }
                    }

                }
                .label-wrapper {
                    font: normal normal normal 13px/16px Inter;
                }
            }
            .inactive-location-Wrapper {
                width: 55%;
                .label-wrapper {
                    font: normal normal normal 13px/16px Inter;
                }
            }
            .icon-header-wrapper {
                display: flex;
                .suggested-header-wrapper,
                .required-header-wrapper {
                    margin-left: 10px;
                    .required-wrapper {
                        background: orange;
                        color: white;
                        border-radius: 50%;
                        label {
                            font-size: 15px;
                            font-weight: bold;
                            padding: 5px;
                        }
                    }
                }
                .suggested-header-wrapper {
                    .required-wrapper {
                        background: #00ABD8;
                    }
                }
            }
        }
        .grid-wrapper {
            display: flex;
            .job-location-grid-wrapper {
                margin-left: 20px;
                .k-grid {
                    width: 750px;
                    .k-grid-content {
                        height: 360px;
                    }
                }
            }
            .navigation-btn-wrapper {
                display: flex;
                flex-direction: column;
                padding: 20px;
                justify-content: center;
                .wms-button-component  {
                    padding: 5px 0 5px 0;
                    button {
                        border-radius: 0;
                        height: 35px;
                        width: 30px;
                        background-color: transparent;
                        border: 1px solid #cbbebe !important;
                        .wms_sprite {
                            margin-left: -3px;
                            margin-top: 4px;
                            font-size: 17px;
                            color: grey;
                            opacity: 0.3;
                        }
                    }
                }
            }
            .audit-job-location-grid-wrapper {
                .k-grid {
                    width: 300px;

                    .k-grid-content {
                        height: 360px;
                    }

                    thead, .k-grid-content{
                        .suggested-label-wrapper,
                        .required-label-wrapper {
                            margin-right: 10px;
                            background: orange;
                            color: white;
                            border-radius: 50%;
                            label {
                                font-size: 15px;
                                font-weight: bold;
                                padding: 5px;
                            }
                        }
                        .required-header-wrapper {
                            float: right;
                            .required-label-wrapper {
                                margin-right: 2px;
                            }
                        }
                        .suggested-label-wrapper {
                            background: #00ABD8;
                        }
                    }
                    .k-hide-chield {
                        input, label {
                            display: none;
                        }
                    }
                }
            }
        }
        .grid-empty-block {
            min-height: 400px;
            opacity: 0.2;
            .wms-form-grid-holder {
                .hidden-header{
                    .k-grid-header {
                        display: block;
                    }

                }
            }

        }
    }
}