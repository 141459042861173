@import "../../../scss/variable";
@import "../../../scss/mixin";

#wms-theme-wrapper {
    height: 100%;
    width: 100%;

    .theme-holder-react {
        height: 100%;
        display: flex;
        flex-direction: row;

        .theme-search-holder {
            height: 100%;
            position: relative;

            .theme-search-filter {
                width: 330px;
                height: 100%;
                padding: 15px;
                border-right: none;
                transition: all 0.4s linear;
                overflow: hidden;
                background-color: rgba($color: #f2f3f4, $alpha: 1);
                display: inline-flex;
                flex-direction: column;
                -webkit-transition: all 0.4s ease-in;
                -moz-transition: all 0.4s ease-in;
                -o-transition: all 0.4s ease-in;
                transition: all 0.4s ease-in;
                margin-left: 0px;

                .wms-toolbar {
                    display: inline-block;
                    width: 100%;
                }

                .theme-form-boxes-wrapper {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    overflow-y: auto;
                    margin-top: 10px;

                    .theme-form-boxes {
                        padding: 0px 15px 0px 0px;
                        transition: all ease-in-out 0.3s;
                        overflow: hidden;

                        .theme-are-content {
                            display: inline-block;
                            width: 100%;
                            border-radius: 4px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;

                            .wms-collapsible-div-proto {
                                .wms-collapsible-content {
                                    margin-bottom: 0;
                                    padding: 15px 5px 5px;
                                }
                            }
                        }
                    }
                }

                .filter-list-block {
                    padding-bottom: 8px;
                    margin-top: -4px;

                    li.filter-list {
                        height: 48px;
                        margin: 0px 0px 12px;
                        background-color: $inputs_bg_color;
                        border-radius: 4px !important;
                        padding: 13px 5px 13px 12px;

                        a.title-text {
                            position: relative;
                            white-space: nowrap;
                            width: 60%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        cursor: unset;

                        .wms-apply-btn {
                            float: right;
                            margin: -7px 3px;
                        }

                        .clear-save-filter {
                            float: right;
                            padding: 2px 5px;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;

                            i {
                                font-size: 16px;
                                color: rgba(255, 0, 0, 0.2);
                                transition: all 0.3s ease-in-out;
                            }

                            &:hover {
                                color: #2c4059;

                                i {
                                    color: rgba(255, 0, 0, 1);
                                }
                            }
                        }
                    }
                }
            }
        }

        .theme-grid {
            flex: 1;
            padding: 10px;
            transition-property: width;
            -webkit-transition: all 0.4s linear;
            -moz-transition: all 0.4s linear;
            -o-transition: all 0.4s linear;
            transition: all 0.4s linear;
            overflow: hidden;
            background: $form-boxbg;
        }


        .wms-search-grid-data {
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .right_content {
                .wms-button-component {
                    display: inline-block;
                }

                ul {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    li.theme-var-list {
                        float: left;
                        padding: 10px 10px;
                        width: 33.33%;
                        cursor: unset;

                        .input-label {
                            white-space: nowrap;
                            width: 70%;
                            float: left;
                        }

                        .k-colorpicker {
                            .k-picker-wrap {
                                .k-selected-color {
                                    width: 45px;
                                    cursor: pointer;
                                }

                                .k-select {
                                    display: none;
                                    width: 0;
                                }
                            }
                        }
                    }
                }
            }

            .branding-logo-wraper{
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                .branding-image-upload{
                    width: 300px;
                    border-right: 3px solid silver;
                    .wms-input-component-proto {
                        padding-right: 25px;
                    }
                    .wms-radiobtn-component-proto{
                            padding: 0;
                            label {
                                cursor: pointer;
                                color: $title_color;
                                input {
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    -ms-appearance: none;
                                    -o-appearance: none;
                                    appearance: none;
                                    height: 28px;
                                    width: 28px;
                                    transition: all 0.15s ease-out 0s;
                                    background: white;
                                    border: 1px solid #A9AAAB;
                                    cursor: pointer;
                                    display: inline-block;
                                    margin: 16px 8px 16px 16px;
                                    outline: none;
                                    position: relative;
                                    z-index: 1;
                                    vertical-align: middle;
                                    border-radius: 50%;
                                    &:checked::after {
                                        -webkit-animation: select-click-wave 0.65s;
                                        -moz-animation: select-click-wave 0.65s;
                                        animation: select-click-wave 0.65s;
                                        content: "";
                                        display: block;
                                        position: relative;
                                        z-index: 100;
                                        background: $checked-wavebg;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }
                                    &:checked::before {
                                        content: "";
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 16px;
                                        width: 16px;
                                        text-align: center;
                                        border-radius: 50%;
                                        background-color: #656565;
                                    }
                                }
                            }
                        }
                }
                .branding-image-view {
                    width: 325px;
                    padding-left: 25px;
                    .img-logo-holder {
                        width: 300px;
                        height: 185px;
                        border: 1px solid lightgrey;
                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                    .btns-holder {
                        width: 300px;
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                    }
                    .apply-tooltip {
                        color: #a9a6a6;
                        text-align: center;
                    }
                }
            }
        }
    }
}


#saveCurrentThemes {
    .save-filter-button-wrapper {
        display: inline-flex;

        .wms-button-component {
            .button_holder_proto {
                display: inline-block;
            }
        }
    }

    .wms-area-content {
        margin-top: 15px;

        .wms-area-content-darker {
            padding: 12px;

            .wms-input-component-proto {
                width: 100%;
                margin-bottom: 0;

                input {
                    border: 1px solid #e0e0e0;
                }
            }

            .wms-dropdown-component {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}

// css for color picker
input.k-color-value {
    border-radius: 10px;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}