@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-input-component {
    &.wms-input-disable{
        opacity: 0.2;
        cursor: not-allowed;
        pointer-events: none;
        input:disabled, :read-only {
            border-color: $border-color !important;
            background: transparent !important;
            opacity: 1;
        }
    }
    position: relative;
    width: 33.33%;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0px 6px;
    margin-bottom: 14px;

    &.full-width-input {
        width: 100%;
    }
    &.input-extra-sign-component{
        input{
            padding-left: 17px !important;;
        }
        .extra-sign{
            position: absolute;
            left: 15px;
            top: 26px;
            font-weight: 600;
            font-size: 14px;
            color: #222222;
        }
    }
    .form_group {
        width: 100%;

        &::before {
            content: unset;
            display: unset;
            position: unset;
            background: unset;
        }

        input {
            width: 100%;
            padding-top: 0px;
            background-color: transparent;
            border: 1px solid $border-color;
            border-radius: 4px !important;
            height: 32px;
            font-weight: 700;
            font-size: 14px;
            color: $input-color;
            padding: 0px 10px;
            line-height: 32px;
        }

    }

    .inputlabel {
        @include label;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    // @include desktop_1280 {
    //     width: 50%;
    // }

    input:disabled {
        border-color: rgba(0, 0, 0, 0.06);
        background: rgba(0, 0, 0, 0.03);
        opacity: 1;
    }
    input:read-only {
        border-color: rgba(0, 0, 0, 0.06);
        background: rgba(0, 0, 0, 0.03);
        opacity: 1;
    }

    .wms-search-input-icon {
        position: absolute;
        z-index: 10;
        color: $input-color;
        right: 7px;
        top: 20px;
        width: 40px;
        height: 32px;
        text-align: center;
        // line-height: 40px;
        font-size: 16px;
        cursor: pointer;
        // border-left: 1px solid #d8e5ee;
        display: flex;
        justify-content: center;
        align-items: center;
        .fa-search {
            font-size: 14px;
        }
    }

    .div_tooltip {
        position: absolute;
        z-index: 12;
        top: 60px;
        width: 200px;
        background: black !important;
        font-size: 14px;
        color: white !important;
        border: 1px solid black !important;
        padding: 4px 8px;
    }

    .div_tooltip:before {
        content: "\A";
        border-style: solid;
        border-width: 11px 11px 0px 20px;
        border-color: transparent black transparent transparent;
        position: absolute;
        -webkit-transform: rotate(-135deg);
        left: 75px;
        top: 1px;
    }

    .wms_required_input {
        border: 1px solid $requireinput-border !important;

        &:focus {
            border-color: $primary-themecolor !important;
        }
    }

    .input_box {
        width: 100%;
        height: 32px;
        float: left;
        position: relative;
        padding-right: 0px;

        .inputlabel {
            display: none;
        }

        input {
            width: 100%;
            background-color: transparent;
            border: 1px solid $border-color;
            height: 32px;
            font-weight: 600;
            font-size: 14px;
            color: $input-color;
            padding: 0 10px;
            position: relative;
            // z-index: 10;
            border-radius: 0 4px 4px 0 !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }
}