@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.wms-virtual-group-grid-holder {
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .wms-grid-area-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1;
        min-height: 400px;
        max-height: 800px;
        border: 1px solid #00000014;
        border-top: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .wms_area_border {
            overflow: hidden;
            width: 100%;
            .gridContainer {
                overflow: hidden;
                height: 100%;
                &.withRadious{
                    .k-grid {
                        border-radius: 4px 4px 0px 0px;
                        .k-grid-header{
                            border-radius: 4px 4px 0px 0px;
                        }
                    }
                }
                .k-grid {
                    border: 0px solid #ebebeb !important;
                    border-top: none !important;
                    .k-grid-header{
                        border: 1px solid #00000014;
                        .k-grid-header-wrap {
                            border-color: #00000014;
                            table {
                                width: 100% !important;
                                .k-header {
                                    font-size: 14px;
                                    font-weight: 700;
                                    padding: 6px 12px;
                                    color: #222222;
                                    .k-link {
                                        .k-icon {
                                            vertical-align: middle !important;
                                        }
                                    }
                                    .k-i-sort-desc-sm,
                                    .k-i-sort-asc-sm {
                                        &::before {
                                            color: $input-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .k-grid-content{
                        border-top: none !important;
                        table{
                            width: 100% !important;
                            tbody {
                                tr {
                                    td {
                                        border-bottom: 0;
                                    }
                                    &.k-state-selected {
                                        td {
                                            font: Bold 13px Open Sans;
                                            color: #005CA8 !important;
                                            background-color: #E2EFFC !important;
                                        }
                                    }
                                    &.k-alt {
                                        &.k-state-selected {
                                            td{
                                                background-color: #D0E5FA !important;
                                            }
                                        }
                                    }
                                    &.k-grouping-row {
                                        td {
                                            background-color: #e5e5e5;
                                        }
                                        .k-i-collapse {
                                            display: none   ;
                                        }
                                        .package-icon-wrapper {
                                            display: flex;
                                            flex: 1;
                                            .group-icon-wrapper {
                                                display: flex;
                                                justify-content: flex-end;
                                                flex: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tr {
                        td {
                            padding: 6px 12px;
                            color: $input-color;
                            font-size: 13px;
                            white-space: nowrap;
                            // remove box shadow on navigate or selection
                            &.k-state-focused{
                                box-shadow: none;
                            }
                        }
                        th{
                            // remove box shadow on navigate or selection
                            &.k-state-focused{
                                box-shadow: none;
                            }
                        }
                        &.k-alt {
                            background-color: $inputs_bg_color;
                        }
                    }
                }
            }
        }
    }
    .wms_area_content {
        .wms-grid-details {
            display: inline-block;
            width: 100%;
            padding: 2px 13px 6px 11px;
            margin: 12px 0 0 0;
            border: 1px solid #00000014;
            background-color: var(--tabel-headbg);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            .wms_grid_results {
                font-size: 11px;
                text-transform: uppercase;
                font-weight: normal;
                padding-right: 15px;
                letter-spacing: 0.1px;
                color: $title_color;
                span {
                    color: $title_color;
                    padding-right: 7px;
                    font-weight: bold;
                }
            }
            .wms_grid_selected {
                font-size: 11px;
                text-transform: uppercase;
                font-weight: normal;
                border-left: 1px solid $title_color;
                padding: 0 15px 0 15px;
                color: $title_color;
                span {
                    color: $title_color;
                    padding-right: 7px;
                    font-weight: bold;
                }
            }
        }
    }
}