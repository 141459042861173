.audit-inventory-review-audit-job-detail-sub-grid {
    .wms-grid-check-box-selection-component {
        thead {
            display: none !important;
        }
        .k-grid {
            border: none;
            .k-grid-header {
                border: none;
            }
            .k-grid-content {
                overflow: hidden;
                tbody {
                    td {
                        label {
                            width: 100%;
                            display: flex;
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
