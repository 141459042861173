@import "../../../../scss/variable";
@import "../../../../scss/mixin";

#wms-packaging-types-wrapper {
    height: 100%;
    width: 100%;
    .wms-packaging-types-search {
        height: 100%;
        position: relative;
        padding: 30px 10px 0 30px;
        .filter {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            border-radius: 0px;
            background: $tabel-headbg;
            border: 0px;
            z-index: 1;
            cursor: pointer;
            .fa-caret-left {
                font-size: 16px;
                padding: 4px 8px;
            }
            .fa-caret-left:before {
                color: #527999;
            }
            &:hover {
                background: #0079c4;
                .fa-caret-left:before,
                span {
                    color: #fff;
                }
            }
            span {
                display: none;
            }
        }
        .packaging-types-search-filter {
            height: 100%;
            border-right: none;
            transition: all 0.4s linear;
            overflow: hidden;
            display: inline-flex;
            flex-direction: column;
            -webkit-transition: all 0.4s ease-in;
            -moz-transition: all 0.4s ease-in;
            -o-transition: all 0.4s ease-in;
            transition: all 0.4s ease-in;
            margin-left: 0px;
            .title {
                padding: 5px 0 7px 0;
                label {
                    color: $filter-note-title !important;
                    font-size: 14px;
                    letter-spacing: 1px;
                    font-weight: 600;
                }
            }
            .wms-toolbar {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 25px;
                padding-right: 20px;
            }
            .disable-section {
                opacity: 0.2;
                pointer-events: none;
            }
            #search_default_collapse_main {
                margin-right: 10px;
                width: unset;
                .wms-dropdown-component {
                    width: 100%;
                }
            }
            .packaging-types-form-boxes-wrapper {
                position: relative;
                width: 100%;
                overflow: hidden;
                overflow-y: auto;

                .packaging-types-form-boxes {
                    transition: all ease-in-out 0.3s;
                    overflow: hidden;
                    margin-right: 20px;
                    .packaging-types-area-content {
                        // display: inline-block;
                        border-radius: 4px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        .title {
                            width: 100%;
                            float: left;
                            margin: 0 0 8px 0;
                            label {
                                color: $form-title !important;
                                font-size: 11px;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                font-weight: 600;
                            }
                        }
                        .wms-collapsible-div {
                            .wms_collapsible_header {
                                .wms-title {
                                    padding: 5px 12px 0 20px;
                                }
                            }
                            .wms-collapsible-content {
                                margin-bottom: 0;
                                // padding: 15px 5px 5px;
                                .wms-dropdown-component {
                                    width: 100%;
                                }
                                .wms-kendo-dropdown-list-component {
                                    width: 100%;
                                    flex-direction: row;
                                    .form_group {
                                        float: left;
                                        position: relative;
                                        padding: 0px 7px;
                                        margin-bottom: 11px;
                                    }
                                }
                                .add-clear-icon {
                                    .wms-kendo-dropdown-list-component {
                                        .k-dropdown {
                                            width: calc(100% - 28px);
                                        }
                                    }
                                }
                                ul#MultiSelectCustomerSkus_taglist {
                                    position: absolute;
                                }
                                .wms-datetimepicker-component {
                                    .border {
                                        width: 100%;
                                    }
                                }
                                .wms-input-component {
                                    width: 100%;
                                }
                                .wms-multiselect-input-wrapper {
                                    .wms-kendo-dropdown-update-list-component {
                                        button {
                                            padding: 0;
                                            width: auto;
                                            height: 20px;
                                            justify-content: center;
                                            padding-left: 6px;
                                            padding-right: 6px;
                                            .wms_sprite {
                                                width: 12px;
                                                height: 12px;
                                                font-size: 12px;
                                                line-height: 22px;
                                            }
                                            .wms_toolbar_button_text {
                                                margin: 0px 9px 0px 4px;
                                                font-size: 10px;
                                                line-height: 22px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .form-group {
                            width: 100%;
                            float: left;
                            padding: 0px;
                            margin-bottom: 15px;
                        }
                        .wms_area_border {
                            height: 100%;
                            overflow: hidden;
                            padding: 0 0 26px 0;
                            #gridContainer {
                                height: 100%;
                            }
                        }
                    }
                }
            }
            .filter-list-block {
                padding-bottom: 8px;
                margin-top: -4px;
                li.filter-list {
                    display: flex;
                    height: 48px;
                    margin: 0px 7px 12px;
                    background-color: transparent;
                    border-radius: 4px !important;
                    padding: 13px 5px 13px 12px;
                    border: 1px solid #bbbbbb;
                    a.title-text {
                        color: $input-color;
                        position: relative;
                        white-space: nowrap;
                        width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                    }
                    .wms-apply-btn {
                        display: flex;
                        margin: -7px 3px;
                    }
                    .clear-save-filter {
                        padding: 2px 5px;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                        i {
                            font-size: 16px;
                            color: rgba(255, 0, 0, 0.2);
                            transition: all 0.3s ease-in-out;
                        }
                        &:hover {
                            color: #2c4059;
                            i {
                                color: rgba(255, 0, 0, 1);
                            }
                        }
                    }
                }
            }
        }
    }
    .new-layout-grid-wrapper {
        transition-property: width;
        -webkit-transition: all 0.4s linear;
        -moz-transition: all 0.4s linear;
        -o-transition: all 0.4s linear;
        transition: all 0.4s linear;
        overflow: hidden;
        background: $form-boxbg;
        overflow-y: auto;
        padding: 0;
        margin-bottom: 30px;
        .wms-virtual-grid-holder{
            display: block;
            height: 80%;
            .k-grid-header-wrap {
                padding-right: 20px;
            }
        }
        .wms-collapsible-div {
            margin: 10px;
            width: -webkit-fill-available;
            .aleart-reciplent-setting-area {
                display: flex;
                align-items: center;
                .w-40{
                    width: 25%;
                    margin-right: 10px;
                    .wms-dropdown-component  {
                        width: 100%;
                    }
                }
                .wms-button-component  {
                    margin-top: 10px;
                    z-index: 10;
                }

            }
        }
        .title {
            margin: 0 0 0 2px;
        }
        .wms-toolbar-button {
            float: right;
            display: flex;
            flex-wrap: wrap;
            .refreshSmalParcelGrid {
                .blue_button_color {
                    padding: 0px 16px;
                    .wms_toolbar_button_text {
                        display: none;
                    }
                }
            }
            .package-def-grid-refresh-btn button{
                padding: 0px 16px;
                .wms_toolbar_button_text {
                    display: none;
                }
            }
        }
    }
}

#PackageDefModal {
    .model-content-wrapper {
        .wms-collapsible-div {
            .wms-collapsible-content {
                padding: 15px 14px;
                .display-inline {
                    display: flex;
                    .package-type-name {
                        width: auto;
                        .form_group {
                            width: 200px;
                        }
                    }
                    .package-type-description {
                        width: auto;
                        .form_group {
                            width: 300px;
                        }
                    }
                    .package-type-barcode {
                        width: auto;
                        .form_group {
                            width: 174px;
                        }
                    }
                    .package-insert-items-dropdown {
                        width: 50%;
                        .wms-form-label{
                            line-height: 1.8;
                        }
                    }
                    .package-Inventory-items-dropdown {
                        width: 50%;
                    }

                    .package-type-customer {
                        // width: 100%;
                        .k-dropdown {
                            width: 272px;
                        }
                        .selected-values {
                            padding: 14px 0px 9px 0px;
                            .wms_toolbar_button {
                                padding: 0;
                                width: auto;
                                height: 20px;
                                justify-content: center;
                                padding-left: 6px;
                                padding-right: 6px;
                                // margin-right: 20px;
                                .wms_sprite {
                                    width: 12px;
                                    height: 12px;
                                    font-size: 12px;
                                }
                                .wms_toolbar_button_text {
                                    margin: 0px 9px 0px 4px;
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                    .package-type-warehouse{
                        width: 40%;
                    }
                    .package-type-email{
                        width: 60%;
                        label {
                            line-height: 1.8;
                        }
                    }
                    // .wms-rounded-checkbox {
                    //     position: absolute;
                    //     margin: 14px 0px 0px 302px;

                    // }

                    .package-type-length,
                    .package-type-width,
                    .package-type-height,
                    .package-type-weight {
                        width: auto;
                        .form_group {
                            width: 90px;
                        }
                    }
                    .package-type-cost,
                    .package-type-markup-per,
                    .package-type-markup-flat {
                        width: auto;
                        .form_group {
                            width: 100px;
                        }
                    }

                    .wms-radiobtn-component {
                        .wms-radiobtn {
                            margin: 0;
                            margin-right: 40px;
                            .radio {
                                margin: 0px 5px 3px 0px;
                            }
                        }
                    }

                    &.customer-and-isdefault-section {
                        .wms-rounded-checkbox {
                            margin-top: 15px;
                            margin-left: 10px;
                        }
                        .wms-radiobtn-component {
                            display: flex;
                            margin-left: 30px;
                            align-items: flex-start;
                            padding-top: 20px;
                        }
                    }
                    &.weight-uom-section {
                        justify-content: space-between;
                        margin-bottom: 20px;;
                        .wms-details-uom-section {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    &.Warehouse-second-area {
                        align-items: center;
                        .wms-button-component {
                            margin-top: 10px;
                            .wms_toolbar_button{
                                padding-right: 10px;
                            }
                        }
                    }
                    &.inventory-item-area{
                        align-items: center;
                        .wms-rounded-checkbox{
                            width: 50%;
                        }
                    }
                    &.order-source-area{
                        align-items: center;
                        .wms-rounded-checkbox{
                            margin-top: 5px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
.drop-down-list-unset-height {
    &.k-list-container{
        height: unset !important;
    }
}
