@import "../../../../scss/variable";
@import "../../../../scss/mixin";

#create-receipt-holder-wrapper {
    height: 100%;
    overflow: hidden;

    .wms-top-toolbar-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;

        .wms-top-toolbar-btn-wrapper {
            margin-bottom: 16px;
            display: flex;
            justify-content: flex-end;
            &>div{
                display: flex;
            }
        }

        .wms-tablisting-holder {
            // position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;
        }

        .wms-container-wrapper {
            .wms-area-content {
                margin-bottom: 0px;
            }
        }

        .wms-dropdown-component,
        .wms-combobox-component,
        .wms-input-component {
            width: 14.28%;
            float: left;

            @include desktop_1600 {
                width: 25%;
            }
        }

        .wms-text-area-component {
            padding: 0 7px;
            display: inline-block;
            width: 100%;
        }

        .wms-datetimepicker-component {
            width: 14.28%;
            float: left;

            @include desktop_1600 {
                width: 25%;
            }

            .border {
                width: 100%;

                &::before {
                    right: 40px;
                    background-color: #ffffff;
                }
            }
        }
        .transport-information-section {
            display: flex;
            flex-direction: column;
        }
        .receipt-charges-section {
            display: flex;
            flex-direction: column;
        }
        .receipt-custom-receipt-info-section {
            display: inline-block;
            width: 100%;

            #GridCustomReceiptInfo {
                min-height: 400px;
            }
        }

        .receipt-line-items-section {
            display: inline-block;
            width: 100%;

            #GridReceiptLineItems {
                min-height: 400px;
            }

            .totalWeightVolumeContainer {
                .wms-input-component {
                    width: 33.33% !important;

                    input {
                        border-color: rgba(0, 0, 0, 0.06);
                        background: rgba(0, 0, 0, 0.03);
                        opacity: 1;
                    }
                }
            }
        }

        .receipt-charges-section {
            #GridmanageReceiptAppliedCharges {
                min-height: 400px;
            }

            .total-price {
                .wms-input-component {
                    width: 33.33%;
                    float: right;
                    padding: 0px;
                    margin: 0px;
                }
            }
        }
    }

    #manageReceiptScrollBox {
        flex: 1;
        overflow: auto;
        margin-top: 12px;
    }

    .collapse-grid-section {
        .wms-collapsible-div {
            .wms-collapsible-content {
                position: relative;

                .wms-kendo-dropdown-list-component {
                    width: 14.2%;

                    @include desktop_1280 {
                        width: 25%;
                    }
                }
            }
        }

        .wms_area_border {
            bottom: unset;
            position: unset;
            top: unset;
            padding: unset;
        }
    }

    .wms-dropdown-component,
    .wms-input-component {
        width: 25%;
    }
}