@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";

#WindowBulkEditCarrierProto {
    .model-content-wrapper{
        .display-inline{
            display: flex;
            flex-wrap: wrap;
        }
        .wms-combobox-component {
            .k-dropdown-wrap {
                width: 100%;
                height: 32px;
                font-weight: 600;
                font-size: 14px;
                cursor: pointer;
                position: relative;
                z-index: 10;
                border-radius: 4px;
                input {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
        .routing-info-carrier, .routing-info-service {
            width: 261px;
        }
        .disable-options {
            opacity: 0.2;
        }
        .ship-info-SCAC {
            width: 186px;
        }
        .routing-info-billingType {
            width: 142px;
        }
        .routing-info-account-number {
            width: 212px;
        }
        .routing-info-postal-code {
            width: 132px;
        }
        .routing-info-tracking-number {
            width: 487px;
        }
        .wms-input-component {
            .extra-sign {
                top: 25px;
            }
        }
        .instruction-section {
            .wms-text-area-component {
                padding: 0 6px;
                width: 50%;
                textarea {
                    height: 105px;
                }
            }
        }

        #carrierInfoRouting_main {
            .wms-collapsible-content {
                .routing-info-load-number {
                    width: 162px;
                }
                .routing-info-bol-number {
                    width: 162px;
                }
                .routing-info-trailer-number {
                    width: 162px;
                }
                .routing-info-seal-number {
                    width: 215px;
                }

                .routing-info-door {
                    width: 112px;
                }
                .routing-info-capacity-type {
                    width: 262px;
                }
                .wms-datetimepicker-component-proto {
                    width: 322px;
                }
            }
        }
        #carrierRountingInfoShipment_main {
            .wms-collapsible-content {
                padding: 0;
            }
            .display-inline {
                border-bottom: 1px solid #eeeeee;
                padding: 15px 0px 15px 15px;
            }
            .wms-checkbox-component {
                padding: 0 6px;
                label {
                    display: flex;
                    align-items: center;
                    margin: 6px 0;
                    input {
                        margin: 0px 12px 0px 0;
                    }
                }
            }
            .shipping-three-checkbox {
                .chk-return-receipt {
                    width: 207px;
                }
                .chk-saturday-delivery {
                    width: 170px;
                }
                .chk-residential-delivery {
                    width: 184px;
                }
                .chk-return-receipt, .chk-saturday-delivery, .chk-residential-delivery {
                    margin-right: 46px;
                }
            }
            .insurance-section {
                align-items: center;
            }
            .chk-insurance {
                width: 115px;
                margin: 0 30px 0 0;
            }
            .ship-info-insurance-type, .ship-info-insurance-amount, .ship-info-init-non-delivery {
                width: 262px;
            }
            .ship-info-COD-type, .ship-info-init-content-type {
                width: 200px;
            }
            .ship-info-dry-ice-weight {
                width: 150px;
            }
            .ship-info-delivery-confirmation-type {
                width: 270px;
            }
            .chk-cod {
                width: 200px;
            }
        }

        .disable-section {
            opacity: 0.2;
        }
    }
}