@import "../../../scss/variable";
@import "../../../scss/mixin";

#user-beta-holder-react {
	.users-filter-wrapper {
        overflow: auto;
        width: 425px;;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 30px;
		background: rgba(0, 0, 0, 0.03);
        .wms-collapsible-div{
            box-shadow: 0px 3px 5px #0000000D;
        }
		.user-reset-btn {
			margin: 0 0 30px 0;
			flex-direction: row-reverse;
        }
        .wms-radio-wrapper {
			.wms-label {
                margin: 7px;
                margin-left: 5px;
                margin-bottom: 3px;
                display: block;
                margin-top: 2px;
                color: #222222;
			}
		}
	}

    .user-grid-wrapper {
        transition-property: width;
        transition: all 0.4s linear;
        overflow: hidden;
        background: #ffffff;
        overflow-y: auto;
        .user-grid-holder{
            flex: 1;
            height: 100%;
        }
        .wms-grid-holder {
            .k-grid {
                flex: 1;
            }
        }
    }
}
#ManageUserAccountModel {
    .wms_error_msg {
        color: $redbtn;
        padding: 0px 6px;
    }
    .wms_success_msg{
        color: $greenbtn;
        padding: 0px 6px;
    }
    .width-20 {
        width: 22.7%;
    }
    .width-31 {
        width: 30.7%;
    }
    .width-35{
        width: 36%;
    }
    .width-40{
        width: 36.6%;
    }
    .width-60{
        width: 59.9%;
    }
    .width-100{
        width: 100%;
    }
    .wms_confirm_psw{
        width: 39%;
    }
    .display-flex {
        display: flex;
        .wms-checkbox-component{
            // margin-right: 15px;
            margin-right: 46px;
            padding: 0px;
            input{
                margin: 5px 9px 8px 0;
            }
        }
    }
    .display-block {
        display: block;
        .wms-checkbox-component{
            // margin-right: 15px;
            margin-right: 46px;
            padding: 0px;
            input{
                margin: 5px 9px 8px 0;
            }
        }
    }
    .overwrite-update-dropdown-style {
        .k-dropdown{
            width: 310px;
            font-weight: bold;
        }
    }
    #collapseManageUserAccess_main{
        .wms-collapsible-content{
            padding: 0px;
            .wms-user-desc{
                // padding: 15px 15px 0;
                padding-bottom: 15px;
            }
            // .wms-radio-wrapper{
            //     padding: 10px 15px 0px 15px;
            // }
            // .wms-hide-show-dropdown{
            //     padding: 15px;
            // }
        }
    }
    .facility-grid-wrapper {
        border-top: 1px solid #eee;
        padding-top: 17px;
    }
    #ManageUserScrollBox{
        .wms-collapsible-div{
            border: 1px solid #EEEEEE;
            box-shadow: none;
            margin-bottom: 21px;
            .wms_collapsible_header{
                margin-top: -1px;
                .wms-title{
                    // padding-left: 20px;
                    padding: 11px 20px 6px 20px;
                }
            }
            .wms-collapsible-content{
                padding: 17px 31px 4px 20px;
                .wms-input-component, .wms_confirm_psw{
                    // padding-left: 12px;
                    #Login, #Name{
                        width: 205px;
                    }
                    #NewPassword{
                        width: 230px;
                    }
                    #confirmPassword{
                        width: 230px;
                    }
                    #Email{
                        width: 400px;
                        padding: 0 8px 1px;
                    }
                    #CellPhone{
                        width: 150px;
                    }
                    .inputlabel{
                        // padding-bottom: 1px;
                        margin-left: 0px;
                        line-height: unset;
                    }
                    input{
                        font-weight: bold;
                        padding: 0 9px 1px;
                    }
                }
                .wms_confirm_psw{
                    .wms-input-component{
                        &:first-child{
                            padding-left: 6px;
                        }
                    }
                }
                .wms-input-component{
                    margin-bottom: 13px;
                    &:first-child{
                        padding-left: 0px;
                    }
                    // padding-right: 0px;
                }
                .wms-dropdown-component{
                    label{
                        margin-left: 0px;
                        line-height: unset;
                        padding: 0px;
                    }
                    .has_value{
                        padding: 0px;
                    }
                   #CellCarrierId{
                        width: 318px;

                   }
                   .caret{
                        right: 25px;
                        top: 13px;
                   }
                }
                .wms-dropdown-component{
                    padding-left: 6px;
                    padding-right: 6px;
                    select{
                        font-weight: bold;
                        padding-left: 9px;
                        padding-bottom: 1px;
                    }
                }
                .wms-user-access{
                    .wms-dropdown-component{
                        &:first-child{
                            padding-left: 0px;
                            // padding-right: 6px;
                        }
                        .has_value{
                            width: 250px;
                            .caret{
                                right: 11px;
                                top: 14px;
                            }
                        }
                    }
                    .wms-hide-show-dropdown{
                        .wms-kendo-dropdown-update-list-component{
                            padding: 0;
                            margin-bottom: 0px;
                            label{
                                line-height: 1.6;
                            }
                            .selected-values{
                                padding: 14px 0px 9px 0px;
                                .wms_toolbar_button{
                                    padding: 0;
                                    width: auto;
                                    height: 20px;
                                    justify-content: center;
                                    padding-left: 6px;
                                    padding-right: 6px;
                                    // margin-right: 20px;
                                    .wms_sprite{
                                        width: 12px;
                                        height: 12px;
                                        font-size: 12px;
                                    }
                                    .wms_toolbar_button_text{
                                        margin: 0px 9px 0px 4px;
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #collapseManageUserAccess_main{
            .wms-collapsible-content{
                padding-right: 19px;
            }
        }
    }
    .footer-btn-holder{
        .wms-button-component {
            &:first-child{
                margin-right: 12px;
            }
            margin-bottom: 0;
            margin-right: 0;
        }
    }
}
.user-assing-role-wrapper {
    .wms-collapsible-div {
        margin: 0;
        .wms-collapsible-content {
            padding: 0;
            .title {
                height: 45px;
                border-bottom: 1px solid #EEEEEE;
                padding: 10px 20px;
                .wms-sprite {
                    padding-right: 10px;
                    color: #FACA00;
                }
                span {
                    height: 17px;
                    text-align: left;
                    letter-spacing: 0px;
                    color: #222222;
                    opacity: 1;
                }
            }
            .wms-combobox-component {
                padding: 20px;
                margin: 0;
                display: flex;
                flex-direction: column;
                .label {
                    height: 14px;
                    letter-spacing: 0px;
                    color: #222222;
                }
                .k-combobox {
                    padding: 0;
                    margin: 0;
                    .k-dropdown-wrap {
                        width: 350px;
                        height: 32px;
                        background: #FFFFFF;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}