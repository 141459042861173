
@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.transfer-activity-wrapper{ 
    .wms-area-content {
        max-width: 365px;
    }
    .wms-dropdown-component{
        width: 100%;
    }
    .wms-datetimepicker-component {
        .border{
            width: 100%;
        }
    }
    .wms-button-component{
        .button_holder {
            padding: 20px 7px;
            width: 100%;
        }
    }
}