@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-file-component {
    // width: 100%;
    display: inline;
    float: left;
    padding-left: 0;
    margin: 15px 0px;
    @include desktop_1280 {
        margin: 5px 0px;
    }
    div {
        position: relative;
        input[type="file"] {
            width: 100%;
            height: 32px;
            border: 1px solid $input-border;
            padding: 0 10px;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            opacity: 0;
            z-index: 3;

        }
        button {
            cursor: pointer;
            // text-transform: uppercase;
            font-weight: normal;
            border: none;
            padding: 0 30px;
            font-size: 10px;
            color: $primary-white;
            background: $greenbtn;
            // margin-right: 15px;
            &:hover,
            &:focus,
            &:active {
                background: $greenbtn-hover;
            }
        }
        label {
            color: $label-color;
        }
        input[type="file"]:hover+button {
            background: $greenbtn-hover;
        }
        .wms-filename-text {
            font-weight: bold;
            font-size: 13px;
        }
    }
}