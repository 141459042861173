@import "../../../scss/variable";
@import "../../../scss/mixin";
.manage-carrriers-holder-react {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 !important;
    .manage-carrier-left-side {
        width: 425px;
        height: 100%;
        overflow-y: auto;
        padding: 30px;
        background: #f2f3f4;
        .wms-collapsible-div {
            margin-top: 61px;
        }
        .wms-dropdown-component {
            width: 100%;
        }
    }

    .manage-carrier-right-side {
        flex: 1 1;
        background: #fafbfc;
        padding: 29px 30px 0 30px;
        width: 70%;
        .manage-carrier-grid-holder {
            height: 100%;
            .wms-virtual-grid-holder {
                .wms-message-wrapper {
                    display: none;
                }
                .wms_area_content {
                    .wms-grid-details {
                        margin: 30px 0 0 0;
                    }
                    .wms-toolbar-button {
                        .wms-button-component {
                            margin: 0 12px 0px 0;
                        }
                        .reset_btn {
                            ul {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            .carrierGridRefreshButton {
                .wms_toolbar_button {
                    padding: 0 14px 0 14px;
                    .wms_toolbar_button_text {
                        display: none;
                    }
                }
            }
        }
    }
}
