.shipSlipsModel {
    .wms-container-wrapper {
        .wms-area-content {
            .title {
                margin-top:10px;
            }
            .wms-area-content-darker {
                .wms-dropdown-component {
                    width: 100%;
                    margin-bottom:12px;
                }
            }
        }
    }
}