@import "./../../scss/variable";
@import "./../../scss/mixin";

#manage-assembly-holder {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 !important;
    .manage-assembly-search-holder {
        width: 405px;
        min-width: 400px;
        max-width: 405px;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #b7d2e5;
        background: rgba(0, 0, 0, 0.03);
        position: relative;
        transition: all 1s linear;

        .manage-assembly-filter {
            height: 100%;
            display: flex;
            flex-direction: column;

            .default-section {
                margin: 15px 0;
                padding: 0px 15px 0px 5px;

                .wms-button-component {
                    margin-bottom: 15px;
                }
            }

            .filter-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;

                .btn-section {
                    margin-top: 5px;
                    display: flex;
                    justify-content: flex-end;
                }

                .title {
                    width: 100%;
                    float: left;
                    margin: 8px 0 10px 2px;

                    label {
                        font-size: 11px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;
                        color: #5a7e9d;
                    }
                }

                .warehouse-form-boxes {
                    flex: 1;
                    padding: 0px 15px 0px 5px;
                    transition: all ease-in-out 0.3s;
                    overflow: auto;
                    .warehouse-are-content {
                        display: inline-block;
                        border-radius: 4px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        .title {
                            width: 100%;
                            float: left;
                            margin: 0 0 8px 0;
                            label {
                                font-size: 11px;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                font-weight: 600;
                            }
                        }
                        .wms-collapsible-div {
                            .wms_collapsible_header {
                                .wms-title {
                                    padding: 5px 12px 0 20px;
                                }
                            }
                            .wms-collapsible-content {
                                margin-bottom: 0;
                                padding: 15px 5px 5px;
                                .wms-dropdown-component {
                                    width: 100%;
                                }
                                .wms-input-group-wrapper{
                                    .wms-dropdown-component {
                                        width: 125px;
                                    }
                                }
                                ul#MultiSelectCustomerSkus_taglist {
                                    position: absolute;
                                }
                                .wms-datetimepicker-component {
                                    .border {
                                        width: 100%;
                                    }
                                }
                                .wms-input-component {
                                    width: 100%;
                                }
                            }
                        }
                        .form-group {
                            width: 100%;
                            float: left;
                            padding: 0px;
                            margin-bottom: 15px;
                        }
                        .wms_area_border {
                            height: 100%;
                            overflow: hidden;
                            padding: 0 0 26px 0;
                            #gridContainer {
                                height: 100%;
                            }
                        }

                        .filter-list-block {
                            padding-bottom: 8px;
                            margin-top: -4px;
                            li.filter-list {
                                height: 48px;
                                margin: 0px 7px 12px;
                                background-color: transparent;
                                border-radius: 4px !important;
                                padding: 13px 5px 13px 12px;
                                border: 1px solid #bbbbbb;
                                a.title-text {
                                    position: relative;
                                    white-space: nowrap;
                                    width: 100px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: inline-block;
                                }
                                .wms-apply-btn {
                                    float: right;
                                    margin: -7px 0px;
                                    .wms-button-component {
                                        margin-right: 5px;
                                        .wms_toolbar_button_text{
                                            margin-left: 0px;
                                        }
                                    }
                                    .wms-button-component:last-child  {
                                        margin-right: 0px;
                                    }
                                }
                                .clear-save-filter {
                                    float: right;
                                    padding: 2px 5px;
                                    cursor: pointer;
                                    transition: all 0.3s ease-in-out;
                                    i {
                                        font-size: 16px;
                                        color: rgba(255, 0, 0, 0.2);
                                        transition: all 0.3s ease-in-out;
                                    }
                                    &:hover {
                                        color: #2c4059;
                                        i {
                                            color: rgba(255, 0, 0, 1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .wms-dropdown-component,
            .wms-input-component {
                width: 100%;
            }
        }

        .filter {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            border-radius: 0px;
            border: 0px;
            z-index: 1;
            background: #cee1f2;
            cursor: pointer;
            &:hover {
                background: #0079c4;

                .fa-caret-left:before, span {
                    color: #fff;
                }
            }
            .fa-caret-left {
                font-size: 16px;
                padding: 4px 8px;
            }
            span {
                display: none;
            }
        }
    }
    .manage-assembly-grid {
        flex: 1;
        overflow-x: auto;
        margin: 0 0 20px 0;
        .wms-virtual-grid-holder {
            .wms_area_content {
                .wms-toolbar-button {
                    float: right;
                    display: flex;
                    flex-wrap: wrap;
                    .assemblyRefreshBtn {
                        .primary_button_color {
                            padding: 0px 16px;
                            .wms_toolbar_button_text {
                                display: none;
                            }
                        }
                    }
                    .wms-button-component {
                        margin-right: 15px;
                    }
                }
            }

            .wms-grid-area-wrapper{
                max-height: none;
            }
        }
    }
}
#CancelAssemblyModal {
    #AssemblyDetails_main{
        .wms-collapsible-content{
            .charge-detail-wrapper{
                .left-content-wrapper,
                .right-content-wrapper {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding: 0 6px;
                    .content {
                        display: flex;
                        justify-content: space-between;
                        label {
                            display: flex;
                            flex: 1;
                            font-size: 14px;
                            line-height: 19px;
                            top: 264px;
                            left: 610px;
                            height: 19px;
                            text-align: left;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                        }
                        span {
                            display: inline-table;
                            max-width: 200px;
                            text-align: right;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 19px;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                        }
                    }
                }
                hr{
                    margin: 10px -15px;
                }
                .wms-text-area-component textarea{
                    padding: 5px 10px;
                }
            }
        }
    }

}
#CreateNewAssemblyModel {
    .sku-dropdown-wrapper{
        margin-top: 5px;
    }
    .wms-text-area-component{
        padding: 0 6px;
    }
    .location-dropdown-list{
        padding-top: 5px;
    }
}
#ImportAssembliesModel {
    .template-upload-wrapper {
        display: flex;
        flex-direction: column;

        .import-assembly-area{
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
            label {
                margin-bottom: 5px;
            }

            .import-area{
                border: 2px dashed #ccc;
                border-radius: 5px;
                padding: 10px;
                .import-drag-area{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0px 0px 15px;
                    position: relative;
                    &.is-dragged {
                        border: 2px dashed #005ca8;
                    }
                    .file-icon-and-name{
                        padding-top: 25px;
                        display: flex;
                        align-items: center;
                    }
                    .wms-file-component {
                        position: absolute;
                        width: 100%;
                        height: 80%;
                        opacity: 0;
                        div{
                            height: 100%;
                            input{
                                height: 100%;
                            }
                        }
                    }
                    i {
                        font-size: 50px;
                        margin-bottom: 15px;
                        color: #bbb;
                    }
                    hr {
                        width: 50%;
                    }
                    .import-file-name-and-size{
                        padding-left: 20px;
                        padding-right: 20px;
                        font-weight: 600;
                        span{
                            padding-left: 5px;
                        }
                    }

                }
                .button-area{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;

                }
            }
        }
    }
}
#EditAssemblyModel {
    .model-content-wrapper{
        min-height: 500px;
    }
    #EditAssemblyTransactionDetailInfo_main{
        .wms-collapsible-content{
            .transaction-information-wrapper{
                .left-content-wrapper,
                .right-content-wrapper {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding: 0 6px;
                    .content {
                        display: flex;
                        justify-content: space-between;
                        label {
                            display: flex;
                            flex: 1;
                            font-size: 14px;
                            line-height: 19px;
                            top: 264px;
                            left: 610px;
                            height: 19px;
                            text-align: left;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                        }
                        span {
                            display: flex;
                            top: 264px;
                            left: 752px;
                            height: 19px;
                            width: 190px;
                            text-align: left;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 19px;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                        }
                    }
                }
                hr{
                    margin: 10px -15px;
                }
                .wms-text-area-component textarea{
                    padding: 5px 10px;
                }
            }
        }
    }
    #EditAssemblyChargesInfo_main{
        .wms-collapsible-content {
            .charge-grid-wrapper {
                flex-direction: column;
                .wms-grid-holder {
                    .k-grid {
                        height: 400px !important;
                    }
                    padding-bottom: 0;
                    .charge-delete {
                        border: none;
                        padding: 0;
                        margin: 0;
                        color: #EF0000;
                    }
                }
                .charge-totalWrapper {
                    display: flex;
                    justify-content: flex-end;
                    padding-bottom: 20px;
                    .total-text {
                        height: 32px;
                        font-weight: 700;
                        color: #222222;
                        padding: 6px 10px;
                        background-color: #e2effc;
                        background-color: #e2effc;
                        border: 1px solid #e2effc;
                        border-right: none;
                    }
                    .wms-input-component {
                        width: 100%;
                        padding: 0;
                        label {
                            display: none;
                        }
                        input {
                            border-left: none;
                            border: 2px solid #e2effc;
                        }
                    }
                }
            }
        }
    }
    #EditAssemblyAllocationInfo_main{
        .wms-grid-holder {
            .k-grid {
                height: 400px !important;
            }
        }
        #ManageAssemblyallocationGrid{
            .k-grid-content{
                height: unset !important;
                min-height: 300px;
                .k-virtual-scrollable-wrap{
                    min-height: 300px;
                }
                .allocation-row-edit-button{
                    background: none;
                    border: none;
                }
                .edit-assembly-allocation-more-info{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    padding: 0 6px;
                    width: 60%;
                    .content {
                        display: flex;
                        justify-content: space-between;
                        label {
                            display: flex;
                            flex: 1;
                            font-size: 14px;
                            line-height: 19px;
                            top: 264px;
                            left: 610px;
                            height: 19px;
                            text-align: left;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                        }
                        span {
                            display: flex;
                            top: 264px;
                            left: 752px;
                            height: 19px;
                            width: 190px;
                            text-align: left;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 19px;
                            letter-spacing: 0px;
                            color: #222222;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    #EditAssemblyInventoryDetailInfo_main{
        .location-dropdown-list{
            margin-top: 4px;
        }
        .split-line-by-movable-unit{
            margin-top: 20px;
            margin-left: 35px;
          }
    }
}

#EditAllocationModel {
    width: 100%;
    height: auto;
    display: flex;
    .model-content-wrapper {
        padding: 0px !important;
        display: flex;
        overflow: hidden;
        .grid-filter-wrapper {
            width: 100%;
            display: flex;

            .side-content {
                display: flex;
                flex-direction: column;
                width: 345px;
                min-width: 345px;
                max-width: 345px;
                padding: 0 5px;
                padding: 30px 15px 30px 30px;
                overflow: auto;
                overflow-x: hidden;
                padding-right: 0;

                .wms-button-component {
                    float: left;
                }

                .wms-area-header {
                    width: 100%;
                    float: left;
                    font-size: 14px;
                    padding: 5px 0 10px;
                    color: $label-color;
                }

                .wms-toolbar {
                    margin-bottom: 10px;
                }

                #LineItemInfoSection {
                    margin-right: 15px;
                }

                .title {
                    margin-top: 10px;
                    margin-left: 5px;
                }

                .wms-area-content-darker {
                    margin: 0;

                    .wms-input-component {
                        width: 100%;
                    }
                    .wms-input-group-wrapper {
                        .wms-input-component  {
                            width: 50%;
                        }
                    }

                    .form-group {
                        width: 100%;
                        margin-bottom: 10px;
                    }

                    .wms-datetimepicker-component {
                        .form_group {
                            @extend .form-group
                        }
                    }

                    ul {
                        li {
                            width: 100%;
                            float: left;
                            h3 {
                                margin: 0;
                                font-size: 13px;
                                width:44%;
                                text-align:right;
                                line-height: 24px;
                                padding-right: 5px;
                                font-weight: bold;
                                color: $window-title;
                            }

                            p {
                                color: $label-color;
                                text-align:left;
                                width:56%;
                                margin: 0;
                                line-height: 24px;
                                font-size: 13px;
                                padding-left: 5px;
                                word-break: break-word;
                            }
                        }
                    }
                }

                .without-bg {
                    padding: 0px 10px 5px;
                    display: inline-block;

                    .pull-left {
                        float: left;
                    }
                }

            }

            .grid-section {
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow: auto;
                padding: 30px 30px 30px 15px;
                .wms-warning-wrapper{
                    padding:0;
                    border: none;
                    top: 40px;

                }
                .alert-not {
                    color: #ff0006;
                    width: auto;
                    display: inline-block;
                    vertical-align: middle;
                }

                .wms_area_border {
                    top: 85px;
                    padding: unset;
                }

                .title-block {
                    width: 100%;
                    float: left;

                    label {
                        color: #5a7e9d !important;
                        display: inline-block;
                        margin-right: 8px;
                        vertical-align: middle;
                        color: $label-color;
                        font-size: 11px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;

                        .EditAllocationsTotalResults {
                            float: left;
                            margin-right: 5px;
                        }

                        &.sort {
                            float: right;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: calc(100% - 109px);
                            vertical-align: middle;
                            text-align: right;
                            margin-top: 5px;

                            span {
                                color: #7d7d7d !important;
                            }
                        }
                    }
                }

                .k-grid .k-grid-header {
                    width: 100%;
                    float: left;
                }
            }
        }
    }

}
#EditAssemblyModel, #CreateNewAssemblyModel {
    .display-inline {
        display: flex;
        padding: 0 15px;
    }
    .k-grid-header{
    padding: 0px !important;
    }
    .width-100 {
    width: 100%;
    }
    .width-70 {
    width: 70%;
    }
    .width-60 {
    width: 60%;
    }
    .width-40 {
    width: 40%;
    }
    .width-20 {
    width: 20%;
    }
    .width-30 {
    width: 30%;
    }
    .width-25 {
    width: 25%;
    }
    .hide {
    display: none !important;
    }
}
