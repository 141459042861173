@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.wms-datetimepicker-component {
    padding: 0 6px;
    margin-bottom: 10px;
    label {
        @include label;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .form_group {
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 7px;
            right: 7px;
            bottom: 0;
            background: transparent;
        }
    }
    .border {
        width: 100%;
        float: left;
        position: relative;
        padding: 0px 0px;
        margin-right: 0px;
        margin-bottom: 10px;
        .k-datetimepicker {
            width: calc(100% - 16px);
            height: 32px;
            float: left;
            border: 1px solid $border-color;
            border-right: none;
            background-color: transparent;
            border-radius: 4px 0px 0px 4px;
            .k-select {
                width: 45px;
                line-height: 40px;
                z-index: 11;
                margin: 4px;
                background: transparent;
                color: $datepicker-icon;

                .k-link {
                    .k-icon {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        &::before {
                            font-size: 12px;
                        }
                    }
                }
            }
            input {
                border: none !important;
            }
            .k-picker-wrap {
                border: none;
                background: transparent;
                input {
                    border: none;
                    height: 32px;
                    font-weight: 600;
                    color: $input-color;
                    font-size: 14px;
                }
                .k-select {
                    background: transparent;
                }
            }
            _:-ms-lang(x),
            _:-webkit-full-screen,
            .k-picker-wrap {
                width: 70%;
            }
        }
        .datePicker_clearer {
            float: left;
            width: 16px;
            margin-left: -1px;
            border: 1px solid #bbbbbb;
            border-left: none;
            height: 32px !important;
            text-align: center;
            line-height: 40px;
            border-left: 0px;
            color: $primary-white;
            cursor: pointer;
            position: relative;
            z-index: 10;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                color: rgba($color: red, $alpha: 0.2);
                font-size: 12px;
                padding-right: 7px;
                transition: all 0.3s linear;
            }
            &:hover {
                i {
                    color: rgba($color: red, $alpha: 0.8);
                }
            }
            &.wms_required_datepicker{
                border: 1px solid $requireinput-border !important;
                border-left: none !important;
                &:focus {
                    border-color: $primary-themecolor !important;
                }
            }
        }
        .k-state-focused {
            box-shadow: none;
        }
    }
    .datepicker_has_value {
        label {
            font-size: 10px;
            line-height: 22px !important;
            left: 7px;
        }
    }
    .only_date {
        .k-link-time {
            display: none;
        }
    }
    .wms_required_datepicker {
        border: 1px solid $requireinput-border !important;
        border-right: none !important;
        &:focus {
            border-color: $primary-themecolor !important;
        }
    }
}
.k-animation-container{
    .k-calendar-container{
        background: white;
        padding: 0px 10px;
        .k-widget{
            &.k-calendar{
                .k-footer{
                    .k-link{
                        font-size: 14px;
                        font-weight: 600;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}