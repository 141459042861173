@import "../../../scss/variable";
@import "../../../scss/mixin";

.cyclecount-holder-react {
    height: 100%;
    display: flex;
    flex-direction: column;

    .select-field {
        // float: left;
        border-bottom: 1px solid $seprator-color;
        .wms-dropdown-component {
            max-width: 275px;
            @include desktop_1600 {
                max-width: 225px;
            }
        }
    }
    .wms-input-group-wrapper {
        width: 50%;
        .wms-dropdown-component {
            width: 50%;
        }
    }
    .main_content {
        display: flex;
        flex: 1;
        .wms-grid-wrapper {
            margin-bottom: 5px;
        }
        .cyclecount-grid-section {
            height: 100%;
            width: 40%;
            padding: 15px 15px 15px 0px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            .wms-grid-wrapper {
                flex: 1;
                .wms-toolbar-holder {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    .reset-btn {
                        border: none;
                    }
                }
            }
        }
        .cyclecount-form-section {
            flex: 1;
            display: flex;
            border-left: 1px solid $seprator-color;
            overflow: auto;
            .location-form-section {
                width: 60%;
                padding: 15px;
                border-right: 1px solid $seprator-color;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                .wms-grid-wrapper {
                    .wms-grid-holder {
                        tbody {
                            .wms-location-allocated {
                                background-color: #d7ebf9 !important;
                            }
                        }
                    }
                }
            }
            .job-form-section {
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 15px;
                overflow-y: auto;
                .wms-input-component,
                .wms-dropdown-component,
                .wms-checkbox-component {
                    width: 100%;
                }
                .wms-grid-wrapper {
                    .wms-grid-holder {
                        .no-josh {
                            .k-grid-content {
                                .k-virtual-scrollable-wrap {
                                    table {
                                        tbody {
                                            tr {
                                                &.placeholder {
                                                    background-color: #dceffd !important;
                                                    color: #52aef7;
                                                    text-align: right;
                                                    line-height: 35px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cyclecount-reveiew-reconcile-wrapper {
            height: 100%;
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 15px;
            border-left: 1px solid $seprator-color;
            overflow-y: auto;
            .wms-btn-wrapper {
                display: flex;
                .export-btn-wrapper {
                    .wms-tooltip-component {
                        position: relative !important;
                        .div_tooltip {
                            left: 0;
                        }
                    }
                }
            }
            .k-grid-header {
                th {
                    border-right: 1px solid #b7d2e5;
                }
            }
            .k-grid-content {
                .wms-variance-allocated {
                    background-color: #fef9e4 !important;
                }
            }

            .sku-click {
                color: blue !important;
                .k-icon {
                    color: #656565 !important;
                }
            }
            .sku-click:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .wms-container-wrapper {
        .wms-area-content-darker {
            display: flex;
            flex-direction: column;
            .inner-filter-section {
                display: flex;
                align-items: center;
                .wms-datetimepicker-component {
                    width: 50%;
                }
                .wms-rounded-checkbox {
                    padding: 0 7px;
                }
            }
        }
    }
    .title {
        display: block;
        padding: 5px 0;
        label {
            display: block;
            color: #5a7e9d;
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
/////  Sortable css
.hint {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    display: block;
    width: 200px;
    background-color: #52aef7;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    cursor: move;
    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #52aef7;
        position: absolute;
        left: 200px;
        top: 8px;
    }
    &:last-child {
        border-radius: 4px;
    }
    span {
        visibility: hidden;
    }
}
/////  Sortable css

//cycle count modal style
#otherLocationModel,
#adjustQuanity {
    .wms-grid-wrapper {
        margin: 0;
        .wms-grid-holder {
            min-height: 90px;
            .k-grid {
                .k-grid-content {
                    min-height: 90px;
                }
            }
        }
    }
    .title {
        width: 100%;
        padding: 5px 0px 0px 3px;
        display: inline-block;

        label {
            color: #5a7e9d;
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
        }
    }
}
