@import "../../../scss/variable";
@import "../../../scss/mixin";

#import-asn-holder {
    .wms-area-content {
        margin-bottom: 0px;

        .wms-dropdown-component {
            width: 33.33%;
        }
    }
    .wms-warning-wrapper {
        margin-bottom: 20px;
        &.in-import{
            border-bottom: 1px solid #eeeeee;
            color: #222222;
        }
    }
    .wms-file-component {
        margin: 0px 0px 15px;
        .wms-button-component {
            .wms_toolbar_button {
                padding: 0px 30px;
            }
        }
    }

    .row {
        width: 100%;
        float: left;

        .chk_label_mng_description{
            display: flex;
            flex-direction: column;
            .warning-txt{
                font-size: 12px;
            }
        }

        .without-bg {
            padding: 0;
            border: none;
            background: transparent;

            .wms-area-content-darker {
                padding: 12px;
                margin-bottom: 20px;
                border: none;
                background: transparent;

                .wms-standard-button {
                    float: left;
                    padding: 0 20px;
                    line-height: 30px;
                    background: $primary-themecolor;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: bold;
                    transition: 0.2s ease-in-out;
                    -webkit-transition: 0.2s ease-in-out;
                    -moz-transition: 0.2s ease-in-out;

                    a {
                        display: block;
                        text-decoration: none;
                        color: #fff;
                        .k-icon.k-i-download {
                            font-size: 15px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .pd-l {
        width: 100%;
        float: left;
        margin-bottom: 10px;
        padding-left: 7px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .GridSection {
        .FormManageAsnImport {
            position: absolute;
            top: 54px;
            left: 0;
            right: 0;
            bottom: 0;

            .success-msg {
                font-size: 14px;
                padding: 15px 0px 0 26px;
                color: #83de52;
            }

            .grid-with-title {
                clear: both;
                min-height: 200px;
                padding: 26px;
                padding-top:0;

                .showHeader {
                    .title {
                        font-size: 11px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;
                        display: block;
                        margin-bottom: 8px;
                        margin-left: 0px;
                        color: #5a7e9d;
                    }
                    .wms-form-grid-component {
                        .form-grid.k-grid {
                            .k-grid-content {
                                height: auto !important;
                            }
                        }
                    }
                }
            }

            .secondImportBtn {
                padding: 0 26px;
            }
        }
    }
}