@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-kendo-dropdown-update-list-component {
    padding: 0 6px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    label {
        font-size: 10px;
        line-height: 1.2;
        margin-left: 1px;
        font-weight: normal;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 3px;
        color:#222222 ;
    }

    .k-dropdown {
        width: 100%;
        height: 32px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        z-index: 10;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid #bbbbbb;

        .k-dropdown-wrap {
            border: none;
            cursor: pointer;
            color: #7d7d7d;
            background: rgba(255, 255, 255, 0);
            display: flex;
            justify-content: flex-end;
            &.k-state-focused {
                border-color: 1px solid #bbbbbb;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
            }
            &.k-state-selected{
                color:#656565;
                background-color: transparent;
            }
            &.k-state-hover {
                background: rgba(255, 255, 255, 0);
            }

            .k-input {
                display: none;
            }
            .k-select {
                .k-icon {
                    color: #2c405a;
                }
            }
        }

    }
    .wms-dropdown-clear {
        background-color: #f2f3f4;
        border: 1px solid #bbbbbb;
        i {
            color: rgba($color: red, $alpha: 0.2);
        }
        &:hover  {
            i {
                color: rgba($color: red, $alpha: 0.8);
            }
        }
        &:focus {
            border: 1px solid #0079c4;
        }
    }
    .k_state_disabled {
        outline: none;
        cursor: default;
        opacity: .6;
        filter: grayscale(0.1);
        pointer-events: none;
    }
    .wms-dropdown-clear {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 31px;
        margin-left: -5px;
        text-align: center;
        line-height: 40px;
        border-left: 0px;
        cursor: pointer;
        z-index: 10;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        i {
            font-size: 16px;
            // margin-top: 12px;
            transition: all 0.3s linear;
        }
        &:focus {
            border-left: none;
        }
    }
    .selected-values{

        padding: 10px 0px 5px 0px;
        button{
            height: 25px;
        }
        .wms_toolbar_button{
            padding:0 5px 0 10px;
            display:flex;
            height: 20px;
            flex-direction:row-reverse;
            .wms_sprite{
                margin: 0;
                line-height: 20px;
            }
            .wms_toolbar_button_text{
                margin: 0 5px;
                line-height: 20px;
                font-size: 10px;
            }
        }
    }
    &.no-value-selected {
        .k-dropdown{
            &.wms_required_input{
                border: 1px solid #d79093;
            }
        }
    }
}