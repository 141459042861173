.note {
    .warning-text-wrapper {

        word-break: break-word;
        overflow: auto;
        color: #222222;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        padding: 10px 10px 00px 10px;

        .warning-icon-text {
            color: #F48831;
            font-size: 14px;
            font-weight: bold;
        }

    }
}
