@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";

.ManageLineItemsModal {
    .width_25 {
        width: 25% !important;
    }
    .with-50 {
        width: 50% !important;
    }
    .wms-toolbar {
        width: 100%;
        float: left;
        margin-bottom: 10px;
        .cst-btn {
            height: 40px;
            margin-left: 5px;
        }
    }
    .k-grid {
        min-height: 400px;
    }
    .form {
        top: 15px;
        left: 15px;
        right: 15px;
        .wms-container-wrapper {
            margin-top: 10px;
            .wms-area-content {
                .wms-area-content-darker {
                    margin-bottom: 10px;

                    .wms-kendo-dropdown-list-component,
                    .wms-input-component,
                    .wms-checkbox-component,
                    .wms-dropdown-component,
                    .wms-combobox-component{
                        width: 25%;
                        margin-bottom: 9px !important;
                        padding: 0 6px;
                    }
                    .wms-kendo-dropdown-list-component {
                        margin-bottom: 12px !important;
                        width: 50%;
                    }
                    .wms-checkbox-component {
                        margin-top: 10px;
                    }
                    .wms-input-component {
                        float: left;
                    }
                    .wms-kendo-dropdown-list-component{
                        float: left;
                        position: relative;
                        margin-top: 4px;
                        // margin-bottom: 9px;

                    }
                    .wms-datetimepicker-component{
                        .form_group{
                            width: 24%;
                        }
                    }
                    .wms-button-component{
                        .button_holder {
                            button{
                                height: 40px;
                                margin-left: 5px;
                            }
                        }
                    }
                    .wms-grid-wrapper {
                        padding: 0 7px;
                    }
                    .row {
                        .form-group {
                            @include desktop_1280 {
                                margin-bottom: 12px;
                            }
                            @include desktop_1199 {
                                margin-bottom: 15px;
                            }
                            .k-combobox {
                                width: 100%;
                                height: 32px; //border: 1px solid #d8e5ee;
                                .k-dropdown-wrap,
                                input {
                                    border: none;
                                }
                                .k-dropdown-wrap {
                                    border: 1px solid #d79093;
                                }
                                .k-select {
                                    background: transparent;
                                }
                            }
                            .wms-required-input {
                                border: none;
                            }
                        }
                        .wms-required-input {
                            border: 1px solid #d79093 !important;
                        }
                    }
                    .wms_area_border{
                        position: inherit;
                        padding: 0 6px 10px;
                        .gridContainer {
                            width: 100%;
                            float: left;
                        }
                    }
                    .wms-element-wrapper {
                        display: flex;
                        align-items: center;
                        .wms-button-component  {
                            margin-top: 10px;
                        }
                    }
                }
                .wms-area-content-darker.without-bg {
                    border: none;
                    background: transparent;
                    padding: 0;
                    .wms-sub-grid-header {
                        margin-bottom: 10px;
                        float: left;
                    }
                    .checkbox {
                        padding: 0px;
                        width: auto;
                    }
                }
            }
        }
    }
}