@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-version-checker-wrapper {
    position: fixed;
    z-index: 999999;
    pointer-events: none;
    right: 12px;
    bottom: 12px;
    .wms-version-checker-container {
        width: 450px;
        background-image: unset !important;
        border: 2px solid #f89407;
        color: #8a6d3b;
        background-color: #fcf8e3;
        font-size: 15px;
        text-align: center;
        opacity: 1;
        padding: 25px 15px;
        min-width: 300px;
        position: relative;
        pointer-events: auto;
        overflow: hidden;
        margin: 0 0 6px;
        border-radius: 3px;
        background-position: 15px center;
        background-repeat: no-repeat;
        box-shadow: 0 0 12px #999;
    }
}
