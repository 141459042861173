@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.wms-datetimepicker-component-proto-kendo {
    width: 280px;
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    float: left;
    position: relative;
    label {
        font-size: 10px;
        line-height: 1.2;
        margin-left: 1px;
        font-weight: normal;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333333;
        height: 14px;
    }
    .border {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid $border-color;
        border-radius: 4px;
        margin-top: 5px;
        .datePicker_options {
            display: flex;
            flex: 1;

            .wms-DatePicker-main{
                display: flex;
                flex-grow: 1;
                align-items: center;
                border-right: 1px solid #bbbbbb;
                position: relative;
                input, select {
                    background-color: var(--transparent-bg);
                    color: #333333;
                    border: none;
                    font-size: 12px;
                    font-weight: 700;
                    padding: 6px 15px 7px 6px;
                    position: relative;
                    -moz-appearance:none; /* Firefox */
                    -webkit-appearance:none; /* Safari and Chrome */
                    appearance:none;
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    
                }
                span.caret {
                    display: block;
                    position: absolute;
                    margin: auto;
                    z-index: 10;
                    pointer-events: none;
                    border-right: 4px solid transparent;
                    border-left: 4px solid transparent;
                    border-top: 5px solid #2c405a;
                    pointer-events: none;
                    top: 14px;
                    right: 4px;
                }
                .k-datepicker {
                    width: 145px;
                }
                select {
                    width: 90px;
                }

                &.option-disabled {
                    select {
                        outline: none;
                        cursor: default;
                        opacity: .6;
                        filter: grayscale(0.1);
                        pointer-events: none;
                    }
                    span.caret {
                        opacity: .6;
                    }
                }
                .k-datepicker {
                    flex: 1;
                    .k-picker-wrap{
                        border: 0;
                    }
                }
            }
        }

        .datePicker_clearer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            cursor: pointer;
            border: 1px solid var(--input-border);
            border-radius: 0 4px 4px 0;
            border-left: none;
            background-color: var(--transparent-bg);
            color: rgba(255, 0, 0, 0.2);
            border: none;
            &:hover {
                color:rgba(255, 0, 0, 0.8);
            }
            i {
                font-size: 16px;
                transition: all 0.3s linear;
            }
        }

        &.dropdown-disabled {
            cursor: default;
            opacity: .6;
            pointer-events: none;
            outline: none;
            filter: grayscale(0.1);
        }
    }
}