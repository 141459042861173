@import "../../../../scss/variable";
@import "../../../../scss/mixin";
#smart-parcel-settings-holder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .select-field {
        width: 100%;
        float: left;
        margin-left: -2px;
        position: relative;
        .wms-area-content {
            max-width: 565px;
            @include desktop_1600 {
                max-width: 450px;
            }

        }
        .top-fields {
            .wms-area-content-darker {
                margin: 5px 0px 0px;
            }
            .without-bg {
                border: none;
                padding: 0;
                background: none;
                .wms-dropdown-component {
                    margin-top: 8px;
                }
            }
        }
    }
    #main-content{
        width: 100%;
        height: calc(100% - 35px);
        float: left;
        position: relative;
    }
    .smart-parcel-settings-main-content {
        width: calc(100% - 5px);
        height: calc(100% - 66px);
        float: left;
        position: relative;
        margin-left: 5px;
        margin-top: 4px;
        padding-top: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: $seprator-color;
        }
        .wms-container-wrapper  {
            &:first-child {
                .wms-area-content-darker {
                    padding-top: 4px;
                    display: flex;
                    align-items: center;
                }
            }
            .wms-area-content-darker {
                .wms-smartparcel-note {
                    margin: 7px 0;
                    font-size: 12px;
                    color: #5a7e9d;
                    float: left;
                }

                .wms-dropdown-component {
                    width: 33.33%;
                    @include desktop_1440 {
                        width: 33.33%;
                    }
                    @include desktop_1199 {
                        width: 50%;
                    }
                }
                .custom-half-width-Dropdown{
                    .wms-dropdown-component {
                        width: 33.33%;
                    }
                }
                .wms-checkbox-wrapper {
                    display: inline-block;
                    width: 100%;
                }
                .wms-wrapper {
                    display: inline-block;
                    width: 33.33%;
                    @include desktop_1280 {
                        width: 50%;
                    }
                    @include desktop_1199 {
                        width: 50%;
                    }
                    .wms-dropdown-component {
                        margin-bottom: 5px;
                        width: 100%;
                    }
                    .wms-smartparcel-note {
                        margin: 0 0 12px 8px;
                        font-size: 12px;
                        color: #5a7e9d;
                        margin-bottom: 15px;
                        display: inline-block;
                        width: 100%;
                    }
                }
                .wms-radio-wrapper {
                    display: inline-block;
                    width: 100%;
                    .wms-label {
                        padding: 0px 10px 8px;
                        display: block;
                        color: #5a7e9d;
                        font-size: 12px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;
                    }
                }
                .wms-rate-shopping-wrapper {
                    display: inline-block;
                    .wms-checkbox-component {
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                }
            }
        }
        .wms-container-wrapper {
            .wms-area-content{
                .wms-area-content-darker{
                    .wms-dropdown-box-checkbox{
                        display: flex;
                        align-items: center;
                    }
                    .wms-input-dropdown-btn-block{
                        display: flex;
                        align-items: center;
                        .wms-add-and-remove-btn-block{
                            margin-top: 10px;
                        }
                        .wms-input-component {
                            @include desktop_1280{
                                width: 35%;
                            }
                        }
                        .wms-dropdown-component {
                            @include desktop_1280{
                                width: 35%;
                            }
                        }
                    }
                    .wms-form-grid-holder{
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}