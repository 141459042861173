@import "./../../../scss/variable";
@import "./../../../scss/mixin";

#CustomerPackingSlips_collapse_main {
    .wms-collapsible-content {
        padding: 0;

        .wms-virtual-grid-holder{
            padding: 5px;
        }
    }
    .k-grid-content {
        min-height: 350px;
    }
}

#PackingSlipsTemplate {
    .slip-template-info {
        display: flex;
        .info-text {
            width: 144px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            font-family: "Open Sans";
            letter-spacing: 0px;
            color: #222222;
        }
        .info-value {
            font-size: 16px;
            line-height: 22px;
            font-family: "Open Sans";
            color: #222222;
        }
    }
    .wms-collapsible-div  {
        margin-top: 30px;
        .wms-collapsible-content {
            padding: 0;
        }


    }
}
.image-wrapper {
    display: flex;
    padding: 15px;
    .img-section {
        display: flex;
        flex-direction: column;
        position: relative;
        img {
            width: 125px;
            height: 125px;
            border: 1px dashed #BBBBBB;
            border-radius: 4px;
            margin-top: 5px;
        }
        &.is-dragged {
            img {
                border: 1px dashed #005ca8;
            }
        }
        .wms-file-component{
            position: absolute;
            width: 100%;
            height: 100%;
            input {
                width: 125px;
                height: 125px;
                margin-top: 20px;
                opacity: 0;
            }
            .wms-button-component  {
                opacity: 0;
            }
        }
    }
    .image-source-info-section {
        margin: 15px;
        .image-path-url{
            margin-bottom: 0px;
            &.wms_invalid_input{
                input {
                    border: 2px solid #d79093;
                }
            }
        }
        .img-size-info{
            margin-top: 0px;
            padding-left: 6px;
        }
        .img-info {
            margin-left: 5px;
            margin-top: 20px;
            font-size: 10px;
            line-height: 14px;
            font-family: "Open Sans";
            color: #222222;
        }
    }
    .or-section {
        margin: 37px 10px 0 0;
    }
    .browse-reset-section {
        margin: 17px 0 0 0;
        display: flex;
        flex-direction: column;
        .btn-wrapper {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            .button_holder  {
                button {
                    padding: 0 10px;
                    .wms_toolbar_button_text {
                        display: none;
                    }
                }
            }
        }
        .wms-file-component {
            .wms-button-component {
                margin: 0;
            }
        }
    }
    .image-source-info-section .wms-input-component  {
        width: 100% !important;
    }
}
.Default_Template{
    .img-section > label {
        font-size: 12px;
    }
}
hr {
    border: 1px solid #eeeeee;
}