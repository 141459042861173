.send-asn-model {
    .wms-area-content {
        .title {
            color: #5a7e9d;
            letter-spacing: 1px;
            font-weight: 600;
        }
        .wms-area-content-darker {
            display: flex;
            flex-direction: column;
            .wms-format-wrapper{
                display: flex;
                margin: 5px 0;
            }
            .wms-selected-order-count{
                padding-left: 7px ;
            }
            .wms-dropdown-component {
                flex: 1;
                margin-right: 5px;
            }
            .wms-button-component{
                margin: auto;
                width: 160px;
            }
            .full-width {
                .checkbox{
                    label{
                        overflow: visible;
                    }
                }
                clear: both;
            }
            .title_label {
                width: calc(100% - 160px);
                float: left;
                padding: 7px 7px 7px 8px;
                margin-right: 5px;
                .title {
                    display: inline-block;
                    width: auto;
                    margin: 0px;
                    span{
                        margin-left: 10px;
                        font-size: 13px;
                    }
                }
            }
            
        }
    }
    .model-content-wrapper{
        .wms-grid-wrapper{
            .wms-grid-holder {
                min-height: 120px !important;
                .k-grid{
                    height: auto !important;
                    .k-grid-content{
                        height: 180px !important;
                    }
                }
            }
        }
    }

}