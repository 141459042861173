
.loadingModal{
    .loading-overlay {
            /* Position the content inside the overlay */
        padding-top: 5px;
        .loading-overlay-content {
            .message_text{
                color: #333333;
                font-weight: bold;
                font-size: 14px;
                text-align: center;
            }
            .message_info{
                text-align: center;
                color: #333333;
                font-size: 12px;
                .k-progressbar{
                    width: 100%;
                    margin-top: 30px;
                    background-color:#f2f3f4;
                    height: 10px;
                    margin-bottom: 10px;
                    .k-progress-status{
                        padding: 0 1.5em;
                    }
                    .k-state-selected{
                        background:#92C547;
                    }
                }
            }
            .wms-button-component {
                align-items: center;
                justify-content: center;
            }
        }
    }
}