@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.addEditColumn{
    .wms-area-content {
        .wms-area-content-darker {
            padding: 12px 6px;
            columns: 4;
            -webkit-columns: 4;
            -moz-columns: 4;
            .checkbox-container{
                width: 100%;
                float: left;
                .wms-checkbox-component {
                    margin-bottom: 0;
                }
            }
        }
    }
}
