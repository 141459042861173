@import "../../../scss/variable";
@import "../../../scss/mixin";
#import-order-holder-react {
    display:flex;
    height: 100%;
    width: 100%;
    .wms-warning-wrapper {
        margin-bottom: 20px;
        &.in-import{
            border-bottom: 1px solid #eeeeee;
            color: #222222;
        }
    }
    #import-order-grid {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .wms-form-grid-holder {
            flex: 1;
            display: flex;
            flex-direction: column;
            .k-grid {
                height: 99%;
                .k-grid-content {
                    max-height: unset;
                    table {
                        width: 100% !important;
                    }
                }
            }
        }
    }
    .wms-error-text {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        margin-bottom: 8px;
        display: block;
    }
    .wms-area-content {
        margin-bottom: 0px;
        .wms-dropdown-component {
            width: 33.33%;
        }
    }
    .radio-btnlist {
        display: inline-block;
        padding-bottom: 20px;
        width: 100%;
    }
    .wms-file-component {
        margin: 0px 0px 15px;
        .wms-button-component {
            .wms_toolbar_button {
                padding: 0px 30px;
            }
        }
    }
    .row {
        width: 100%;
        float: left;
        .chk_label_mng_description{
            display: flex;
            flex-direction: column;
            .warning-txt{
                font-size: 12px;
            }
        }
        .without-bg {
            padding: 0;
            border: none;
            background: transparent;
            .wms-area-content-darker {
                padding: 12px;
                margin-bottom: 20px;
                border: none;
                background: transparent;
                .wms-standard-button {
                    float: left;
                    padding: 0 20px;
                    line-height: 30px;
                    background: $primary-themecolor;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: bold;
                    transition: 0.2s ease-in-out;
                    -webkit-transition: 0.2s ease-in-out;
                    -moz-transition: 0.2s ease-in-out;
                    a {
                        display: block;
                        text-decoration: none;
                        color: #fff;
                        .k-icon.k-i-download {
                            font-size: 15px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
    .showHeader {
        .title {
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
            display: block;
            margin-bottom: 8px;
            margin-left: 0px;
        }
        .wms-form-grid-component {
            .form-grid.k-grid {
                .k-grid-content {
                    height: auto !important;
                }
            }
        }
    }
    .pd-l {
        width: 100%;
        float: left;
        margin-bottom: 10px;
        padding-left: 7px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    .gridContainer {
        width: 100%;
        float: left;
        padding: 0 15px;
        margin: 10px 0;
    }
    .wms-toolbar.import-btn {
        width: 100%;
        float: left;
        padding: 0 15px;
    }
    .reset-btn {
        margin-right: 15px;
    }
    .success-msg {
        width: 100%;
        float: left;
        font-size: 14px;
        padding: 15px 0px;
    }
    .reset-btn {
        margin-top: -21px;
        margin-right: -5px;
    }

    .wms-form-grid-holder{
        margin: 0;
        padding: 0;
    }
    div#GridManageOrderImport {
        // height: 222px !important;
        width: 100% !important;
        margin-left: 0px;

        .k-grid-header {
            // border: 1px solid #b7d2e5 !important;
            border-left: 1px solid #b7d2e5;
            border-right: 1px solid #b7d2e5;
            border-top: none !important;
            .k-header {
                font-size: 14px;
                font-weight: 700;
                padding: 6px 12px;
                .k-link {
                    .k-icon {
                        vertical-align: middle !important;
                    }
                }
                .k-i-sort-desc-sm,
                .k-i-sort-asc-sm {
                    &::before {
                        color: $input-color;
                    }
                }
                .k-header-column-menu {
                    bottom: 0;
                }
            }
            .k-grid-header-wrap {
                table {
                    width: 100% !important;
                }
            }
        }

    }
    .radio-btnlist {
        .width35 {
            width: 35%;
            float: left;
            display: flex;
            flex-direction: column;
            @include desktop_1280 {
                width: 40%;
            }
            @include desktop_1199 {
                width: 40%;
            }
            .wms-radiobtn-component {
                .wms-radiobtn {
                    margin: 0 20px 0px 0;
                }
            }
            .radiobtn {
                padding: 0px;
                float: none;
                margin-bottom: 0px;
                margin: 0px 24px 0px 10px;
                label {
                    display: inline-block;
                    padding-bottom: 5px;
                }
            }
        }
    }
}