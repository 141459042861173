@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-multi-dorpdown-list {
    margin-bottom: 8px;
    padding: 0 6px;
    label {
        @include label;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .k-multiselect {
        .k-multiselect-wrap {
            background-color: transparent;
            border-radius: 4px;
            border: 1px solid $border-color;
            width: 100%;
            height: 32px;
            color: $input-color;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            .k-input {
                height: 32px;
            }
            .k-button {
                border: unset;
                margin: 0;
                height: 30px;
                padding-left: 10px;
                background: transparent;
                &.k-state-hover {
                    background: transparent;
                }
                .k-select {
                    height: 30px;
                }
            }
            .k-clear-value {
                height: 22px;
            }
        }
    }
    .div_tooltip {
        position: absolute;
        z-index: 12;
        top: 50px;
        width: 200px;
        background: black !important;
        font-size: 14px;
        color: white !important;
        border: 1px solid black !important;
        padding: 4px 8px;
    }

    .div_tooltip:before {
        content: "\A";
        border-style: solid;
        border-width: 11px 11px 0px 20px;
        border-color: transparent black transparent transparent;
        position: absolute;
        -webkit-transform: rotate(-135deg);
        left: 75px;
        top: 1px;
    }
}