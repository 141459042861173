@import "variable";
@import "mixin";

/******  Combobox of Dropdown & Input  *******/
.wms-input-group-wrapper {
    width: 100%;
    padding: 0px 6px;
    float: left;
    display: flex;
    align-items: flex-end;
    margin-bottom: 14px;

    .wms-dropdown-component {
        width: 125px;
        margin-bottom: 0px;

        label {
            padding-left: 0px;
        }
    }

    .wms-input-component {
        flex: 1;
        padding: 0px 0px;
        margin-bottom: 0px;
        position: relative;

        label {
            left: 0px;
        }
    }
}

/******  Page Title Styles  *******/
.k-window-titlebar {
    .k-window-title {
        color: $window-title;
        font-size: 16px;
        line-height: 20px;
    }
}


/******  TabListing CSS   ***********/
.tab-listing {
    ul {
        border: 1px solid var(--default-border);
        border-radius: 30px;
        overflow: hidden;
        display: inline-flex;
        margin-top: 4px;

        li {
            border-right: 1px solid var(--default-border);
            display: inline-block;

            &:last-child {
                border-right: none;
            }

            label {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: var(--primary-black);
                background-color: transparent;
                font-size: 14px;
                padding: 16px 20px;
                line-height: 0;
                font-weight: 600;
                cursor: pointer;
                display: block;
                transition: 0.2s ease-in-out;
                -webkit-transition: 0.2s ease-in-out;
                -moz-transition: 0.2s ease-in-out;

                &:hover,
                &:active,
                &:focus {
                    background: $blue_color;
                    color: white;
                }

                @include desktop_1024 {
                    font-size: 12px;
                    padding: 15px 15px;
                }
            }

            &.active-tab {
                background: $blue_color;

                label {
                    color: white;
                }
            }
        }
    }
}

/******  k-window important for fit window   ***********/
.k-window {
    &:not(.Modal1CustomWindow) {
        overflow: hidden;
        position: fixed;
        box-shadow: inherit !important;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
    }

    &.Modal1CustomWindow {
        .k-window-content {
            height: 100%;
        }
    }
}


/******  Page Title CSS  *******/
.k-window-titlebar {
    padding: 5px 15px;

    .k-window-title {
        color: $window-title;
        font-size: 16px;
        line-height: 20px;
    }
}

.k-window-actions {
    margin: 0;
}

/******  AlertMessages CSS  *******/

.wms-notification {
    width: 400px;
    text-align: center;
    padding: 15px;

    .wms-notification-header {
        font-size: 16px;
        font-weight: bold;
    }

    .wms-notification-content {
        margin-top: 5px;
        line-height: 22px;
    }

    .wms-notification-toolbar {
        .wms-toolbar {
            display: flex;

            .wms-toolbar-button {
                border-radius: 0px;
                line-height: 40px;
                margin: 0px;
                width: 100%;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                cursor: pointer;

                .wms-toolbar-button-text {
                    padding: 0 4px;
                }
            }
        }
    }
}

.wms-notification.success {
    color: #3c763d;
    background-color: #dff0d8;
}

.wms-notification.info {
    color: #31708f;
    background-color: #d9edf7;
}

.wms-notification.warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
}

.wms-notification.danger {
    color: #a94442;
    background-color: #f2dede;
}

.wms-notification.error-recover {
    color: #a66a02 !important;
    background-color: #feeccc !important;
}

/******  ResetButton CSS   ***********/
.reset-btn {
    float: right;
    margin-top: 5px;

    .k-menu {
        li {
            overflow: hidden;

            .k-link {
                padding: 0px;
                line-height: 25px;
                display: unset;

                .k-icon {
                    width: 23px;
                    height: 23px;
                    margin: 0px;
                    display: block;
                    line-height: 25px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        li.k-state-border-down {
            overflow: visible;
        }
    }

    .k-animation-container {
        display: block !important;
        left: inherit !important;
        right: 0;

        .k-item {
            &.k-state-default {
                background: $resettext-bg;
            }

            &.k-state-hover {
                background-color: $greenbtn;
            }

            &.wms-disabled-look {
                .k-link {
                    color: #656565 !important;

                    &:hover {
                        color: #656565 !important;
                    }
                }
            }

            .k-link {
                padding: 0 10px;
                font-size: 13px;
                line-height: 30px;
            }
        }
    }
}


/******  ProgressBar CSS  *******/
.progress-bar.active {
    animation-name: progress-bar-stripes;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: initial;
    animation-iteration-count: infinite;
    animation-direction: initial;
    animation-fill-mode: initial;
    animation-play-state: initial;
}

.progress-bar-striped,
.progress-striped .progress-bar {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
}

.progress-bar {
    float: left;
    width: 300px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: $primary-white;
    text-align: center;
    background-color: $primary-themecolor;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
}

.grid-progress .progress-bar {
    bottom: 0px;
}

@keyframes progress-bar-stripes {
    0% {
        background-position: 40px 0;
    }

    100% {
        background-position: 0 0;
    }
}

/******  Animation icon move to Progress Bar  *******/
.gear-icn {
    position: absolute;
    z-index: 20010;
    font-size: 25px;

    i {
        color: #81c926;
    }
}


@keyframes animationFrames {
    0% {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    20% {
        opacity: 1;
        transform: translate(30px, 30px);
    }

    100% {
        opacity: 0;
        transform: translate(-1000px, 0px);
    }
}

.gear-icn.icon-move {
    animation: animationFrames ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
}
// kloading css
.k-loading-mask{
    background-color: rgba(255, 255, 255, 0.8) !important;
}
.k-grid-content{
    .k-loading-mask{
        background-color: rgba(255, 255, 255, 0.3) !important;
    }
}
// kloading css
/******  Chechbox & RadioBtn CSS   ***********/
@keyframes click-wave {
    0% {
        height: 30px;
        width: 30px;
        opacity: 0.35;
        position: relative;
        @include desktop_1280 {
            width: 27px;
            height: 27px;
        }
    }
    100% {
        height: 60px;
        width: 60px;
        margin-left: -15px;
        margin-top: -15px;
        opacity: 0;
    }
}
@keyframes new-click-wave {
    0% {
        height: 20px;
        width: 20px;
        opacity: 0.35;
        position: relative;
    }
    100% {
        height: 40px;
        width: 40px;
        margin-left: -12px;
        margin-top: -10px;
        opacity: 0;
    }
}

/******  common Search Filer Section Hide/Show  ***********/
@keyframes hideCommonFilterSection {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: -425px;
    }
    100% {
      margin-left: -390px;
    }
}

@keyframes showCommonFilterSection {
    0% {
        margin-left: -390px;
    }
    50% {
        margin-left: -425px;
    }
    100% {
        margin-left: 0;
    }
}

/******  common Side Navbar Hide/Show  ***********/
@keyframes showTextIconMenu {
	0% {
        margin-left: -175px;
        display: block;
        width: 175px;
	}
	100% {
        margin-left: 40px;
        display: block;
        width: 175px;
    }
}

@keyframes hideTextIconMenu {
	0% {
        margin-left: 0;
    }
	99% {
        margin-left: -175px;
    }
    100% {
        margin-left: -175px;
        width: 0px;
    }
}

@keyframes showOnlyIconMenu {
	0% {
        margin-left: -40px;
        display: block;
        width: 40px;
	}
	100% {
        margin-left: 0;
        width: 40px;
        display: block;
    }
}

@keyframes hideOnlyIconMenu {
	0% {
        margin-left: 0;
        width: 40px;
    }
	99% {
        margin-left: -40px;
        width: 40px;
    }
    100% {
        margin-left: -40px;
        width: 0px;
    }
}

/******  common Side Navbar Hide/Show  ***********/
@keyframes showViewListMenu {
	0% {
        margin-left: -175px;
        display: block;
        width: 175px;
	}
	100% {
        margin-left: 0px;
        display: block;
        width: 175px;
    }
}

@keyframes hideViewListMenu {
	0% {
        margin-left: 0;
        width: 175px;
    }
	99% {
        margin-left: -175px;
    }
    100% {
        margin-left: -175px;
        width: 175px;
    }
}

// @keyframes showIconNavSection {
// 	0% {
// 		margin-left: -40px;
// 	}
// 	50% {
// 		margin-left: -60px;
// 	}
// 	100% {
// 		margin-left: 0;
// 	}
// }

/****** new layout structure ***********/
.new-layout-grid-filter-wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    .new-layout-filter-wrapper{
        width: 425px;
        background: #F2F3F4 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 30px;
        .wms-kendo-dropdown-list-component{
            margin-bottom: 14px;
        }
        .wms-multiselect-input-wrapper{
            .wms-multiselect-input{
                .wms-kendo-dropdown-update-list-component {
                    margin-bottom: 0px;
                    padding-bottom: 4px;
                }
            }
        }
        // .wms-button-component{
        //     .wms_toolbar_button{
        //         .wms_sprite{
        //             margin-right: 6px;
        //         }
        //     }
        // }
        .wms_collapsible_header{
            .wms-title{
                padding: 12px 0px 6px 20px;
            }
        }
        .wms-collapsible-content{
            .wms-radio-wrapper{
                .wms-label{
                    font-size: 10px;
                    line-height: 14px;
                }
                .wms-radio{
                    display: flex;
                    justify-content: space-between;
                    .wms-radiobtn-component{
                        .small-radio-new{
                            margin: 3px 17px 21px 0;
                            .wms-option-radiobtn{
                                margin-right: 14px;
                                top: -1px;
                                right: -2px;
                            }
                        }
                    }
                }
            }
            .wms-input-group-wrapper{
                &:last-child{
                    margin-bottom: 5px;
                }
            }
            .wms-input-group-wrapper {
                padding: 0px 5px;
                margin-bottom: 12px;
                .wms-dropdown-component {
                    width: 140px !important;
                    label{
                        line-height: unset;
                        padding-top: 2px;
                    }
                }
            }
            .wms-datetimepicker-component-proto{
                .border{
                    margin-bottom: 14px;
                }
            }
        }
    }
    .new-layout-grid-wrapper{
        padding: 0 31px 31px 31px;
        flex: 1;
        background: #ffffff 0% 0% no-repeat padding-box;
        .wms-grid-wrapper{
            .wms-grid-toolbar{
                .wms-grid-checkbox-content{
                    .wms-grid-checkbox-input{
                        padding-left: 0px;
                        label{
                            display: block;
                            padding-bottom: 11px;
                            color: #333333;
                            input{
                                margin: 2px 9px 6px 0;
                            }
                        }
                    }
                }
                .wms-toolbar-button {
                    margin-top: -1px;
                    .wms-button-component {
                        .button_holder {
                            // .wms_sprite{
                            //     margin-right: 8px;
                            // }
                            .wms_toolbar_button_text{
                                font-weight: 700;
                                margin-right: -1px;
                            }
                        }
                    }
                    .wms-button-dropdown-component{
                        .wms_dropdown_button{
                            .k-menu .k-item > .k-link{
                                padding: 0;
                                .k-menu-expand-arrow.k-i-arrow-60-down{
                                    margin-left: 0;
                                    margin-top: 3px;
                                }
                            }
                            .wms_menu_item_wrapper{
                                .k-link.k-menu-link{
                                    padding: 6px 12px;
                                }
                            }
                        }
                    }
                    .wms-grid-reset-btn{
                        margin-right: 7px;
                    }
                    .wms-grid-reset-btn, .wms-toolbar-button-text{
                        .k-link{
                            .k-menu-expand-arrow{
                                margin-left: 1px;
                                margin-bottom: 1px;
                            }
                        }
                    }
                    // .wms-toolbar-button-text, .wms-button-dropdown-component{
                    //     margin-right: 0px;
                    // }
                }
            }
            .wms-grid-top{
                margin-top: 18px;
            }
            .wms-grid-holder{
                .k-grid-header{
                    border-left: 1px solid #b7d2e5;
                    border-right: 1px solid #b7d2e5;
                    .k-grid-header-wrap{
                        table{
                            thead{
                                th.k-header{
                                    padding: 4px 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/******  Search Filer Section Hide/Show  ***********/
@keyframes hideFilterSection {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: -400px;
    }
    100% {
      margin-left: -365px;
    }
}

@keyframes showFilterSection {
    0% {
        margin-left: -365px;
    }
    50% {
        margin-left: -400px;
    }
    100% {
        margin-left: 0;
    }
}
/** Toaster css**/
#toast-container{
    .toast{
        color: #222;
        font-size: 15px;
        text-align: center;
        opacity: 1;
        padding: 25px 15px;
        min-width: 300px;
        width: auto;
        &.toast-success{
            background-image: unset !important;
            border: 2px solid #8ad000;
            background-color: #ecf3dd;
        }
        &:hover{
            box-shadow : 0 0 12px #999 !important;
        }
        .toast-message{
            .fa{
                padding-right: 10px;
            }
        }
    }
}
/**For warning div css*/
.wms-warning-wrapper{
    padding: 0px 5px 15px 5px;
    &.in-grid{
        padding: 0px 5px 15px 20px;
    }
    &.in-delete{
        padding: 0px 5px 15px 0px;
        display: flex;
        align-items: center;
    }
    border-bottom: 1px solid #f5f5f5;
    .wms-sprite {
        color: #fcde5f;
        margin-right: 10px;
    }
    span{
        font-size: 12px;
    }
}

/*** For grid last column resize issue in window and Firefox */
.k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
    margin-right: -1px !important;
}

.wms-badge {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
    background-color: #D91C03;
    width: 48px;
    height: 19px;
    border-radius: 4px;
    font: normal bold 12px Open Sans;
    color: #FFFFFF;
    &.free-trial-badge{
        width: 80px;
    }
}