@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";

#windowReceiptAdvice {
    .model-content-wrapper{
        .wms-collapsible-div{
            .wms-collapsible-content{
                .receipt_num_info{
                    padding: 0 0px 10px 6px;
                    border-bottom: 1px solid #F5F5F5;
                    .wms-icon{
                        color: #FACA00;
                        font-size: 12px;
                        padding: 0 10px 0 0;
                    }
                }
                .wrapper-section{
                    padding-top: 5px;
                    .wms-dropdown-component {
                        width: 100%;
                    }
                }    
                .title_label {
                    padding: 0px 7px 7px 8px;    
                    .label_wrapp {
    
                        .title {
                            display: inline-block;
                            width: auto;
                            margin: 0px;
                            float: none;
                            padding-left: 10px;
                            color: #5a7e9d;
                            letter-spacing: 1px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}