@import "../../../scss/variable";
@import "../../../scss/mixin";

#barcode-holder-react {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .select-field {
        width: 100%;
        float: left;
        .wms-dropdown-component {
            max-width: 275px;
            @include desktop_1600 {
                max-width: 225px;
            }
        }
    }
    .barcode-main-content {
        width: 100%;
        flex: 1;
        position: relative;
        margin-left: 5px;
        margin-top: 4px;
        display: flex;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: $seprator-color;
        }
        .barcode-grid {
            overflow-y: auto;
            width: 46.5%;
            height: 100%;
            padding: 15px 15px 15px 0;
            display: flex;
            flex-direction: column;
            border-right: 1px solid $seprator-color;
            .search-applied{
                float: right;
                color: #5a7e9d;
                margin: 8px 0 10px 2px;
                label{
                    color: #5a7e9d;
                    font-size: 11px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 600;

                }
            }
            .wms-float-right {
                float: right;
            }
            .barcode-form-boxes {
                display: flex;
                flex-direction: column;
                .title {
                    width: 100%;
                    float: left;
                    margin: 8px 0 10px 2px;
                    label {
                        font-size: 11px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 600;
                    }
                }
                .wms_area_border {
                    top: 250px;
                    padding-bottom: 0;
                    bottom: 0;
                    padding: 0px 15px 0px 0px;
                    #gridContainer {
                        height: 100%;
                    }
                }
                .detailTemplate {
                    .wms-form-grid-component {
                        .wms_user_access_checkbox {
                            display: none;
                        }
                    }
                }
            }
            .wms-barcode-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                .wms-grid-toolbar {
                    flex-wrap: wrap-reverse;

                    .wms-grid-checkbox-content {

                        @include desktop_1440 {
                            width: 100%;
                        }
                    }

                    .wms-toolbar-button {
                        .wms-button-component:first-child{
                            .button_holder {
                                button{
                                    .wms_sprite{
                                        width: 12px;
                                    }
                                    .wms_toolbar_button_text{
                                        width: 100px;
                                    }
                                }
                            }
                        }
                        .wms-button-component {
                            @include desktop_1280 {
                                margin: 0 5px 5px 0;
                            }
                        }
                    }
                }
                .wms-grid-wrapper {
                    .wms-grid-top {
                        margin-top: 0px;
                    }
                }
            }
        }
        .barcode-form {
            position: relative;
            flex: 1;
            display: flex;
            width: 53.5%;
            flex-direction: column;
            padding: 15px 0px 15px 15px;
            .conatiner-title{
                width: 100%;
                float: left;
                text-align: left;
                margin-bottom: 8px;
                label{
                    font-size: 11px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 600;
                    color: #5a7e9d;
                    span{
                        color: #222222;
                    }
                }
            }
            #barcodeCustomerTextSection {
                .wms-area-content{
                    .wms-area-content-darker{
                        display: flex;
                        .wms-input-component, .wms-combobox-component {
                            width:100% ;
                        }
                    }
                }
            }
            #barcodeElementDefination{
                .wms-area-content{
                    .wms-area-content-darker{
                        padding: 12px 12px 0px;
                        .text{
                            margin-bottom: 8px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
            .wms-collapsible-div{
                flex: 1;
                overflow: auto;
                margin-top: 15px;
            }
            .barcode-element-form {
                display: flex;
                width: 100%;
                .wms-kendo-dropdown-list-component{
                    padding: 3px 6px 0;
                }
                .wms-kendo-dropdown-list-component {
                    width: 50% !important;
                }
                .wms-input-component {
                    width: 25%;
                }
            }
            .note {
                color: red;
                float: right;
                line-height: 2;
            }
            .total-character {
                .alert-message {
                    float: left;
                    color: #ff0024;
                    line-height: 30px;
                    font-size: 13px;
                }
                .wms-input-component {
                    float: right;
                }
            }
        }
    }
}