@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";
.advancedContactLookupModal {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    .filter-line {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-end;
        .text {
            line-height: 40px;
            padding-left: 12px;
             @include desktop_1280 {
                padding-left: 0px;
             }
            span {
                color:$label-color
            }
        }
    }
    .wms-input-component{
        width: 33.33%;
        padding:0;
        @include desktop_1280 {
            padding-left: 0;
        }
    }
    .wms-button-component {
        margin-top: 15px;
        margin-left: 10px;
    }
    .wms-search-wrapper {
        display: flex;
        align-items: center;
    }
    .wms-grid-wrapper {
        .wms-grid-holder {
            min-height: 90px;
            .k-grid-content {
                height: 100% !important;
            }
        }
    }
}