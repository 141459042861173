@import "../../../../scss/variable";
@import "../../../../scss/mixin";


.wms-form-grid-holder {
    padding-bottom: 20px;
    margin-top: 20px;
    &.hidden-header{
        .k-grid-header{
            display: none;
        }
    }
    &.assembly-grid-holder{
        .wms-form-grid-component {
            .form-grid{
                display: inline-block;
            }
        }
    }
    .wms-grid-details {
        display: inline-block;
        width: 100%;
        padding: 2px 13px 6px 11px;
        margin: 12px 0 0 0;
        border: 1px solid $seprator-color;
        background: #e2effc;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .wms_grid_results {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: normal;
            padding-right: 15px;
            letter-spacing: 0.1px;
            color: $input-color;
            span {
                color: $title_color;
                padding-right: 7px;
                font-weight: bold;
                color: $input-color;
            }
        }
        .grid-right-content{
            float: right;
            position: relative;
            display: flex;
            cursor: pointer;
        }
    }
    .wms-grid-summary{
        background: #e2effc;
        display: flex;
        border: 1px solid $seprator-color;
        border-top: none;
        .wms-details-data-section{
            width: 14%;
            border-right: 1px solid $seprator-color;
            text-align: center;
            padding: 0px 10px;
            .details-count{
                font-size: 20px;
                padding: 0;
                margin: 5px 0 0 0;
                font-weight: bold;
                color: black;
            }
            .details-name{
                margin: 0px 0 10px 0;
                text-transform: uppercase;
                font-size: 12px;
            }
        }
    }
    .form-grid{
        width: 100%;
        margin: 0px 7px 0px 0px;
        display: block;
        .k-grid-header {
            .k-grid-header-wrap {
                &.k-auto-scrollable {
                    table {
                        thead {
                            tr {
                                // background: #e2effc !important;
                                th {
                                    &.k-header{
                                        font-weight: 700;
                                        color: $input-color;
                                        font-size: 14px;
                                        padding: 6px 10px;
                                        text-transform: uppercase;
                                        border-right: 1px solid rgba(0, 0, 0, 0.08);
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
        .k-grid-content{
            overflow: auto !important;
            height: 120px;
            max-height: 100%;
            &.k-auto-scrollable{
                table {
                    tbody {
                        tr {
                            td {
                                border: none;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                color: $input-color;
                                &.k-header{
                                    font-weight: 400;
                                    font-size: 13px;
                                    padding: 0 12px;
                                }
                            }
                            td.k-edit-cell {
                                padding: 8px 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .wms_user_access_checkbox {
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        label {
            float: left;
            &.wms_counter_label {
                padding: 9px 30px;
            }
        }
    }
    &.hideHeader {
        .wms-form-grid-component {
            .form-grid {
                &.k-grid {
                    .k-grid-header{
                        display: none;
                    }
                    .k-grid-content {
                        top: 0px !important;
                        height: 159px ;
                    }
                }
            }
        }
    }
    &.showHeader {
        .wms-form-grid-component {
            .form-grid {
                &.k-grid {
                    .k-grid-content {
                        height: 180px;
                    }
                    .k-grid-header{
                        th.k-header {
                            border-right: 1px solid #b7d2e5;
                        }
                    }
                }
            }
            .title{
                margin-left: 9px;
                width: calc(100% - 20px);
                color: #5a7e9d;
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 600;
                display: block;
                span{
                    color: #7d7d7d
                }
            }
        }
    }
    .wms-form-grid-reset-btn{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        margin-top: -52px;
        .k-widget {
            .GridMenuTopLink {
                overflow: hidden;
                background: #0079c4;
                color: #fff;
                .k-link {
                    padding: 0px;
                    line-height: 25px;
                    display: unset;
                    .k-icon {
                        width: 23px;
                        height: 23px;
                        margin: 0px;
                        display: block;
                        line-height: 25px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
            li.k-state-border-down {
                overflow: visible;
            }
        }
        .k-animation-container {
            display: block !important;
            left: inherit !important;
            right: 0;
            .k-item {
                &.k-state-default {
                    background: $resettext-bg;
                }
                &.k-state-hover {
                    background-color: $greenbtn;
                }
                &.wms-disabled-look {
                    .k-link {
                        color: #656565 !important;
                        &:hover {
                            color: #656565 !important;
                        }
                    }
                }
                .k-link {
                    padding: 0 10px;
                    font-size: 13px;
                    line-height: 30px;
                }
            }
        }
    }
}