//------ WMS Variables -------
//$icon-path: "../../assets/images/";

/**==========  Common Variables  ==========**/
$primary-font:'Open Sans', sans-serif;
$primary-fontsize:14px;
$primary-white:#ffffff;
$primary-black:#000000;
$green-menu:#83de52;
$blue-menu:#28b7ed;
$orange-menu:#f6bb42;
$requireinput-border:#d79093;
$primary-button-bg:#0079c4;
$primary-button-pressed-bg:#0079c4;

//---- Buttons Variables -----
$greenbtn:#008895;
$greenbtn-hover:#005f68;
$yellowbtn:#faca00;
$yellowbtn-hover:#fad745;
$redbtn:#D91C03;
$redbtn-hover:#b71a04;
$orangebtn:#F48831;
$orangebtn-hover:#f57610;
$blackbtn:#666666;
$blackbtn-hover:#777777;

/**==========  ThemeColor Variables  ==========**/
$spinner-color:rgba(255, 255, 255, 0.6);
$primary-themecolor:#008895;
$body-bg:#ecf5fd;
$window-title:#2c405a;
$window-closeicon:#a8c6df;
$window-titlebg:#ffffff;
$transparent-bg:rgba(255, 255, 255, 0);
$seprator-color:#b7d2e5;
$overAllocated:#f1e0dc;
$resetbtn-bg:#008895;
$resettext-bg:#f9f9f9;
$resetbtn-bg-hover:#006aac;

//---- SideNavigation Variables -----
$sidepanel-bg:#2c405a;
$submenu-hoverbg:rgba(255, 255, 255, 0.1);
$submenufont:#ffffff;
$submenufont:#ffffff;

//---- HeaderTab Variables -----
$active-windowbg:rgba(255, 255, 255, 1);
$deactive-windowbg:#1a6db1;
$active-windowborder:#0079c4;
$header-tabtitle:#666666;
$header-tabtext:#4a4a4a;
$header-tablabel:#222222;

//---- FontColor Variables -----
$primary-textcolor:#3f536e;
$form-title:#5a7e9d;
$filter-note-title:#e67b24;

//---- Form Variables -----
$form-boxbg:#fafbfc;
$label-color:#7d7d7d;
$input-border:#d8e5ee;
$input-bg:#ffffff;
$datepicker-icon:#2c405a;
$datepicker-border:#afbcc7;
$checked-bg:rgba(255,255,255,1);
$checked-wavebg:#0079c4;

//---- TabListing Variables -----
$accordian-title:#0079c4;
$tablabel-border:#c5d9e8;
$tablabel-bg:rgba(0, 147, 238, 0.05);
$tablabel-hoverbg:rgba(0, 147, 238, 0.25);
$activetab-border:rgba(0, 0, 0, 0.08);
$collapsecontent-bg:#ffffff;

//---- Table Variables -----
$tabel-headbg:#cee1f2;
$tableheader-rowbg:#cee1f2;
$table-rowbg:#ffffff;
$table-alterrowbg:#fafbfc;
$selected-rowbg:#8eb8dd;
$gridheader-textcolor:#527999;
$tblfont-color:#3f536e;
$tblcolumn-rightborder:#b7d2e5;
$tblbottom-border:rgba(255,255,255,0.15);

// Search New Design

//---- Collapse Variables -----

$blue_color: #008895;
$collapse-header-color: #CFD4D8;
$inputs_bg_color: #F5F5F5;
$title_color: #656565;

// variable override for new kendo themes
$menu-expanded-text:white;
$menu-hover-text:white;
$grid-selected-bg: #C1E9ED;
$list-item-selected-text:white;
$list-item-selected-bg:#0079c4;

// date picker

$calendar-cell-hovered-text:white;
$calendar-cell-hovered-bg:#0079c4;
$calendar-cell-hovered-border:#0079c4;
$node-hovered-bg:#0079c4;
$node-selected-bg:#0079c4;


// new design color
$input-color: #222222;
$border-color:#bbbbbb;

// Text selection color variable
$accent:#f07c69;
$accent-contrast:white;


// wms-model-new-ui-wrapper

$modal-header-color: #E2EFFC;

// wms-model-new-ui-wrapper

// date ticker variable

$calendar-header-text: $input-color;
$calendar-header-bg: $primary-white;
$calendar-header-gradient:none;
$calendar-header-shadow: none;
$calendar-header-button-hovered-text: $input-color;
$calendar-header-button-hovered-bg: $primary-white;
$calendar-header-button-hovered-border: $primary-white;
$calendar-header-button-hovered-gradient: $primary-white;

// deader variable
$calendar-cell-hovered-text: $primary-white;
$calendar-cell-hovered-bg: $greenbtn;
$calendar-cell-hovered-border: $greenbtn;
$calendar-today-nav-hovered-text: $input-color;
$calendar-cell-selected-text: $primary-white;
$calendar-cell-selected-bg: $greenbtn;
$calendar-cell-selected-border: $greenbtn;
$calendar-cell-selected-focus-shadow: $greenbtn;


// date time variable
// $list-item-selected-text: $primary-white;
// $list-item-hovered-bg: $greenbtn;
// $list-item-selected-bg: $greenbtn;
$supernav-bg-color:#0048a6;