#ViewRateShoppingDetailsModal {
    .model-content-wrapper {
        tr {
            display: flex;
        }
        td {
            padding-left: 10px;
        }
        th {
            width: 100px;
            text-align: left;
        }
        .paddingBottom {
            padding-bottom: 10px;
        }
    }
}