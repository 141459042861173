@import "../../../../../../scss/variable";
@import "../../../../../../scss/mixin";
.reprintEndOfDayReport{
    .model-content-wrapper {
        padding: 30px 18px !important;
    }
    .inline-element{
        display:flex;
        width: 70%;
        align-items: center;
    }

    .wms-grid-wrapper {
        margin: 0 6px !important;
        height: auto !important;
        margin-bottom: 0px !important;
        .wms-grid-holder{
            height: auto !important;
            min-height: unset !important;
        }

        .no-recored-text-enable {
            .k-virtual-scrollable-wrap {
                .k-grid-norecords   {
                    font-size: 16px;
                    line-height: 22px;
                    margin: 36px;
                }
            }
        }
    }

    .refresh-btn-wrapper {
        margin: 0 6px;
        margin-top: 7px;
        .wms_toolbar_button {
            padding: 0 15px 0 14px;
            .wms_toolbar_button_text {
                display: none;
            }
        }
    }

}