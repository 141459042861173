@import "../../../../../../scss/variable";
@import "../../../../../../scss/mixin";
.transferORder{
    .model-content-wrapper{
        .wms-collapsible-content{
            padding: 0;
            .title{
                padding: 15px;
                border-bottom: 1px solid #f5f5f5;
                .wms-sprite {
                    color: #fcde5f;
                    margin-right: 10px;
                }
                span{
                    font-size: 12px;
                    color: $input-color;
                }
            }
            .wms-dropdown-component {
                width: 60%;
                padding: 15px 0;
            }
        }
    }
}