@import "../../../scss/variable";
@import "../../../scss/mixin";
.api-simulator-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    p{
        font-weight: 600;
        font-size: 14px;
        color: $input-color;
    }
    .simulator-section{
        display: flex;
        flex-direction: row;
        .wms-collapsible-div{
            height: 100%;
            display: flex;
            flex-direction: column;
            .wms-collapsible-content{
                flex: 1;
            }
        }
        .wms-input-component {
            width: 100%;
        }
        #apiSimulatorResponseSection_main{
            margin: 0px 0px 20px 20px;
        }
        .response-section{
            .wms-area-content{
                height: 100%;
                .wms-area-content-darker{
                    height: 100%;
                    .response-info-wrapper{
                        label{
                            font-weight: 400;
                            font-size: 14px;
                            color: #7d7d7d;
                            margin-left: 10px;
                            span{
                                color: #83de52;
                            }
                        }
                    }
                    .response-object{
                        .wms-text-area-component {
                            margin: 10px;
                            label {
                                display: none
                            }
                            textarea{
                                max-height: 285px ;
                            }
                        }
                    }
                }
            }
        }
        .wms-request-params{
            .wms-text-area-component{
                margin: 0px 6px 0 10px;
            }
        }
        .wms-button-component {
            padding: 10px;
            width: 100%;
            .button_holder {
                width: 100%;
                button{
                    width: 100%;
                }
            }
        }
    }
    .wms-click-btn-block{
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        .display-inline {
            display: flex;
            .wms-input-component {
                .inputlabel{
                    display: none;
                }
            }
        }
    }

}