@import "./../../scss/variable";
@import "./../../scss/mixin";

.adjust-inventory-wrapper {
    .adjust-inventory-search {
        width: 405px;
        min-width: 400px;
        max-width: 405px;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #b7d2e5;
        background: rgba(0, 0, 0, 0.03);
        position: relative;
        transition: all 1s linear;
        .btn-section {
            padding-bottom: 30px;
            .wms-button-component {
                float: right;
            }
        }
    }
}
.adjust-customer-charges-wrapper {
    .adjust-customer-charges-search {
        .wms-dropdown-component {
            width: 100%;
        }
    }
    .adjust-customer-charges-search {
        .adjust-inventory-refresh-btn {
            .wms_toolbar_button {
                padding: 0px 10px 0px 15px;
            }
        }
    }
    .adjust-customer-charges-grid {
        .adjust-inventory-refresh-btn {
            .primary_button_color {
                padding: 0px 16px;
                .wms_toolbar_button_text {
                    display: none;
                }
            }
        }
    }
}
#SelectReceiveItemModel {
    .display-inline {
        display: flex;
        padding: 0 15px;
    }
    .wms-collapsible-content {
        .display-inline {
            padding: 0px;
        }
        .adjust-inventory-selected-receive-item-grid {
            .remove-selected-available-item {
                color: rgba(255, 0, 0, 1);
            }
        }
    }
    .left-content-wrapper,
    .right-content-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 6px;
        margin-bottom: 10px;
        .content {
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
            label {
                font-size: 14px;
                line-height: 19px;
                color: #222222;
                font-weight: bold;
            }
            span {
                font-size: 14px;
                line-height: 19px;
                color: #222222;
            }
        }
        .add-receive-item-button {
            margin-top: 20px;
        }
    }
    .adjust-inventory-available-receive-item-grid {
        .form-grid {
            height: 300px;
            .k-grid-content {
                height: 100%;
            }
        }
    }
}
#ReverseAdjustmentModal {
    .wms-collapsible-content {
        padding: 0;
        .display-inline {
            display: flex;
            padding: 0 15px;
        }
        .left-content-wrapper,
        .right-content-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0 6px;
            .content {
                display: flex;
                justify-content: space-between;
                label {
                    display: flex;
                    flex: 1;
                    font-size: 14px;
                    line-height: 19px;
                    top: 264px;
                    left: 610px;
                    height: 19px;
                    text-align: left;
                    letter-spacing: 0px;
                    color: #222222;
                    opacity: 1;
                }
                span {
                    display: flex;
                    top: 264px;
                    left: 752px;
                    height: 19px;
                    width: 190px;
                    text-align: left;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0px;
                    color: #222222;
                    opacity: 1;
                }
            }
        }
        .cancellation-reason {
            width: 349px;
        }
        .cancellation-fee {
            width: 100px;
        }
        .adjustment-create-invoice {
            margin-left: 30px;
            margin-top: 17px;
        }
    }
}

#CreateNewInventoryAdjustmentModel {
    .charge-type {
        width: 30%;
    }
    .charge-amount {
        width: 20%;
    }
    .charge-description {
        width: 50%;
    }

    .adjustment-add-button-area {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .adjust-inventory-item-grid-wrapper {
            overflow-x: scroll;
            max-width: 104%;
            padding: 0px;
        }
    }

    .adjustment-information-wrapper {
        margin-top: 15px;

    .charge-grid-wrapper {
      flex-direction: column;
        .k-grid-header-wrap {
            padding-right: 20px;
        }
      .k-grid {
            min-height: 400px;
        }
      .wms-form-grid-holder {
          padding-bottom: 0;
          .charge-delete {
              border: none;
              padding: 0;
              margin: 0;
              color: #EF0000;
          }
      }
      .charge-totalWrapper {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 20px;
          .total-text {
              height: 32px;
              font-weight: 700;
              color: #222222;
              padding: 6px 10px;
              background-color: #e2effc;
              background-color: #e2effc;
              border: 1px solid #e2effc;
              border-right: none;
          }
          .wms-input-component {
              width: 100%;
              padding: 0;
              label {
                  display: none;
              }
              input {
                  border-left: none;
                  border: 2px solid #e2effc;
              }
          }
      }
    }
    .include-charge-wrapper {
        padding-right: 20px;
    }
  }
  .adjustment-item-wrapper {
    .error-message {
        padding-left: 20px;
        padding-bottom: 10px;
        color: red;
    }
    .sku-dropdown-wrapper{
      margin-top: 4px;
    }
    .location-dropdown-list{
      margin-top: 4px;
    }
    .split-line-by-movable-unit{
      margin-top: 20px;
      margin-left: 35px;
    }
  }
  .display-inline {
    display: flex;
    padding: 0 15px;
  }
  .adjust-inventory-item-grid{
    .form-grid{
      min-height: 300px;
      .adjust-inventory-item-grid-reduce-row {
        td {
          color: rgba(255, 0, 0, 1);
          font-weight: bold;
        }
      }
      .remove-adjustment-item {
        color: rgba(255, 0, 0, 1);
      }
    }
  }
}
.k-grid-header{
  padding: 0px !important;
}
.width-100 {
    width: 100%;
}
.width-70 {
    width: 70%;
}
.width-60 {
    width: 60%;
}
.width-40 {
    width: 40%;
}
.width-20 {
    width: 20%;
}
.width-30 {
    width: 30%;
}
.width-25 {
    width: 25%;
}
.hide {
    display: none !important;
}
