@import "../../../scss/variable";
@import "../../../scss/mixin";
.border-modal{
    border: 1px solid #2c405a;
    border-top: none;
}
.react-progress-model-wrapper {
    .message-content{
        clear: both;
        .title-top{
            font-size: 14px;
            color: #5a7e9d;
            font-weight: 600;
            padding-bottom: 15px;
            width: calc(100% - 25%);
            float: left;
            @include desktop_1280 {
                width: calc(100% - 13%);
            }
        }
        .btn-clear{
            background: #faca00;
            border: none;
            cursor: pointer !important;
            width: auto;
            padding: 0 20px 0 10px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            transition: 0.2s ease-in-out;
            -wekbit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            float: right;
            .wms-sprite {
                width: 16px;
                font-size: 15px;
                color: #ffffff;
                display: inline-block;
                vertical-align: middle;
                margin: 0 5px 0 4px;
                line-height: 34px;
            }
            .wms-toolbar-button-text {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                color: #ffffff;
                line-height: 34px;
            }
        }
    }
    .model-content-wrapper {
        overflow-y: auto;
        flex: 1;
        padding: 25px 20px 20px 30px;
        .wms-notification {
            text-align: left;
            clear: both;
            position: relative;
            width: 100%;
            padding: 10px;
            display: inline-block;
            // border-bottom: 1px solid #ececec;
            background-color: #F5F5F5;
            margin-bottom: 12px;
            .progress-title-box{
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                line-height: 20px;
                font-family: Inter;
                color: $input-color;
                .title {
                    display: inline-block;
                    .progress-title{
                        line-height: 24px;
                        font-weight: bold;
                        text-align: left;
                        text-transform: none;
                    }
                    .success-msg {
                        font-size: 12px;
                        color: $title_color;
                        font-weight: 400;
                        text-align: left;
                        text-transform: none;
                    }
                    .wms-notification-icon {
                        i {
                        font-size: 12px;
                        color: #faca00;
                        }
                    }
                    &.progress-disabled {
                        cursor: pointer;
                        .progress-title{
                        opacity: 0.3;
                        }
                        .success-msg {
                        opacity: 0.3;
                        }
                    }
                }
                .wms-toolbar {
                    display: flex;
                    .wms-toolbar-button {
                        // margin-right: 10px;
                        padding: 0 !important;
                        text-align: center;

                        .wms-sprite {
                        font-size: 16px;
                        width: unset;
                        margin: unset;
                        line-height: unset;
                        cursor: pointer;
                        &.fa-search {
                            color: $green-menu;
                            cursor: pointer;
                        }
                        &.fa-arrow-circle-o-down {
                            color: $primary-button-bg;
                            cursor: pointer;
                        }
                        &.fa-times-circle-o {
                            color: $redbtn;
                            cursor: pointer;
                        }
                        &.fa-trash-o {
                            color: $redbtn;
                            cursor: pointer;
                        }
                        }
                    }
                }
            }
            .box {
                background: transparent;
                border: none;
                padding: 0;
                margin-bottom: 20px;
                .wms-progess-bar-wrapper-main {
                    // display: flex;
                    // align-items: center;
                    // justify-content: space-between;
                    .progress-disabled {
                        .k-state-selected {
                            background-color: #cccccc;
                            border-color: #cccccc;
                            background-image: none;
                        }
                    }
                    .progess-bar {
                        height: 5px;
                        font-size: 12px;
                        line-height: 16px !important;
                        width: 100%;
                        margin-top: 0px;
                        background-color: #f6f6f6;
                        border-color: rgba(0, 0, 0, 0.08);
                        background-clip: padding-box;
                        border-radius: 2px;
                        border-width: 0;
                        display: inline-flex;
                        vertical-align: middle;
                        position: relative;
                        overflow: hidden;
                        .k-complete {
                            background-color: #81c926;
                            border-color: #81c926;
                        }
                        .k-progress-status-wrap{
                            flex-direction: row;
                            justify-content: flex-end;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            position: absolute;
                            .k-progress-status {
                                display: none;
                            }
                        }
                    }

                }
                .wms-disabled-msg-error {
                    margin-top: 15px;
                    p {
                        font-size: 12px;
                        color: #d6d6d6;
                        font-weight: 700;
                        margin: 0;
                        margin-bottom: 6px;
                    }
                }
            }
        }
        .alert-msg {
            font-size: 12px;
            color: $input-color;
            font-weight: 600;
        }
    }
    .alert-msg{
        clear: both;
        padding-top: 10px;
    }
}