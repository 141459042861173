@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.wms-tooltip-component{
    position: relative;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: not-allowed;
    z-index: 12000000;
    &.saw-tooltip-on-icon {
        .div_tooltip {
            left: -80%;
            &:before {
                left: 35%;
            }
        }
    }
    .div_tooltip {
        position: absolute;
        height: auto;
        width: auto;
        min-width: 100px;
        max-width: 300px;
        max-height: 300px;
        transition: opacity 0.4s;
        transition-delay: 200ms;
        visibility: hidden;
        opacity: 0;
        font-size: 14px;
        color: white;
        font-weight: 100;
        top: 40px;
        background: black ;
        border: 1px solid black;
        padding: 4px 8px;
        border-radius: 10px;
        &:before {
            content: "\A";
            border-style: solid;
            border-width: 11px 11px 0px 20px;border-color: transparent black transparent transparent;
            position: absolute;
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
            top: 1px;
            left: 50%;
        }
    }
     &:hover .div_tooltip  {
        visibility: visible;
        opacity: 1;
    }
}