
#windowCancelModel{
    .model-content-wrapper{
        .wms-collapsible-div{
            .wms-collapsible-content{
                .textarea-listing {
                    margin-bottom: 12px;
                }
                .wms-input-component {
                    margin-bottom: 12px;
                    padding: 0;
                }
            }
        }
    }
}