@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-kendo-dropdown-list-component {
    padding: 0 6px 0;
    display: flex;
    flex-direction: column;
    // height: 32px;
    margin-bottom: 8px;
    &.disabled{
        span{
            background-color: #F7F7F7;
            border-color: rgba(0, 0, 0, 0.06);
        }
    }
    label {
        @include label;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 3px;
    }

    .k-dropdown {
        width: 100%;
        height: 32px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        z-index: 10;
        background-color: transparent;
        border: 1px solid $border-color;
        border-radius: 4px;

        .k-dropdown-wrap {
            border: none;
            cursor: pointer;
            color: $label-color;

            &.k-state-hover {
                background: transparent;
            }

            &.k-state-focused {
                //  box-shadow: unset;
                border-color: rgba(0, 0, 0, 0.15);
                // color: #656565;
                // background-color: #ffffff;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
            }

            .k-input {
                line-height: 32px;
                padding: 0 10px;
                height: 32px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
                color: #222222;
            }

            .k-select {
                .k-icon {
                    color: $datepicker-icon;
                }
            }
        }
        &.wms_required_input{
            border: 1px solid #d79093;
        }
    }

    .k_state_disabled {
        outline: none;
        cursor: default;
        opacity: .6;
        filter: grayscale(0.1);
        pointer-events: none;
    }
    .wms-dropdown-clear {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 31px;
        margin-left: -5px;
        background: $primary-white;
        border: 1px solid $border-color;
        text-align: center;
        line-height: 40px;
        border-left: 0px;
        cursor: pointer;
        z-index: 10;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        i {
            color: rgba($color: red, $alpha: 0.2);
            font-size: 16px;
            // margin-top: 12px;
            transition: all 0.3s linear;
        }
        &:hover  {
            i {
                color: rgba($color: red, $alpha: 0.8);
            }
        }
        &:focus {
            border: 1px solid $primary-themecolor;
            border-left: none;
        }
    }
}