.k-window-maximized {
    .model-content-wrapper {
        .charges-grid-holder {
            .wms-form-grid-holder {
                .k-grid {
                    .k-grid-content {
                        height: auto !important;
                    }
                }
            }

        }
    }
}
.confirm-receipt {
    background: #ffffff !important;
    .model-content-wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px 30px !important;
        .wms-datetimepicker-component {
            padding-left: 0px;
            .border {
                width: 228px;
            }
        }
        .charges-grid-holder {
            height: 80%;
            margin: 30px 0px;
            .charge-grid-note {
                border: 1px solid rgba(0, 0, 0, 0.08);
                background: var(--tabel-headbg);
                color: #222222;
                font-size: 12px;
                font-weight: bold;
                padding: 6px 0 5px 10px;
                border-radius: 4px 4px 0 0;
                label {
                    text-transform: uppercase;
                }
            }
            .wms-form-grid-holder {
                margin-top: 0;
                padding-bottom: 0px;
                .k-grid {
                    border-color: rgba(0, 0, 0, 0.08);
                    .k-grid-content {
                        height: 90px;
                    }
                }
            }
        }
        .chk-and-input-holder {
            display: flex;
            justify-content: space-between;
            .chk-wrapper {
                display: flex;
                flex-direction: column;
            }
        }
    }
    .footer-btn-holder {
        background: #ffffff !important;
    }
}
