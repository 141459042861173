@import "../../../../scss/variable";
@import "../../../../scss/mixin";
.wms-button-dropdown-component {
    float: left;
    margin: 0 12px 12px 0;
    .wms_dropdown_button {
        width: auto;
        float: left;
        &.hidden_menu {
            display: none;
        }
        ul {
            li {
                outline: none !important;
                box-shadow: none !important;
                .k-link.k-menu-link {
                    padding: 0px;
                    .k-menu-expand-arrow.k-i-arrow-60-down {
                        margin-left: 0;
                        margin-top: 3px;
                    }
                }
            }
        }
        .k-animation-container {
            ul {
                min-width: 170px;
            }
        }
        .disabled {
            opacity: 0.2;
            cursor: not-allowed;
            .wms-toolbar-button-text {
                pointer-events: none;
                box-shadow: none;
            }
        }
        .wms-toolbar-button-text {
            cursor: pointer;
            color: #fff;
            padding: 0 10px 1px 15px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            height: 32px;
            // .k-link {
            //     padding: 0px 4px 0px 3px;
            // }
        }
        .k-group {
            li {
                .k-link {
                    padding: 6px 12px !important;
                }
            }
        }

        .primary_color {
            background: var(--primary-themecolor);
            pointer-events: all;
            &:hover,
            &:active,
            &:focus {
                background: var(--primary-hovered);
            }
            .k-state-hover {
                &:not(.k_state_disabled) {
                    background-color: var(--primary-themecolor);
                    color: var(--primary-white);
                }
            }
        }

        .outlined_color {
            border: 1px solid var(--default-border);
            color: var(--primary-black) !important;
            pointer-events: all;
            &:hover,
            &:active,
            &:focus {
                background-color: rgba(0, 0, 0, 0.08);
            }
            .k-state-hover {
                &:not(.k_state_disabled) {
                    color: var(--primary-black);
                }
            }
            .k-menu-link {
                color: var(--primary-black) !important;
            }
        }

        .yellow_color {
            background: $yellowbtn;
            &:hover,
            &:active,
            &:focus {
                background: $yellowbtn-hover;
            }
            .k-state-hover {
                &:not(.k_state_disabled) {
                    background-color: $yellowbtn-hover !important;
                }
            }
        }

        .red_color {
            background: $redbtn;
            &:hover,
            &:active,
            &:focus {
                background: $redbtn-hover;
            }
            .k-state-hover {
                &:not(.k_state_disabled) {
                    background: $redbtn-hover !important;
                }
            }
        }

        .black_color {
            background: $blackbtn;
            &:hover,
            &:active,
            &:focus {
                background: $blackbtn-hover;
            }
            .k-state-hover {
                &:not(.k_state_disabled) {
                    background: $blackbtn-hover !important;
                }
            }
        }

        // .wms_dropdown_sprite {
            // width: auto;
            // font-size: 14px;
            // color: $primary-white;
            // display: inline-block;
            // vertical-align: middle;
            // margin: 0 7px 0 4px;
            // margin-right: 10px;
            // line-height: 14px;

            // @include desktop_1199 {
            //     font-size: 14px;
            //     line-height: 30px;
            //     margin: 0 4px;
            // }
        // }
        .wms_dropdown_menu_title {
            font-weight: bold;
            margin-left: 9px;
        }
    }
}
