.wms-model-new-ui-wrapper {
    .k-window-titlebar {
        .k-window-actions {
            margin-top: 17px;
        }
    }
}
.pack-and-ship-transaction-model-wrapper {
    .model-content-wrapper {
        .small-parcel-recored-wrapper {
            display: flex;
            margin-bottom: 20px;
            .small-parcel-recored-col {
                flex:1;
                display: flex;
                align-items: flex-start;
                gap: 5px;
                .edit-icon {
                    color: #005ca8;
                    cursor: pointer;
                    margin-top: 10%;
                }
                table {
                    max-width: 90%;
                    max-height: 100%;
                    tbody {
                        th {
                            text-align: left;
                            font: Bold 16px/22px Open Sans;
                            color: #222222;
                            white-space: nowrap;
                            vertical-align: top;
                        }
                        td {
                            color: #222222;
                            padding-left: 10px;
                            vertical-align: top;
                        }
                        .remove-white-space {
                            white-space: pre;
                        }
                    }
                }
            }
        }
        .wms-collapsible-div {
            .wms-collapsible-content {
                .smart-pack-wrapper {
                    display: flex;
                }
                .packing-slip-wrapper {
                    display: flex;
                    margin-top: 20px
                }
                .pack-and-ship-process {
                    width: 150px;
                }
                .pack-and-ship-label-format {
                    width: 195px;
                }
                .pack-and-ship-return-label {
                    width: 255px;
                }
                .pack-and-ship-combo-template {
                    width: 255px;
                }
                .package-slip-type {
                    width: 300px;
                    padding-left: 10px;
                }
                .packing-slip-address-configuration {
                    width: 300px;
                }
                .pack-and-ship-batchname {
                    width: 250px;
                    float: left;
                }
                .inline-elements {
                    display: flex;
                    .add-package-wrapper {
                        width: 330px;
                        display: flex;
                        flex-direction: column;
                        margin-left: 15px;
                        margin-right: 4px;
                        .pkg-option-label {
                            display: flex !important;
                            flex: 17px 0;
                            align-items: center;
                            font-size: 10px;
                            line-height: 1.2;
                            margin-left: 1px;
                            color: #222;
                            font-weight: 400;
                            display: inline-block;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-bottom: 3px;
                        }
                        .pkg-elements-wrapper {
                            display: flex;
                            flex: 1;
                            display: flex;
                            .qty-label {
                                height: 32px;
                                width: 50px;
                                padding: 5px 10px 5px 10px;
                                background-color: transparent;
                                border: 1px solid #bbbbbb;
                                border-bottom-left-radius: 4px;
                                border-top-left-radius: 4px;
                                color: #222222;
                                font-weight: 700;
                                font-size: 14px;
                                border-right: none;
                            }
                            .qty-input-wrapper {
                                width: 65px;
                                padding: 0;
                                margin: 0;
                                .inputlabel {
                                    display: none;
                                }
                                input {
                                    border-radius: 0 !important;
                                    border-right: none;
                                }
                            }
                            .pack-and-ship-add-package {
                                padding: 0;
                                margin: 0;
                                label {
                                    display: none;
                                }
                                .k-combobox {
                                    .k-dropdown-wrap {
                                        border-bottom-left-radius: unset;
                                        border-top-left-radius: unset;
                                        border-bottom-right-radius: 4px;
                                        border-top-right-radius: 4px;
                                        .k-icon {
                                            color: #222222;
                                        }
                                    }
                                }
                            }
                        }

                        &.disabled-add-pkg {
                            .pkg-elements-wrapper {
                                opacity: 0.2;
                                pointer-events: none;
                            }
                            .k-dropdown-wrap {
                                pointer-events: none;
                            }
                        }
                    }
                }
                .get-rates-button {
                    margin: 20px 0px 0 10px;
                }
                .pack-and-ship-add-package {
                    width: 375px;
                    padding-left: 15px;
                    .form_group {
                        padding: 0 6px 0 0;
                    }
                }
                .pack-and-ship-retailer-template {
                    width: 233px;
                }
                .pack-and-ship-carton-label {
                    width: 312px;
                    ._pendo-badge_ {
                        height: 15px !important;
                        ._pendo-image {
                            height: 15px !important;
                            width: 15px !important;
                        }
                    }
                }
                .pack-and-ship-transaction-label {
                    width: 245px;
                }
                .wms-rounded-checkbox {
                    margin: 20px 0 0 24px;
                    padding: 0;
                }
                .disable-element {
                    opacity: 0.2;
                }

                .wms-virtual-group-grid-holder {
                    padding: 0 15px;
                    .wms-grid-area-wrapper {
                        border-top: 1px solid #00000014;
                        border-top-right-radius: 4px;
                        border-top-left-radius: 4px;
                        .k-grid-header {
                            border-top : none;
                            border-left : none;
                            border-right : none;
                            [data-field="CheckIcon"] {
                                pointer-events: none;
                            }
                            .k-header {
                                text-transform: uppercase;
                            }
                        }
                    }
                    .k-grid-content {
                        tr {
                            &.k-grouping-row {
                                td {
                                    background-color: #e5e5e5;
                                }
                                .k-i-collapse {
                                    display: none;
                                }
                                .package-icon-wrapper {
                                    font: normal normal normal 13px/18px
                                        "Open Sans";
                                    color: #222222;
                                    display: flex;
                                    flex: 1;
                                    cursor: pointer;
                                    label {
                                        font: normal normal bold 13px/18px
                                            "Open Sans";
                                        color: #222222;
                                    }
                                    .group-icon-wrapper {
                                        display: flex;
                                        justify-content: flex-end;
                                        flex: 1;
                                        .disabled-icon {
                                            opacity: 0.2;
                                            pointer-events: none;
                                        }
                                        a {
                                            padding: 0 8px;
                                        }
                                        .fa-pencil,
                                        .fa-print {
                                            color: #025ca8;
                                        }
                                        .fa-trash-o {
                                            color: #dc4f3b;
                                        }
                                    }
                                }
                            }
                            .selected-package {
                                display: block;
                                margin: -11px;
                                font-size: 20px;
                                padding-right: 20px;
                            }
                            .qty-icon-wrapper {
                                display: flex;
                                .fa-check {
                                    color: #dcdcdc;
                                    flex: 1;
                                    justify-content: flex-end;
                                    display: flex;
                                    margin-right: 6px;
                                }
                                &.checked-package {
                                    .fa-check {
                                        color: #81c925;
                                    }
                                }
                            }
                            .hazmat-wrapper {
                                display: flex;
                                .pill-wrapper {
                                    background-color: #D91C03;
                                    padding: 5px 9px 5px 9px;
                                    border-radius: 100px;
                                    color: white;
                                }
                                .description-wrapper {
                                    padding-top: 5px;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
            &#PackAndShipSmartParcel_main {
                .wms-collapsible-content {
                    padding: 15px 0;
                    .pack-and-ship-process {
                        margin-left: 10px;
                    }
                }
            }
            &#PackAndShipSmartPack_main {
                .wms-collapsible-content {
                    padding: 15px 0;
                    .scan-key-wrapper {
                        width: 100%;
                        margin-top: 20px;
                        padding-top: 20px;
                        display: flex;
                        border-top: 1px solid #eeeeee;
                        padding-left: 10px;
                        justify-content: space-between;
                        .first-section {
                            display: flex;
                        }
                        .wms-input-component {
                            width: 264px;
                            padding: 0 6px 0 0;
                        }
                        .scan-key-not-found {
                            color: red;
                            align-items: center;
                            display: flex;
                            width: 150px;
                        }
                        .wms-button-component {
                            margin-top: 19px;
                            margin-left: 6px;
                            button {
                                border-radius: 16px;
                                padding: 7px 16px;
                            }
                        }
                        .package-scan-add {
                            button {
                                .wms_toolbar_button_text {
                                    display: none;
                                }
                            }
                        }
                        .chk-btn-wrapper {
                            display: flex;
                            justify-content: flex-end;
                            flex: 1;
                            .pack-all-selected {
                                .wms_sprite {
                                    width: 16px;
                                }
                            }
                            .package-delete-all {
                                margin-right: 10px;
                            }
                            .qty-chk-wrapper {
                                margin-right: 15px;
                            }
                        }
                    }
                    .scale-weight-text {
                        display: flex;
                        align-items: center;
                        color: #222222;
                        justify-content: center;
                        margin-bottom: 5px;
                        span {
                            margin-left: 10px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    .footer-btn-holder {
        .wms-button-component {
            .wms_sprite {
                // margin-right: 5px;
                width: 16px;
            }
            &:first-child {
                .wms_toolbar_button_text {
                    margin-left: 8px;
                }
            }
            .wms_toolbar_button_text {
                margin-left: 10px;
            }
        }
    }
    /* .footer-btn-holder {
        .wms-button-component {
            .wms_toolbar_button_text {
                margin-left: 15px;
                margin-right: 20px;
            }
        }
    } */

    .div-packnship-loading-footer {
        position: absolute;
        right: 0;
        margin-right: 30px;
    }
}

.add-item-quantity-wrapper {
    .text-align-center {
        text-align: center;
        color: #222222;
        padding: 0 !important;
        .sku-detail-wrapper {
            padding: 15px;
            border-bottom: 1px solid #eeeeee;
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 50%;
                    display: flex;
                    label {
                        font-weight: Bold;
                        color: #222222;
                        width: 70px;
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;
                        padding-right: 10px;
                    }
                    span {
                        color: #222222;
                        text-align: left;
                        float: left;
                        width: calc(100% - 70px);
                        word-break: break-all;
                    }
                }
            }
        }
        hr {
            border-color: #eeeeee;
        }
        .scan-key-wrapper {
            width: 100%;
            justify-content: center;
            .wms-input-component {
                width: 230px;
                margin-bottom: 0;
                .inputlabel {
                    display: flex;
                    margin-bottom: 3px;
                }
                .wms-search-input-icon {
                    top: 15px;
                }
            }
            .scan-key-not-found {
                color: red;
                height: 19px;
            }
        }

        .quantity-wrapper {
            display: flex;
            flex-direction: revert;
            justify-content: center;
            .fa-minus {
                margin-top: 19px;
                border: 1px solid rebeccapurple;
                padding: 8px 10px 20px 10px;
                height: 32px;
                border-top-left-radius: 4px;
                border: 1px solid #bbbbbb;
                border-bottom-left-radius: 4px;
                border-right: none;
                cursor: pointer;
                &.disable-btn {
                    opacity: 0.2;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }
            .pack-and-ship-itemQuantity {
                padding: 0;
                text-align: left;
                .inputlabel {
                    margin-left: -32px !important;
                }
                input {
                    border-radius: unset !important;
                }
            }
            .fa-plus {
                margin-top: 19px;
                padding: 8px 10px 20px 10px;
                height: 32px;
                border-top-right-radius: 4px;
                border: 1px solid #bbbbbb;
                border-bottom-right-radius: 4px;
                border-left: none;
                cursor: pointer;
                &.disable-btn {
                    opacity: 0.2;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }
            &.invalid-error {
                .fa-minus {
                    border-color: red !important;
                }
                .fa-plus {
                    border-color: red !important;
                }
                .pack-and-ship-itemQuantity {
                    input {
                        border-top-color: red !important;
                        border-bottom-color: red;
                    }
                }
            }
            .scan-inputlabel {
                margin-top: 19px;
                padding: 6px 0 0 9px;
                border: 1px solid #bbbbbb;
                margin-bottom: 14px;
                margin: 19px 0 14px 15px;
                border-radius: 4px;
                cursor: pointer;
                input {
                    width: 0;
                    opacity: 0;
                }
                &.scanned-focused {
                    border-color: #005ca8;
                    i {
                        color: #005ca8;
                    }
                }
                &.scanned-detected {
                    background: #005ca80d 0% 0% no-repeat padding-box;
                    border-color: #005ca8;
                    i {
                        color: #005ca8;
                    }
                }
            }
        }
        .error-message {
            color: red;
            opacity: 1;
            &.message-opacity {
                opacity: 0;
            }
        }
    }
}
.serial-number-popup-wrapper {
    .model-content-wrapper {
        color: #222222;
        padding: 0 !important;
        .serial-reminder-label,
        h1 {
            text-align: center;
        }
        .wms-input-component {
            width: 100%;
            padding: 0 25px;
        }
        hr {
            border-color: #eeeeee;
        }
        .provide-serial-wrapper {
            width: 100%;
            padding: 0 25px 25px 25px;
            h4 {
                margin-bottom: 3px;
            }
        }
        .serial-input-add-wrapper {
            display: flex;
            .wms-input-component {
                padding: 0 12px 0 25px;
            }
            .wms-button-component {
                margin: 19px 14px 6px 0;
                button {
                    padding: 0 13px 0 15px;
                    .wms_toolbar_button_text {
                        display: none;
                    }
                }
            }
        }
    }
}
.confirm-remove-package-wrapper {
    .confirm-remove-msg {
        b {
            font: normal normal bold 16px/22px Open Sans;
            color: #222222;
        }
    }
}
.confirm-remove-all-package-wrapper {
    .confirm-remove-msg {
        b {
            font: normal normal bold 16px/22px Open Sans;
            color: #222222;
        }
    }
}
.wms-model-new-ui-wrapper {
    .pack-and-ship-package-options {
        width: 250px;
    }
    .pack-and-ship-weight {
        width: 125px;
        &.reset-icon-opacity {
            i {
                opacity: 0.2;
            }
        }
    }
}
.edit-package-detail-wrapper {
    .wms-collapsible-content {
        padding: 0;
        .pack-and-ship-package-options,
        .pack-and-ship-weight {
            padding: 15px 0px 0px 15px;
            .wms-search-input-icon {
                right: -4px;
                top: 35px;
            }
        }
        .customer-dimensions-wrapper {
            .dimension-wrapper {
                padding: 15px;
            }
        }
    }
    .model-content-wrapper {
        .length-wrapper,
        .width-wrapper,
        .height-wrapper {
            width: 80px;
        }
        .reference-fields-wrapper {
            padding: 15px;
            .wms-input-component {
                width: 100%;
            }
        }
    }
}
.verify-track-by-wrapper {
    .model-content-wrapper {
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style-type: none;
                border: none;
                display: flex;
                padding: 0px 10px;
                line-height: 35px;
                color: #222222;
                font-family: "Heebo";
                strong {
                    display: inline-block;
                    min-width: 180px;
                    padding: 0px 12px 0px 0px;
                    color: #222222;
                    font-family: "Heebo";
                }
            }
        }
    }
}
.custom-package-model-wrapper {
    .model-content-wrapper {
        .length-wrapper,
        .width-wrapper,
        .height-wrapper {
            width: 80px;
        }
    }
}
#packAndShipTransactionGrid {
    .pink-color {
        color: #d749a3;
        padding: 0 5px;
    }
}