@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";
#addToNewBatch{
    .wms-area-content {
        margin-top: 15px;
        .wms-area-content-darker {
            padding: 12px 6px;
            .wms-input-component {
                width: 100%;
                margin-bottom: 0;
            }
            .wms-dropdown-component {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}