@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";
.WindowPickTicket {
    .wms-toolbar {
        width: 100%;
        float: left;
        padding: 0 6px;
        margin-bottom: 10px;
        .wms-toolbar-button {
            margin-right: 5px;
            padding: 0 10px;
        }
        .wms-disabled-look {
            pointer-events: none !important;
        }
    }
    .wms-area-content-darker {
        margin-bottom: 10px;
        .wms-toolbar {
            width: 100%;
            float: left;
            padding: 0 7px;
        }
        .radio-btnlist {
            .radiobtn {
                margin-bottom: 10px;
            }
            .radionew {
                float: none !important;
            }
        }
        .checkbox-listing {
            margin: 12px 0;
            &.pick-ticket {
                padding-top: 20px;
            }
            .wms-checkbox-component {
                width: 100% !important;
            }
        }
        .wms-radiobtn-component {
            .wms-radiobtn {
                margin-bottom: 10px;
            }
        }
    }
}