@import "variable";

/*=====  Responsive Mixins  =====*/
@mixin desktop_1680 {
    @media screen and (max-width:1680px) {
        @content;
    }
}
@mixin desktop_1600 {
    @media screen and (max-width:1600px) {
        @content;
    }
}
@mixin desktop_1440 {
    @media screen and (max-width:1440px) {
        @content;
    }
}
@mixin desktop_1366 {
    @media screen and (max-width:1366px) {
        @content;
    }
}
@mixin desktop_1280 {
    @media screen and (max-width:1280px) {
        @content;
    }
}
@mixin desktop_1199 {
    @media screen and (max-width:1199px) {
        @content;
    }
}
@mixin desktop_1024 {
    @media screen and (max-width:1024px) {
        @content;
    }
}


@mixin title-bold {
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: bold !important;
}

@mixin label {
    font-size: 10px;
    line-height: 1.2;
    margin-left: 1px;
    color: $input-color;
    font-weight: normal;
    display: inline-block;
    width: 100%;
}

