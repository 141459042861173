@import "scss/styles.scss";
// @import "./assets/css-scss/font-awesome.min.css";
// @import "./assets/css-scss/font-awesome-animation.css";

* {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

body,
html {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    font-family: $primary-font !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #root {
        width: 100%;
        height: 100%;
    }
}

button,
input,
optgroup,
select,
textarea {
    font-family: $primary-font !important;
}

ul {
    margin: 0px;
    padding: 0px;
}

li {
    list-style: none;
}

hr {
    border: 1px solid #ecf5fd;
    margin: 10px 0px;
}
#managerLogin {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: translateX(-50%);
    z-index: -1;
    opacity: 0.15;
}

.faa-spin.animated,
.faa-spin.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-spin {
    -webkit-animation: spin 3.5s linear infinite;
    animation: spin 3.5s linear infinite;
}

.tast_txt {
    opacity: 0;
}

.tast_txt.opacity {
    opacity: 1;
}

/******  Loader CSS  *******/

.wms-notification.danger {
    position: relative;
    padding: 0 0 40px;
    .wms-notification-header {
        padding: 12px 15px;
        border-bottom: 1px solid;
    }
    .wms-notification-content {
        padding: 20px 15px;
        margin: 0 0 15px;
        word-break: break-word;
        max-height: 234px;
        overflow: auto;
        font-weight: 400;
        .wms-error-code {
            display: block;
            margin-bottom: 10px;
        }
    }
    .wms-notification-toolbar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .wms-toolbar {
            .wms-toolbar-button {
                border-radius: 0px;
                line-height: 40px;
                display: flex;
                .wms-sprite,
                .wms-toolbar-button-text {
                    vertical-align: top;
                    line-height: 40px;
                }
            }
            .width50 {
                width: 50%;
                margin: 0px;
            }
            a {
                text-decoration: none;
            }
            .contact-support-link {
                width: 50%;
                .savebtn {
                    width: 100%;
                }
            }
        }
    }
}

.wms-notification.alert-msg {
    width: 510px;
    text-align: left;
    -webkit-font-smoothing: antialiased !important;
    span {
        display: block;
        padding: 5px 0px;
    }
    p {
        font-weight: normal;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}
