@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-input-tag-component {
    width: 100%;
    float: left;
    padding-left: 7px;
    flex-wrap: wrap;
    padding: 0px 6px;
    margin-bottom: 8px;
    label {
        font-size: 10px;
        line-height: 1.2;
        margin-left: 1px;
        font-weight: normal;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 3px;
        color:#222222 ;
    }

    .k-combobox {
        height: 32px;
        width: 40%;
        background-color: transparent;
        color: #222222;
        background-color: transparent;
        .k-dropdown-wrap {
            background-color: $primary-white!important;
            border: 1px solid $border-color !important;
            outline: none !important;
            box-shadow: none !important;
            color: $input-color !important;
            border-radius: 4px;
            
            .k-input {              
                color: $input-color;
                height: 100%;
                padding-top: 0px;
                height: 32px;
                font-weight: 600;
                font-size: 14px;
                padding: 0 10px;
            }
            .k-clear-value {
                height: 30px;
                top: 1px;
                right: 22px;
            }
            .k-select{
                background: transparent;
            }
        }
    }
    .selected-values{

        padding: 14px 0px 9px 0px;
        width: 100%;
        button{
            height: 25px;
        }
        .wms_toolbar_button{
            padding: 0;
            width: auto;
            height: 20px;
            justify-content: center;
            display:flex;
            flex-direction:row-reverse;
            padding-left: 6px;
            padding-right: 6px;
            .wms_sprite{
                width: 12px;
                height: 12px;
                font-size: 12px;
                line-height: 22px;
                margin: 0;
            }
            .wms_toolbar_button_text{
                margin: 0px 9px 0px 4px;
                font-size: 10px;
            }
        }
    }
    .div_disable {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: not-allowed;
        height: 40px;
        display: inline-block;
    }
    .duplicate-tooltip {
        position: absolute;
        margin-top: 5px;
        left: 0;
        min-width: 180px;
        z-index: 12;
        background: #ecf5fd !important;
        font-size: 14px;
        color: #7d7d7d !important;
        border: 1px solid #b7d2e5 !important;
        padding: 4px 8px;
        &:before {
            content: "\A";
            position: absolute;
            top: 1px;
            left: 30px;
            border-style: solid;
            border-width: 11px 11px 0px 20px;
            border-color: transparent #0079c4 transparent transparent;
            -webkit-transform: rotate(-135deg);
        }
    }
}