@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms_menu_item_wrapper {
    li {
        .k-link {
            display: flex;
            text-align: center;
            justify-content: space-between;
            .wms-badge {
                margin-left: 10px;
            }
        }
    }

    .k_state_disabled {
        outline: none;
        opacity: 0.6;
        filter: grayscale(0.1);
        cursor: not-allowed !important;
        pointer-events: none !important;
    }
    .mydisabled {
        background-color: white !important;
        color: #717171 !important;
        pointer-events: all !important;
        span {
            cursor: not-allowed !important;
            .wms-badge {
                opacity: 0.5;
            }
        }
    }
}
