@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-model-new-ui-wrapper {
    border-radius: 4px;
    //top:100px !important;
    background: #fff;
    height: auto !important;
    &.model-height-fixed {
        height: 100% !important;
    }
    &.k-window-maximized {
        top: 20px !important;
        bottom: 20px !important;
        left: 20px !important;
        right: 20px !important;
        width: auto !important;
        max-height: 100% !important;

        .k-window-content {
            max-height: 100% !important;
        }
    }
    .k-window-titlebar {
        --seprator-color: #bad2ea;
        --sidepanel-bg: #bad2ea;
        background: #849eb0 !important;
        padding: 0;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #bad2ea !important;
        height: 80px;
        min-height: 80px;
        .k-window-title {
            color: var(--primary-white) !important;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
            padding: 2px 0px 0px 30px;
        }
        .k-window-actions {
            padding: 15px;
            .k-button {
                color: var(--primary-white);
            }

        }
    }
    .Modal1 {
        // height: unset !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        border-radius: 0 0 4px 4px;
        background: #fff;
        .model-content-wrapper {
            padding: 30px;
            overflow: auto;
            flex: 1;
            background: #fff;
            .wms-container-wrapper {
                &:last-child {
                    .wms-area-content {
                        margin-bottom: 0 !important;
                        .wms-area-content-darker {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
            .wms-collapsible-div {
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
        // .wms-area-content-darker {
            // overflow-y: auto;
            // flex: 1;
        // }
        .footer-btn-holder {
            display: flex;
            height: 95px;
            min-height: 95px;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            border-radius: 0 0 4px 4px;
            .wms-button-component {
                .button_holder {
                    .wms-button-tooltip {
                        top: unset;
                        bottom: 44px;
                        &:before {
                            -webkit-transform: rotate(-315deg);
                            top: unset;
                            bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    &.change-maximize-icon {
        .k-window-actions {
            a[aria-label="window-restore"] {
                background: url("/assets/images/collapse.png") no-repeat !important;
                &:focus {
                    color: transparent;
                }
                .k-icon {
                    display: none;
                }
            }
            a[aria-label="window-Maximize"] {
                background: url("/assets/images/expand.png") no-repeat !important;
                &:focus {
                    color: transparent;
                }
                .k-icon {
                    display: none;
                }
            }
        }
    }
}
.model-tooltip-title-p {
    max-width: 300px;
    max-height: 300px;
}
.wms-model-new-model-wrapper.mini-model {
    .k-window-titlebar {
        &.k-header{
            height: auto;
            min-height: 50px;
        }
        .k-window-actions{
            padding: 10px;
        }
        .k-window-title {
            min-height: 25px;
            .fa-exclamation-triangle {
                color: #F5B05E;
                padding-right: 15px;
            }
        }
    }
    #newMessageAlertModel {
        .model-content-wrapper{
            padding: 20px;
        }
        .footer-btn-holder{
            height: auto;
            min-height: 75px;
        }
    }
}
.wms-model-new-model-wrapper {
    border-radius: 4px;
    background: #fff;
    height: auto !important;
    &.model-height-fixed {
        height: 100% !important;
    }
    // &.k-window-maximized {
    //     top: 20px !important;
    //     bottom: 20px !important;
    //     left: 20px !important;
    //     right: 20px !important;
    //     width: auto !important;
    //     max-height: 100% !important;

    //     .k-window-content {
    //         max-height: 100% !important;
    //     }
    // }
    .k-window-titlebar {
        --seprator-color: #ffffff;
        --sidepanel-bg: rgba(0, 0, 0, 0.08);
        background: #ffffff !important;
        padding: 0;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
        height: 80px;
        min-height: 80px;
        .k-window-title {
            // font: normal normal bold 20px/24px Inter;
            color: #222222 !important;
            font-weight: bold;
            font-size: 20px;
            padding: 2px 0px 0px 30px;
            .fa-exclamation-triangle {
                color: red;
                padding-right: 15px;
            }
        }
        .k-window-actions {
            padding: 15px;
            .k-button {
                color: var(--primary-white);
            }

            a[aria-label="Close"] {
                background: url("/assets/images/close.png") no-repeat !important;
                &:focus {
                    color: transparent;
                }
                .k-icon {
                    display: none;
                }
            }
        }
    }
    .Modal1 {
        // height: unset !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        border-radius: 0 0 4px 4px;
        background: #fff;
        .model-content-wrapper {
            padding: 30px;
            overflow: auto;
            flex: 1;
            background: #fff;
            color: #222222;
            .wms-container-wrapper {
                &:last-child {
                    .wms-area-content {
                        margin-bottom: 0 !important;
                        .wms-area-content-darker {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
            .wms-collapsible-div {
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
        .footer-btn-holder {
            display: flex;
            height: 95px;
            min-height: 95px;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            border-radius: 0 0 4px 4px;
            .wms-button-component {
                .button_holder {
                    button {
                        padding: 0 16px;
                    }
                    .wms-button-tooltip {
                        top: unset;
                        bottom: 44px;
                        &:before {
                            -webkit-transform: rotate(-315deg);
                            top: unset;
                            bottom: 0px;
                        }
                    }
                    .wms_toolbar_button_text {
                        margin-left: 0px !important;
                    }
                }
            }
        }
    }
}