@import "../../../scss/variable";
@import "../../../scss/mixin";
#warehouses-holder-react {
    width: 100%;
    height: 100%;
    display: flex;
    background: #fafbfc;
    padding: 0 !important;
    .warehouse-grid {
        width: 46.5%;
        height: 100%;
        padding: 30px;
        background: rgba(0, 0, 0, 0.03);
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        .wh-topbtn-holder {
            .search-applied {
                float: right;
                margin: 8px 0 10px 2px;
                label {
                    color: #5a7e9d;
                    font-size: 11px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 600;
                }
            }
        }

        .warehouse-form-boxes {
            margin-top: 20px;
            .wms-collapsible-div {
                .wms-collapsible-content {
                    .wms-input-group-wrapper {
                        width: 50%;
                    }
                }
            }
        }


        .mail-content {
            font-size: 13px;
            margin-bottom: 15px;
            display: inline-block;
            color: #5a7e9d ;
        }

        .wms-grid-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;
            .wms-grid-toolbar {

                .wms-toolbar-button {
                    // min-width: 324px;
                    .wms-button-component {
                        @include desktop_1280 {
                            margin: 0 5px 5px 0;
                        }
                    }
                }
                .WarehouseRefreshBtn {
                    .blue_button_color {
                        padding: 0px 16px;
                        .wms_toolbar_button_text {
                            display: none;
                        }
                    }
                }
            }
            .wms-grid-wrapper {
                .wms-grid-top {
                    margin-top: 0px;
                }
            }
        }

    }
    .warehouse-form {
        position: relative;
        height: 100%;
        flex: 1;
        width: 53.5%;
        display: flex;
        flex-direction: column;
        .warehouse-form-header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap-reverse;            
            padding: 15px 30px 14px 30px;
        }
            .tab-content {
                overflow-y: scroll;
                padding: 0 14px 0px 30px;
                flex: 1 1 auto;
                .wms-radiobtn-component {
                    margin: 21px 0;
                    float: left;
                    width: 15%;
                    @include desktop_1280 {
                        width: 25%;
                        margin: 15px 0;
                    }
                    .wms-radiobtn {
                        margin: 0px;
                        padding: 0 5px;
                        @include desktop_1366 {
                            padding: 0 2px;
                        }
                    }
                }
                .wms-input-component {
                    float: left;
                        @include desktop_1280{
                            width: 50%;
                        }
                }
                .wms-dropdown-component {
                    width: 33.33%;
                    float: left;
                    @include desktop_1280 {
                        width: 50%;
                    }
                }
                .inline-elements {
                    display: flex;
                    .wms-checkbox-component{
                        &.user-predefined-chk-wrapper {
                            margin-right: 50px;
                        }
                    }
                }
                .replenishment-chk-wrapper {
                    display: flex;
                    flex-direction: column;
                    .replenishment-wrapper {
                        display: flex;
                        flex-direction: column;
                        margin-left: 40px;
                    }
                }
            }
        }
    }