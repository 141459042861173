@import "./../../scss/variable";
@import "./../../scss/mixin";
.mobile-label-templates-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 !important;
    .mobile-label-templates-search {
        width: 405px;
        min-width: 400px;
        max-width: 405px;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #b7d2e5;
        background: rgba(0, 0, 0, 0.03);
        position: relative;
        transition: all 1s linear;
        .title {
            padding: 5px 0 7px 0;
            label {
                color: $filter-note-title !important;
                font-size: 14px;
                letter-spacing: 1px;
                font-weight: 600;
            }
        }
        .btn-section {
            padding-bottom: 30px;
            .wms-button-component {
                float: right;
            }
        }
        .customer-options {
            width: 100%;
        }
        .label-size {
            width: 175px;
        }
    }
    .mobile-label-templates-grid {
        flex: 1 1;
        overflow-x: auto;
        margin: 0 0 20px 0;
        overflow-y: hidden;
        .mobile-label-templates-btn {
            .wms_toolbar_button {
                padding: 0 16px;
                .wms_toolbar_button_text {
                    display: none;
                }
            }
        }
        .default-template-lock {
            margin-right: -17px;
            padding-left: 5px;
        }
    }
}

#ManageMuLabelTemplate {
    .customer-template-wrapper {
        display: flex;
        .template-name {
            width: 344px;
        }
        .customer-options {
            width: 200px;
        }
    }
    .command-wrapper {
        textarea {
            height: 200px;
            width: 700px;
        }
    }
    .information-wrapper {
        span {
            color: #222222;
            margin-right: 20px;
        }
        label {
            color: #222222;
            font-weight: 700;
        }
    }
    .assign-customer-wrapper {
        display: flex;
        flex-direction: column;
    }
}

