@import "../../../scss/variable";
@import "../../../scss/mixin";
#body-component {
    width: 100%;
    height: 100%;
    font-size: $primary-fontsize;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    // margin: 60px 0 0;

    header {
        display: flex;
        height: 60px;
        z-index: 101;
        background: #005ba8;
        border-bottom: 1px solid #fff;
        .logo {
            width: 175px;
            height: 100%;
            text-align: center;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            a {
                height: 100%;
                width: 100%;
                display: block;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
        }
        .tabs {
            flex: 1;
            position: relative;
            overflow: hidden;
            display: flex;
            .arrow {
                position: absolute;
                top: 0;
                bottom: 0;
                color: white;
                text-align: center;
                vertical-align: middle;
                width: 40px;
                height: 100%;
                background: var(--secondary) 0% 0% no-repeat padding-box;
                opacity: 1;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                &.disable-arrow {
                    opacity: 0.2;
                    pointer-events: none;
                }
                i {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                }
                &.arrow-left {
                    left: -40px;
                    box-shadow: none;
                }
                &.arrow-right {
                    right: -40px;
                    box-shadow: none;
                }
            }
            &.tab-arrow-show {
                .arrow-left {
                    left: 0;
                    box-shadow: 0px 0px 15px #0000004d;
                }
                .arrow-right {
                    right: 0;
                    box-shadow: 0px 0px 15px #0000004d;
                }
                .tab-list {
                    margin: 0 40px;
                }
            }
            .tab-list {
                // flex: 1;
                transition: margin 0.3s ease-in-out;
                display: flex;
                overflow: hidden;
                .tab-item {
                    position: relative;
                    // float: left;
                    // list-style: none;
                    margin-right: 1px;
                    // overflow: hidden;
                    min-width: fit-content;
                    cursor: pointer;
                    a {
                        height: 100%;
                        text-decoration: none;
                        min-width: 140px;
                        display: flex;
                        align-items: center;
                        padding-right: 45px;
                        i {
                            display: inline-block;
                            vertical-align: middle;
                            margin: 0 14px 0 21px;
                            line-height: 20px;
                            text-align: center;
                        }
                        .detail {
                            h5 {
                                display: inline-block;
                                font-size: 12px;
                                line-height: 18px;
                                vertical-align: middle;
                                font-weight: normal;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                color: $primary-white;
                                padding: 0 15px 0 0;
                                margin: 0;
                            }
                            p {
                                display: block;
                                font-size: 10px;
                                margin: 0 14px 0 0;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                span {
                                    display: inline-block;
                                    margin-right: 2px;
                                    vertical-align: middle;
                                    :first-child {
                                        text-transform: capitalize;
                                    }
                                }
                            }
                        }
                    }
                    button {
                        position: absolute;
                        border: none;
                        background: transparent;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                        padding: 5px;
                        z-index: 1;
                        color: $primary-white;
                        opacity: 0.5;
                        &:hover {
                            color: #ca1800;
                            opacity: 1;
                        }
                        .fa-times-circle {
                            font-size: 13px;
                        }
                    }
                    &.active {
                        cursor: default;
                        a {
                            .detail {
                                h5 {
                                    color: $input-color;
                                }
                            }
                        }
                        button {
                            color: #ca1800;
                        }
                    }
                }
            }
        }
        .cogs-setting.hub {
            padding-right: 20px;
            background-color: #D5D5D5;
            margin: 0;
        }
        .cogs-setting {
            margin: auto;
            width: fix-content;
            display: flex;
            a.gear-holder {
                cursor: pointer;
                position: relative;
                margin: auto auto auto 15px;
                i {
                    color: #008895 !important;
                    font-size: 25px;
                    display: inline-block;
                }
                .wms-progress-online {
                    width: 10px;
                    height: 10px;
                    background: $green-menu;
                    position: absolute;
                    border-radius: 100px;
                    top: 0px;
                    right: 0px;
                }
            }
            .message-alert,
            .wms-info-dialog {
                margin: auto;
                cursor: pointer;
                padding: 0 0 0 15px;
                z-index: 1000;
                img {
                    margin-bottom: -5px;
                }
                i {
                    color: #008895;
                    font-size: 25px;
                    display: inline-block;
                    margin-top: 6px;
                }
            }
            .wms-hub-support-portal-link {
                align-self: center;
                color: #008895;
                text-decoration: underline;
                font-size: 120%;
                margin-left: 10px;
                i {
                    margin-right: 5px;
                }
            }
            .wms-info-dialog {
                margin: 0;
            }
            .wms-info-dialog.hub {
                margin: 5px 0px;
                .fa-question-circle {
                    margin-top: 7px !important;
                    .pendo-resource-center-badge-notification-bubble {
                        top: 2px !important;
                        right: 142px !important;
                        height: 18px !important;
                        width: 19px !important;
                        .pendo-notification-bubble-unread-count {
                            font-size: 14px !important;
                            left: -2px !important;
                        }
                    }
                }
            }
            .user {
                a {
                    cursor: pointer;
                    position: relative;
                    margin: auto;
                    i {
                        color: #008895;
                        font-size: 25px;
                        display: inline-block;
                    }
                }
                ul {
                    li {
                        .k-animation-container {
                            right: 0;
                            width: auto;
                            min-width: 170px;
                            ul {
                                width: calc(100% - 10px);
                                max-width: 100%;
                                li {
                                    &:hover {
                                        background-color: var(--primary) !important;
                                    }
                                    &:first-child {
                                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                                        font-weight: bold;
                                    }
                                    &.wms-border-bottom {
                                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                                    }
                                    a {
                                        padding: 8px 20px;
                                        transition: 0.2s ease-in-out;
                                        -wekbit-transition: 0.2s ease-in-out;
                                        -moz-transition: 0.2s ease-in-out;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bottom-wrapper {
        width: 100%;
        height: calc(100vh - 101px); // 40px super nav + 60px header + 1px margin bottom
        // flex: 1;
        display: flex;
        .sidenav {
            width: 175px;
            height: 100%;
            display: flex;
            transition: all 1s;
            // &:not(.expand){
            // 	.icon-menu {
            // 		display: none;
            // 	}
            // }
            .nav-content {
                // z-index: 200; // submenu not saw front after iframe open
                &.with-texticon-menu {
                    width: 175px;
                    display: flex;
                    flex-direction: column;
                    .copyright {
                        display: block;
                    }
                }
                &.icon-menu {
                    width: 0px;
                    display: flex;
                    flex-direction: column;
                    .copyright {
                        display: none;
                    }
                }
            }
        }
        .sidenav.expand {
            width: 40px;
            .nav-content {
                &.with-texticon-menu {
                    // position: absolute;
                    // top: 0;
                    // width: 0px;
                    animation: hideTextIconMenu 1s forwards;
                }
                &.icon-menu {
                    animation: showOnlyIconMenu 1s forwards 0.3s;
                }
            }
        }
        .sidenav.collaspse {
            width: 175px;
            .nav-content {
                &.with-texticon-menu {
                    width: 0px;
                    animation: showTextIconMenu 1s forwards 0.3s;
                    // position: relative;
                }
                &.icon-menu {
                    // width: 0px;
                    // height: 0px;
                    animation: hideOnlyIconMenu 0.3s forwards;
                }
            }
        }
        .sidenav {
            .view-list.show-view-list {
                width: 0px;
                animation: showViewListMenu 0.5s forwards;
            }
        }
        .sidenav {
            .view-list.hide-view-list:not(.default-view-list-hide) {
                width: 0px;
                animation: hideViewListMenu 0.5s forwards;
            }
        }
        &.showIconWithTextNav {
            .custom-tab-wrapper {
                width: calc(100% - 175px);
                // transition: all 1s linear;
            }
        }
        &.showIconNav {
            .custom-tab-wrapper {
                width: calc(100% - 40px);
                // transition: all 1s linear;
            }
        }
        .custom-tab-wrapper {
            height: 100%;
            // overflow: auto;
            // height: calc(100% - 40px);
            // flex: 1;
            .custom-tab-content {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                .dynamic-react-component {
                    width: 100%;
                    height: 100%;
                    background: var(--form-boxbg);
                    border: 0;
                    outline: 0;
                    overflow: auto;
                    > div {
                        padding: 26px;
                    }
                }
            }
        }
        .sidenav-z {
            z-index: 10 !important;
        }
    }
    &.wms-customer-user-body {
        .bottom-wrapper {
            height: calc(100vh - 61px); // 40px super nav + 60px header + 1px margin bottom
        }
    }
}

.hub-header {
    min-height: 50px !important;
    max-height: 50px !important;
    border-bottom: none !important;
    background-color: transparent !important;
}

.tabs-hub {
    background-color: #D5D5D5 !important;
    justify-content: center;
    .hub-tab-detail{
        display: flex;
        .first {
            margin-right: 10px;
        }
    }
}

.hub-tab-item-active, .hub-tab-item {
    margin-right: 0 !important;
}

.hub-tab-item-active a {
    background-color: #FAFBFC !important;
}

.hub-tab-item a {
    background-color: #BFBFBF !important;
}

.hub-logo {
    // height: 29px !important;
    border-right: 0 !important;
    background-color: #ED40A9 !important;

    a {
        display: none !important;
    }

    &::after {
        content: '3PL Manager';
        display: block;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: bold;
        text-align: end;
        margin-top: 5px;
        height: 18px;
        line-height: 1.7;
        background-image: url("../../../assets/images/3pl-whm.svg");
        filter: brightness(0) invert(1);
        background-repeat: no-repeat;
        background-position: 35px 0;
        padding: 0 32px;
    }
}

.body-hub .bottom-wrapper {
    height: calc(100% - 40px) !important;
}