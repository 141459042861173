
.carrier-markup-model-wrapper {
  .default-rate-amount {
      margin-right: 10px;
  }
  .wms-collapsible-content {
    padding: 0;
  }

  .service-rate-options {
      display: flex;
      padding: 15px;
      border-bottom: 1px solid #EEEEEE;
      .carrier-image-section {
        display: flex;
        max-width: 208px;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 10px;

        img {
            width: 100%;
            height: 48px;
            object-fit: contain;
        }
      }
      .carrier-markup-section {
        display: flex;
        flex-direction: column;
        .markup-item {
          display: flex;
        }
      }
      .carrier-services {
          width: 212px;
          &.disable-shipment-service {
            select {
              background: #F2F2F2;
              border: 1px solid #BBBBBB;
              opacity: 1;
            }
          }
      }
      .default-rate-percentage, .default-rate-amount {
          width: 112px;
      }

      .wms-button-component {
          &:first-child{
              margin-left: 5px;
          }
          .button_holder {
              margin: 18px 0px 0px 0px;
              .wms_toolbar_button {
                  padding: 7px 15px 7px 15px;
                  .wms_toolbar_button_text {
                      display: none;
                  }
              }
          }
      }
  }
}