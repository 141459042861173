@import "../../../../scss/variable";
@import "../../../../scss/mixin";
#wms-findorder-virtual-wrapper {
    height: 100%;
    width: 100%;
    .k-loading-mask {
        background-color: rgba(255, 255, 255, 0.8) !important;
    }
    &.find-order-holder-react {

        .find-order-search-holder {
            height: 100%;
            position: relative;
            .filter {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                border-radius: 0px;
                background: $tabel-headbg;
                border: 0px;
                z-index: 1;
                cursor: pointer;
                .fa-caret-left {
                    font-size: 16px;
                    padding: 4px 8px;
                }
                .fa-caret-left:before {
                    color: #527999;
                }
                &:hover {
                    background: #0079c4;
                    .fa-caret-left:before,
                    span {
                        color: #fff;
                    }
                }
                span {
                    display: none;
                }
            }
            .find-order-search-filter {
                height: 100%;
                border-right: none;
                transition: all 0.4s linear;
                overflow: hidden;
                display: inline-flex;
                flex-direction: column;
                -webkit-transition: all 0.4s ease-in;
                -moz-transition: all 0.4s ease-in;
                -o-transition: all 0.4s ease-in;
                transition: all 0.4s ease-in;
                margin-left: 0px;
                margin-right: 10px;
                // &.expand {
                //     transform: unset;
                //     margin-left: -396px;
                //     -webkit-transition: all 0.4s ease-in;
                //     -moz-transition: all 0.4s ease-in;
                //     -o-transition: all 0.4s ease-in;
                //     transition: all 0.4s ease-in;
                //     // @include desktop_1680 {
                //     //     margin-left: -340px;
                //     // }
                //     .collapse-box {
                //         position: absolute;
                //     }
                // }
                .title {
                    padding: 5px 0 7px 0;
                    label {
                        color: $filter-note-title !important;
                        font-size: 14px;
                        letter-spacing: 1px;
                        font-weight: 600;
                    }
                }
                .wms-toolbar {
                    display: flex;
                    width: 100%;
                    flex-direction: row-reverse;
                }
                .warehouse-form-boxes-wrapper {
                    position: relative;
                    width: 100%;
                    overflow: auto;

                    .warehouse-form-boxes {
                        transition: all ease-in-out 0.3s;
                        overflow: hidden;
                        .warehouse-are-content {
                            // display: inline-block;
                            border-radius: 4px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;
                            .title {
                                width: 100%;
                                float: left;
                                margin: 0 0 8px 0;
                                label {
                                    color: $form-title !important;
                                    font-size: 11px;
                                    text-transform: uppercase;
                                    letter-spacing: 1px;
                                    font-weight: 600;
                                }
                            }
                            .wms-collapsible-div {
                                .wms_collapsible_header {
                                    .wms-title {
                                        padding: 5px 12px 0 20px;
                                    }
                                }
                                .wms-collapsible-content {
                                    margin-bottom: 0;
                                    // padding: 15px 5px 5px;
                                    .wms-dropdown-component {
                                        width: 100%;
                                    }
                                    .wms-kendo-dropdown-list-component {
                                        width: 100%;
                                        flex-direction: row;
                                        .form_group {
                                            float: left;
                                            position: relative;
                                            padding: 0px 7px;
                                            margin-bottom: 11px;
                                        }
                                    }
                                    .add-clear-icon {
                                        .wms-kendo-dropdown-list-component {
                                            .k-dropdown {
                                                width: calc(100% - 28px);
                                            }
                                        }
                                    }
                                    ul#MultiSelectCustomerSkus_taglist {
                                        position: absolute;
                                    }
                                    .wms-datetimepicker-component {
                                        .border {
                                            width: 100%;
                                        }
                                    }
                                    .wms-input-component {
                                        width: 100%;
                                    }
                                }
                            }
                            .form-group {
                                width: 100%;
                                float: left;
                                padding: 0px;
                                margin-bottom: 15px;
                            }
                            .wms_area_border {
                                height: 100%;
                                overflow: hidden;
                                padding: 0 0 26px 0;
                                #gridContainer {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
                .filter-list-block {
                    padding-bottom: 8px;
                    margin-top: -4px;
                    li.filter-list {
                        display: flex;
                        height: 48px;
                        margin: 0px 7px 12px;
                        background-color: transparent;
                        border-radius: 4px !important;
                        padding: 13px 5px 13px 12px;
                        border: 1px solid #bbbbbb;
                        a.title-text {
                            color: $input-color;
                            position: relative;
                            white-space: nowrap;
                            width: 100px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;
                        }
                        .wms-apply-btn {
                            display: flex;
                            margin: -6px 3px;
                        }
                        .clear-save-filter {
                            padding: 2px 5px;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;
                            i {
                                font-size: 16px;
                                color: rgba(255, 0, 0, 0.2);
                                transition: all 0.3s ease-in-out;
                            }
                            &:hover {
                                color: #2c4059;
                                i {
                                    color: rgba(255, 0, 0, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        .find-order-grid {
            transition-property: width;
            -webkit-transition: all 0.4s linear;
            -moz-transition: all 0.4s linear;
            -o-transition: all 0.4s linear;
            transition: all 0.4s linear;
            overflow: hidden;
            background: $form-boxbg;
            overflow-y: auto;
            padding: 0;
            margin-bottom: 30px;
            .title {
                margin: 0 0 0 2px;
            }
            .wms-toolbar-button {
                float: right;
                display: flex;
                flex-wrap: wrap;
                .findOrferGridRefreshBtn {
                    .primary_button_color {
                        padding: 0px 16px;
                        .wms_toolbar_button_text {
                            display: none;
                        }
                    }
                }
            }
        }

        .search-hover-tooltip {
            position: absolute;
            top: 86px;
            left: 0;
            right: 0;
            background-color: #000000;
            color: white;
            font-size: 12px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 3px;
            padding: 8px;
            margin: 0 15px;
            z-index: 12;
            &::after {
                content: "\A";
                position: absolute;
                top: -10px;
                left: 50%;
                border-style: solid;
                border-width: 6px 6px 0 0px;
                border-color: #000000;
                -webkit-transform: rotate(-135deg);
                width: 10px;
                height: 10px;
                transform: rotate(-45deg) translateX(-50%);
                z-index: -1;
            }
        }
        .wms-search-grid-data {
            height: 100%;
            margin: 0 30px;
            .wms-virtual-grid-holder {
                margin: 0;
                .wms_area_content {
                    .wms-grid-details {
                        margin: 12px 0 0 0;
                    }
                }
            }
            .wms_area_content {
                .wms-button-dropdown-component {
                    .k-animation-container {
                        ul {
                            min-width: 180px;
                        }
                    }
                }
            }
            .wms-grid-area-wrapper {
                .gridContainer {
                    .k-grid-header-wrap {
                        table {
                            thead {
                                tr {
                                    th {
                                        // padding: 0 30px 0 5px;
                                        .k-header-column-menu {
                                            margin-right: -4px;
                                            z-index: 1;
                                            bottom: 0;
                                            height: 100%;
                                        }
                                        .k-grid-filter {
                                            background: transparent;
                                            margin-top: 10px;
                                            margin-right: -4px;
                                            .k-icon {
                                                &:before {
                                                    content: "\E031";
                                                }
                                            }
                                            .k-header-column-menu {
                                                bottom: 0 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.expandFilter {
        &.animatedCollapse {
            .find-order-search-holder {
                animation: showCommonFilterSection 1s forwards;
            }
        }
    }
    &.collapsedFilter {
        &.animatedCollapse {
            .find-order-search-holder {
                animation: hideCommonFilterSection 1s forwards;
            }
        }
        &:not(.animatedCollapse) {
            .find-order-search-holder {
                margin-left: -390px;
            }
        }
        .find-order-search-holder {
            overflow: hidden;
            .filter {
                width: 40px;
                height: 100%;

                .fa-caret-left {
                    transform: rotate(180deg);
                    transition: all 0.4s;
                    -webkit-transition: all 0.4s;
                    -moz-transition: all 0.4s;
                    padding: 13px 17px;
                    @include desktop_1280 {
                        padding: 13px 20px;
                    }
                }
                span {
                    text-transform: uppercase;
                    font-size: 12px;
                    transform: rotate(90deg);
                    display: inline-block;
                    width: 120px;
                    position: absolute;
                    top: 90px;
                    left: -40px;
                }
            }
        }
    }

    .wms-tooltip-content {
        position: fixed;
        top: 105px;
        left: 197px;
        text-align: center;
        padding: 8px 12px;
        background: #ecf5fd;
        min-width: 300px;
        font-size: 14px;
        color: #7d7d7d;
        border: 1px solid #b7d2e5;
        z-index: 2;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
    }
}

#saveCurrentFilter {
    .save-filter-button-wrapper {
        display: inline-flex;
        .wms-button-component {
            .button_holder_proto {
                display: inline-block;
            }
        }
    }
    .wms-area-content {
        margin-top: 15px;
        .wms-area-content-darker {
            padding: 12px;
            .wms-dropdown-component {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}

#allocationResultMessage {
    .model-content-wrapper {
        text-align: center;
        .fault-results-msg {
            div {
                display: flex;
                b {
                    display: flex;
                }
                span {
                    flex: 1;
                    text-align: start;
                    padding-left: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
}
