@import "../../../scss/variable";
@import "../../../scss/mixin";
#navbar-component {
    &.sidenav {
        position: relative;
    }
    .nav-content {
        position: relative;
        top: -1px;
        visibility: visible;
        opacity: 1;
        width: 175px;
        height: 100%;
        z-index: 101;
        padding: 0 0 0px;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        .wms-scroll-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: thin;
            scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
            &::-webkit-scrollbar {
                width: 0.5em;
                height: 0.5em;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 3px;

                &:hover {
                    background: rgba(255, 255, 255, 0.3);
                }
            }
        }
        &.with-texticon-menu {
            .title {
                button.k-primary {
                    &:not(:first-child) {
                        border-left: 1px solid rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }
        &.view-list {
            position: absolute;
            background: #333333;
            top: -1px;
            .title {
                i {
                    font-size: 14px;
                }
                .k-primary:hover,
                .k-primary:active,
                .k-primary:focus {
                    background: transparent;
                }
            }
            .menu-section {
                ul {
                    color: #7a7a7a;
                    li.new-white {
                        color: #a3a3a3;
                        &.active {
                            color: #ffffff;
                            pointer-events: none;
                        }
                        &:hover,
                        &:active,
                        &:focus {
                            background: unset;
                            color: #ffffff;
                        }
                        .spaces-title-section {
                            display: flex;
                            align-items: center;
                            a {
                                padding: 0 9px 0 12px !important;
                            }
                            .wms-badge {
                                width: 36px;
                            }
                        }
                    }
                }
            }
        }
        &.show-view-list {
            width: 175px;
        }
        &.hide-view-list {
            width: 0px;
        }
        .title {
            background-color: var(--secondary);
            width: 100%;
            float: left;
            text-align: left;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 0px;
            h3 {
                line-height: 40px;
                color: white;
                font-weight: bold;
                float: left;
                margin: 0;
                width: calc(100% - 70px);
                padding-left: 12px;
                .alter-menu-icon {
                    float: right;
                    cursor: pointer;
                    border: 1px solid;
                    border-radius: 50%;
                    padding: 0px 6px;
                    line-height: 19px;
                    margin-top: 10px;
                    margin-right: 7px;
                    font-size: 12px;
                }
            }
            button.k-primary {
                float: right;
                height: 40px;
                width: 40px;
                background: rgba(0, 0, 0, 0.2);
                border: none;
                cursor: pointer;
                font-size: 20px;
                color: $submenufont;
                transition: 0.2s ease-in-out;
                -webkit-transition: 0.2s ease-in-out;
                -moz-transition: 0.2s ease-in-out;
                line-height: 0;
                &:hover,
                &:active,
                &:focus {
                    background-color: rgba(0, 0, 0, 0.4);
                    outline: none !important;
                }
            }
            .btn-wrapper {
                display: flex;
                justify-content: flex-end;
            }
        }
        .menu-section {
            width: 100%;
            // height: 100%;
            display: inline-block;
            flex: 1;
            ul {
                margin: 0px;
                padding: 0px;
                list-style-type: none;
                li {
                    width: 100%;
                    float: left;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
                    position: relative;
                    transition: 0.3s ease-in-out;
                    -webkit-transition: 0.3s ease-in-out;
                    -moz-transition: 0.3s ease-in-out;
                    cursor: pointer;
                    &:hover {
                        background: #f0f0f0;
                    }
                    a {
                        text-decoration: none;
                        padding: 0 12px !important;
                        line-height: 40px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        display: block;
                        .k-icon {
                            display: none;
                        }
                        i {
                            width: 20px;
                            display: inline-block;
                            margin-right: 10px;
                            font-size: 14px;
                            text-align: center;
                        }
                        .fa-home {
                            font-size: 17px;
                            position: relative;
                            top: 1px;
                        }
                        .fa-user,
                        .fa-eye {
                            font-size: 16px;
                        }
                    }
                    .title-section {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .wms-badge {
                            position: absolute;
                            right: 10px;
                        }
                    }
                    ul.vertical-dropdown {
                        margin: 0px;
                        padding: 0;
                        z-index: 0;
                        list-style-type: none;
                        display: none;
                        position: fixed !important;
                        margin-top: -1px;
                        height: auto !important;
                        bottom: 0px;
                        top: 100px !important;
                        transition: 0.3s ease-in-out;
                        -webkit-transition: 0.3s ease-in-out;
                        -moz-transition: 0.3s ease-in-out;
                        left: 0px;
                        overflow: auto;
                        li {
                            display: flex;
                            align-items: center;
                            position: relative;
                            width: 100%;
                            border: none !important;
                            margin: 0 auto;
                            padding: 0px 10px;
                            float: none;
                            overflow: hidden;
                            &:hover {
                                background: #e5e5e5;
                            }
                            // border-radius: 5px;
                            // -webkit-border-radius: 5px;
                            // -moz-border-radius: 5px;
                            a {
                                width: 100%;
                                z-index: 1;
                                padding: 0 0 0 5px !important;
                                font-size: 15px;
                                cursor: pointer;
                                transition: 0.3s ease-in-out;
                                -webkit-transition: 0.3s ease-in-out;
                                -moz-transition: 0.3s ease-in-out;
                                i {
                                    width: 20px;
                                    margin-right: 10px;
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                            }
                            .wms-badge {
                                position: absolute;
                                right: 10px;
                            }
                        }
                        li:last-child {
                            margin-bottom: 30px;
                        }
                        li.title {
                            text-align: left;
                            padding: 0px;
                            letter-spacing: 1px;
                            // text-transform: uppercase;
                            font-size: 20px;
                            font-weight: bold;
                            display: flex;
                            .title-text {
                                padding: 5px 15px 5px;
                            }
                            .wms_slideout_title_close,
                            .wms_icon_slideout_title_close {
                                cursor: pointer;
                                color: var(--primary-white);
                                float: right;
                                font-size: 14px;
                                margin-left: auto;
                                background: rgba(0, 0, 0, 0.2);
                                padding: 10px;
                                &:hover {
                                    background: rgba(0, 0, 0, 0.4);
                                }
                            }
                        }
                        li.menu-seperator {
                            pointer-events: none;
                            a {
                                font-size: 20px;
                                font-weight: bold;
                                i {
                                    display: none;
                                }
                            }
                        }
                        scrollbar-width: thin;
                        scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
                        &::-webkit-scrollbar {
                            width: 0.5em;
                            height: 0.5em;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: rgba(255, 255, 255, 0.2);
                            border-radius: 3px;

                            &:hover {
                                background: rgba(255, 255, 255, 0.3);
                            }
                        }
                    }
                }
                li.dropdown {
                    .active {
                        display: block;
                        left: 175px;
                        box-shadow: inset 0px 3px 15px #00000029;
                    }
                }
                li.active-icon {
                    background: #f0f0f0;
                    box-shadow: 5px 0px 0 var(--secondary) inset;
                }
            }
            #verticalMenuWithIcon {
                li {
                    ul.vertical-dropdown {
                        left: 40px;
                    }
                }
            }
        }
        .copyright {
            // position: absolute;
            // bottom: 75px;
            // right: 0;
            // left: 0;
            text-align: center;
            padding: 30px 10px 10px 10px;
            font-size: 9px;
            color: var(--primary-black);
        }
        .div_tooltip {
            position: fixed;
            bottom: 110px;
            left: 0;
            min-width: 231px;
            z-index: 12;
            font-size: 14px;
            padding: 4px 8px;
            background: black !important;
            color: white !important;
            border: 1px solid black !important;
            &:before {
                content: "\A";
                position: absolute;
                top: 16px;
                left: 50px;
                border-style: solid;
                border-width: 11px 11px 0px 20px;
                -webkit-transform: rotate(405deg);
                border-color: transparent black transparent transparent;
            }
        }
    }
    .nav-content.icon-menu {
        .menu-section {
            ul {
                li {
                    a {
                        padding: 0px !important;
                        i {
                            width: 40px;
                            height: 40px;
                            margin-right: 0;
                            display: block;
                            line-height: 40px;
                        }
                    }
                    ul.vertical-dropdown {
                        li {
                            a {
                                i {
                                    display: inline-block;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
                li.active-icon {
                    &:after {
                        top: 13px;
                        border-top: 7px solid transparent;
                        border-right: 8px solid #2c405a;
                        border-bottom: 7px solid transparent;
                    }
                }
            }
        }
    }
}
.wms-customer-user-body {
    #navbar-component {
        .menu-section {
            ul {
                li {
                    ul.vertical-dropdown {
                        top: 60px !important;
                    }
                }
            }
        }
    }
}

.hub-navbar-title {
    background-color: #555555 !important;
}

.hub-collapse-button {
    background-color: #333333 !important;

    &:hover {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
}

.sidenav-hub {
    margin-top: -10px !important;
    height: calc(100% + 10px) !important;
}

#navbar-component .nav-content .menu-section ul li.dropdown .vertical-dropdown-hub {
    top: 40px !important;
    border-left: none !important;
}