@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";

#createHVPBatchModal {
    .model-content-wrapper {
        .section-wrapper-preset {
            display: flex;
            .wms-dropdown-component {
                width: 33%;
                flex: unset;
            }
            .preset-btn-save {
                margin: 18px 0 0 5px;
                .wms_toolbar_button {
                    padding: 0 11px 0 15px;
                }
            }
        }
        .error-message {
            text-align: center;
            padding-bottom: 5px;
            color: red;
        }
        .section-wrapper {
            display: flex;
        }
        .wms-dropdown-component {
            float: none;
            flex: 1;
        }
    }

    .small-parcel-hvp-grid {
        transition-property: width;
        -webkit-transition: all 0.4s linear;
        -moz-transition: all 0.4s linear;
        -o-transition: all 0.4s linear;
        transition: all 0.4s linear;
        overflow: hidden;
        background: $form-boxbg;
        overflow-y: auto;
        padding: 0;
        margin-bottom: 30px;
        .title {
            margin: 0 0 0 2px;
        }
        .wms-toolbar-button {
            float: right;
            display: flex;
            flex-wrap: wrap;
            .refreshSmalParcelHVPGrid {
                .primary_button_color {
                    padding: 0px 16px;
                    .wms_toolbar_button_text {
                        display: none;
                    }
                }
            }
        }
        .k-grid {
            .columnd-header-icon {
                font-size: 12px;
            }
            .facility-exclamator {
                padding-left: 5px;
                color: #d91c03;
            }
            .error-icon-status {
                cursor: pointer;
            }
        }
    }

    .wms-search-grid-data {
        height: 100%;
        margin: 0 30px;
        .wms-virtual-grid-holder {
            margin: 0;
            .wms_area_content {
                .wms_toolbar {
                    flex-wrap: wrap;
                    .button_list {
                        margin-top: 5px;
                    }
                }
                .wms-grid-details {
                    margin: 12px 0 0 0;
                    .grid-right-content {
                        display: inline-block;
                        height: 15px;
                        margin-top: 2px;
                    }
                    .sort {
                        color: $input-color;
                        span {
                            color: $input-color;
                        }
                    }
                }
            }
        }
        .wms_area_content {
            .wms-button-dropdown-component {
                .k-animation-container {
                    ul {
                        min-width: 180px;
                    }
                }
            }
        }
        .wms-grid-area-wrapper {
            max-height: unset;
            .gridContainer {
                .k-grid-header-wrap {
                    table {
                        thead {
                            tr {
                                th {
                                    // padding: 0 30px 0 5px;
                                    .k-header-column-menu {
                                        margin-right: -4px;
                                        z-index: 1;
                                        bottom: 0;
                                        height: 100%;
                                    }
                                    .k-grid-filter {
                                        background: transparent;
                                        margin-top: 10px;
                                        margin-right: -4px;
                                        .k-icon {
                                            &:before {
                                                content: "\E031";
                                            }
                                        }
                                        .k-header-column-menu {
                                            bottom: 0 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .k-grid-content {
                    .k-grid-norecords {
                        justify-content: center;
                        display: flex;
                        margin-top: 30px;
                        height: 18px;
                        font: normal normal normal 13px/18px Open Sans;
                        color: #222222;
                    }
                }
            }
        }
    }
}
