
@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";
.new-carrier-connection-wrapper{
    display: flex;
    flex-direction: column;
    .wms-carrier-connection{
        overflow-y: auto;
        flex: 1;
        &.new-connection-four-elements {
            .wms-collapsible-div{
                .wms-collapsible-content{
                    .new-connection-first-element{ width: 20%; }
                    .new-connection-second-element{ width: 40%; }
                    .new-connection-third-element{ width: 20%; }
                }
            }
        }
        &.new-connection-three-elements {
            .wms-collapsible-div{
                .wms-collapsible-content{
                    .new-connection-first-element{ width: 25%; }
                    .new-connection-second-element{ width: 50%; }
                    .new-connection-third-element{ width: 25%; }
                }
            }
        }
        .wms-collapsible-div{
            .wms-collapsible-content{
                .disabled-facility-opt{
                    opacity: 0.3;
                    cursor: not-allowed;
                    .wms-dropdown-component{
                        select{
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
    .connection-step-block{
        overflow-y: auto;
        flex: 1;
        .image-section {
            margin: auto;
            text-align: center;
            padding-bottom: 10px;
            width: 300px;
            height: 140px;
            max-width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        .default-section {
            padding: 10px 30px;
        }
        .img-amazon {
            width: 240px;
            height: 75px;
        }
        .amazon-info {
            display: flex;
            flex-direction: column;
            .section-label {
                color: #656565;
                font-weight: bold;
                margin: 12px 0 13PX 0;
            }
            .wms-collapsible-div {
                .wms-collapsible-content {
                    .account-info-section {
                        .amazon-auth-token {
                            width: 80%;
                        }
                        .amazon-email {
                            width: 60%;
                        }
                    }
                }
            }
        }
        .wms-collapsible-div{
            border-radius: 4px;
            .wms-collapsible-content{
                .display-inline{
                    display: inline;
                    .ups-first-name{
                        width: 30% !important;
                    }
                }
                .ups-company-name{
                    width: 26% !important;
                }
                .account-info-section{
                    display: flex;
                    flex-direction: column;
                    .w-42 {
                        width: 42%;
                    }
                    .w-50 {
                        width: 50%;
                    }
                    .w-75 {
                        width: 75%;
                    }
                }
                p{
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 5px;
                    color: #7f7f7f;
                    font-weight: 400;
                }
                .pitney-account-info-section {
                    display: block;
                    .wms-input-component {
                        width: 50%;
                    }
                    .wms-checkbox-component {
                        padding-left: 6px;
                    }
                }
                .ups-eula-agreement {
                    p {
                        margin-top: 5px;
                        margin-bottom: 15px;
                    }
                }
                .wms-checkbox-component{
                    display: inline-block;
                    margin-top: 10px;
                }
                .wms-input-component{
                    &.wms-input-names-box{
                        width: 39%;
                    }
                    &.wms-input-title-box{
                        width: 22%;
                    }
                    &.wms-input-address-box{
                        width: 78%;
                    }
                    &.wms-input-address-half-box{
                        width: 50%;
                    }
                    &.wms-input-city-box{
                        width: 39%;
                    }
                    &.wms-input-zip-postal-code-box{
                        width: 20%;
                    }
                    &.wms-input-account-zip-box{
                        width: 25%;
                    }
                }
                .wms-dropdown-component{
                    float: unset;
                    position: unset;
                    display: inline-block;
                    &.wms-dropdown-state-box{
                        width: 15%;
                        display: inline-block;
                    }
                    &.wms-dropdown-country-box{
                        width: 26%;
                        display: inline-block;
                    }
                    &.wms-dropdown-account-country-box{
                        width: 35%;
                    }
                }
                .wms-datetimepicker-component{
                    width: 50%;
                    display: inline-block;
                }
                .wms-invoice-block{
                    .wms-input-component {
                        width: 28%;
                    }
                }
            }
        }
        #dhl-express-au_main {
            .dhlexp-au-nikcname {
                width: 265px;
            }
            .dhlexp-au-accountnumber {
                width: 315px;
            }
        }
        #SekoInformation_main {
            .seko-nikcname {
                width: 250px;
            }
            .seko-accesskey {
                width: 350px;
            }
        }
        #CanadaPost_main {
            .cp-nickname {
                width: 260px;
            }
            .cp-account-number {
                width: 310px;
            }
            .cp-contract-id {
                width: 310px;
            }
        }

        #carrierSettings_main {
            .wms-collapsible-content {
                .dropdown_section, .mail_section {
                    display: flex;
                }
                .radio_section {
                    .wms-checkbox-component {
                        width: 50%;
                        label {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        #FedexEditComponent_main {
            .fedex-edit-nickname {
                width: 300px;
            }
            .fedex-edit-accoutnumber {
                width: 200px;
            }
            .fedex-edit-zip {
                width: 120px;
            }
            .fedex-edit-smart-post-hub {
                width: 172px;
            }
            .fedex-edit-smart-post-endorsment {
                width: 400px;
            }
        }
        #UPSEditComponent_main, #carrierEditInvoiceInformation_main {
            .UPS-edit-nickname {
                width: 300px;
            }
            .UPS-edit-accoutnumber {
                width: 200px;
            }
            .UPS-edit-zip {
                width: 120px;
            }
            .UPS-edit-pickup-type {
                width: 200px;
            }
            .chk-inline {
                display: flex;
                .wms-checkbox-component {
                    margin-left: 5px;
                    margin-right: 10px;
                    padding: 0 0px;
                    label {
                        font-size: 13px;
                    }
                    input {
                        margin: 2px 7px 6px 0;
                    }
                }
            }
            .disabled-checkbox {
                opacity: 0.2;
            }
        }
        #carrierEditInvoiceInformation_main {
            .invoice-info-section {
                display: flex;
                flex-direction: column;
                .wms-checkbox-component {
                    margin-bottom: 20px;
                }
                .wms-invoice-elements {
                    display: flex;
                }
            }
        }
        &.ups-auth-flow-wrapper{
            h3 {
                font-weight: bold;
                color: black;
                margin: 0 0 30px 0;
            }
            .link-expire-alert,
            .link-reset-alert  {
                display: flex;
                padding: 6px 16px 6px 16px;
                background: #FFF4E5;
                justify-content: space-between;
                .alert-text-wrapper {
                    color: #663C00;
                    align-items: center;
                    display: flex;
                    i {
                        width: 16px;
                        display: flex;
                        align-items: center;
                        font-size: large;
                        color: #EF6C00;
                    }
                    span, p, h4 {
                        margin: 0px 0px 0px 10px;
                    }
                    &.expiring-msg,
                    &.reseting-msg {
                        margin-top: 10px;
                    }
                }
                .alert-btn-wrapper {
                    .wms-button-component  {
                        margin: 5px;
                        .orange_button_color {
                            border: 1px solid #FF5100 !important;
                            .wms_sprite,
                            .wms_toolbar_button_text {
                                color: #FF5100 !important;
                            }
                        }
                    }
                }
            }
            .link-reset-alert {
                background: #EDF7DF;
                .fa-check-circle-o {
                    color: #488C18 !important;
                }
                .linked-reset {
                    color: #2B401D;
                    p {
                        olor: #2B401D;
                    }
                }
            }
            p {
                color: black;
            }
            .radio-wrapper {
                display: flex;
                flex-direction: column;
                .alert-wrapper {
                    background: var(--_components-alert-info-background, #E5F6FD);
                    padding: 9px 17px 0px;
                    margin-bottom: 10px;
                    .title{
                        color: #014361;
                        font-weight: 600;
                        i {
                            margin-right: 10px;
                        }
                    }
                    .description {
                        p {
                            margin: 5px 5px 5px 22px;
                            color: #014361;
                        }
                    }
                }
            }
            .connection-ups-wrapper {
                border: 1px solid #bbbbbb;
                padding: 10px 20px 15px 20px;
                border-radius: 10px;
                background: #F5F5F5;
                li {
                    display: flex;
                    .auth-url-wrapper {
                        display: flex;
                        a {
                            padding-left: 3px;
                        }
                    }
                    i {
                        font-size: 5px;
                        padding-top: 8px;
                        padding-right: 5px;
                    }
                    a {
                        border-bottom: 1px solid #008895;
                        color: #008895;
                        display: inline-block;
                        width: 400px;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                    }
                }
            }
            .copy-btn-wrapper {
                padding-top: 15px;
                .copy-link-btn {
                    button {
                        background: white;
                        border: 1px solid;
                        .wms_sprite,
                        .wms_toolbar_button_text {
                            color: black;
                        }
                    }
                }
            }
            .popup-title {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
            }
            li {
                display: list-item;
                list-style-type: disc;
                color: black;
                padding-left: 15px;
                line-height: 15px;

            }
            .check-connection-wrapper {
                padding-top: 15px;
                text-align: center;
                a {
                    border-bottom: 1px solid rgba(0, 112, 122, 1);
                    color: rgba(0, 112, 122, 1);
                    display: inline-block;
                    cursor: pointer;
                }
            }
            .fa-solid, .fa-regular {
                margin-right: 10px;
            }
            .check-connection-loading-alert {
                padding: 16px;
                background: rgba(229, 246, 253, 1);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-align: left;
                color: rgba(1, 67, 97, 1);
            }
            .check-connection-success-alert {
                padding: 16px;
                background: rgba(237, 247, 223, 1);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-align: left;
                color: rgba(43, 64, 29, 1);
            }
            .check-connection-error-alert {
                padding: 16px;
                background: rgba(253, 237, 237, 1);
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.15px;
                text-align: left;
                color: rgba(95, 33, 32, 1);
                h3 {
                    color: rgba(95, 33, 32, 1);
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }
                p {
                    color: rgba(95, 33, 32, 1);
                    margin-left: 32px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
                i {
                    color: #D91C03;
                }
            }
        }


    }
    .wms-button-holder-block{
        display: flex;
        height: 40px;
        z-index: 1;
        justify-content: center;
        .wmsi-button-holder{
            margin: auto;
        }
    }
    .display-inline{
        display: flex;
    }
    .width-40 {
        width: 40%;
    }
    .width-100 {
        width: 70%;
    }
    .pitney-bowes-connection-step-block {
        display: flex;
        flex-direction: column;
        .image-section {
            margin: auto;
            text-align: center;
            padding-bottom: 10px;
            width: 300px;
            height: 140px;
            max-width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        .wms-collapsible-div  {
            flex: 1;
            .wms-collapsible-content{
                padding: 0;
                .title, .pitneyBoweserror {
                    padding: 10px 10px 20px 25px;
                    border-bottom: 1px solid #EEEEEE;
                    .wms-sprite {
                        color: #fcde5f;
                        margin-right: 10px;
                    }
                }
                .pitneyBoweserror {
                    .wms-sprite {
                        color: #D91C03;
                    }
                }
            }
            span {
                font-size: 16px;
                color: #000000;
            }
            .pitney-bowes-middel-wrapper {
                display: flex;
                .pitney-bowes-credentials {
                    display: flex;
                    flex-direction: column;
                    width: 40%;
                    padding: 15px;
                    .wms-input-component {
                        width: 82%;
                    }
                }
            }
            .pitney-bowes-seprator-wrapper{
                background: #EEEEEE;
                width: 2px;
                position: relative;
                .pitney-bowes-seprator-word{
                    position: absolute;
                    background: white;
                    top: 50%;
                    transform: translate(52%,-50%);
                    right: 50%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 13px;
                }
            }
            .pitney-bowes-create-account-btn {
                margin: auto;
            }
        }
    }
}
