.edit-shippinh-info-model-wrapper {
    .model-content-wrapper{
        .display-inline{
            display: flex;
            flex-wrap: wrap;
            padding: 0px 14px 0px 14px;
        }
        .wms-combobox-component {
            .k-dropdown-wrap {
                width: 100%;
                height: 32px;
                font-weight: 600;
                font-size: 14px;
                cursor: pointer;
                position: relative;
                z-index: 10;
                border-radius: 4px;
                input {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
        .wms-input-component {
            .extra-sign {
                top: 25px;
            }
        }
        .disable-options {
            opacity: 0.2;
        }

        #shippingInfoCarrier_main {
            .wms-collapsible-content {
                padding: 17px 0px;
            }
            .ship-info-carrier, .ship-info-service {
                width: 262px;
            }
            .ship-info-SCAC {
                width: 170px;
            }
            .ship-info-billingType {
                width: 160px;
            }
            .ship-info-account-nickname {
                width: 27%
            }
            .ship-info-account-number {
                width: 212px;
            }
            .ship-info-postal-code {
                width: 132px;
            }
            .ship-info-CapacityType {
                width: 225px;
            }
            .disabled-tracking-number {
                input {
                    opacity: 0.2;
                }
                .div_tooltip {
                    top: 55px;
                }
            }
            .ship-info-tracking-number {
                width: 287px;
            }
            .ship-info-ship-date {
                width: 180px;
            }
            .wms-text-area-component {
                padding: 0 6px;
                width: 50%;
                textarea {
                    height: 100px;
                }
            }
        }
        #shippingInfoShipment_main {
            .wms-collapsible-content {
                padding: 0px;
            }
            .wms-checkbox-component {
                padding: 0 6px;
                label {
                    display: flex;
                    align-items: center;
                    margin: 6px 0;
                    .option-input {
                        margin: 0;
                        margin-right: 12px;
                    }
                }
            }
            .chk-return-receipt {
                width: 207px;
            }
            .chk-saturday-delivery {
                width: 167px;
            }
            .chk-residential-delivery {
                width: 184px;
            }
            .chk-insurance {
                width: 113px;
                margin-right: 30px;
            }
            .chk-return-receipt, .chk-saturday-delivery, .chk-residential-delivery {
                margin-right: 46px;
            }

            .display-inline {
                border-bottom: 1px solid #eeeeee;
                padding: 15px 14px 15px 14px;
            }
            .ship-info-insurance-type {
                width: 262px;
            }
            .ship-info-insurance-amount {
                width: 152px;
            }
            .ship-info-dry-ice-weight {
                width: 187px;
            }
            .ship-info-init-content-type {
                width: 212px;
            }
            .ship-info-init-non-delivery {
                width: 262px;
            }
            .ship-info-delivery-confirmation-type {
                width: 282px;
            }
            // .ship-info-COD-type {
            //     width: 200px;
            // }
            // .chk-cod {
            //     width: 200px;
            // }
            .chk-margin-top {
                margin-top: 20px;
            }
        }
        #internationalShippingTaxInformation_main {
            .service-rate-options {
                display: flex;
                align-items: center;
                .wms-radio-wrapper {
                    width: 35%;
                }
                .identifier-type-wrapper {
                    width: 20%;
                }
                .tax-number-value-wrapper {
                    width: 20%;
                }
                .issuing-authority-wrapper{
                    width: 15%;
                }
                .remove-tax-identifiers-btn-wrapper{
                    width: 10%;
                    margin-bottom: 13px;
                }
                .wms-label {
                    font-size: 10px;
                    line-height: 1.2;
                    margin-left: 1px;
                    color: #222222;
                    font-weight: normal;
                    display: inline-block;
                    width: 100%;
                    padding: 0 6px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .wms_toolbar_button {
                padding: 0 12px 0 15px;
            }
        }
    }
}