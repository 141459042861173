@import "../../../scss/variable";
@import "../../../scss/mixin";
#wms-smallParcel-virtual-wrapper {
    height: 100%;
    width: 100%;
    .k-loading-mask {
        background-color: rgba(255, 255, 255, 0.8) !important;
    }
    &.small-Parcel-holder {
        // .sectionToggled {
            // animation: showCommonFilterSection 1s forwards;
        // }
        .small-parcel-search-holder {
            height: 100%;
            position: relative;
            padding: 30px 10px 0 30px;
            .filter {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                border-radius: 0px;
                background: $tabel-headbg;
                border: 0px;
                z-index: 1;
                cursor: pointer;
                .fa-caret-left {
                    font-size: 16px;
                    padding: 4px 8px;
                }
                .fa-caret-left:before {
                    color: #527999;
                }
                &:hover {
                    background: #0079c4;
                    .fa-caret-left:before,
                    span {
                        color: #fff;
                    }
                }
                span {
                    display: none;
                }
            }
            .small-parcel-search-filter {
                height: 100%;
                border-right: none;
                transition: all 0.4s linear;
                overflow: hidden;
                display: inline-flex;
                flex-direction: column;
                -webkit-transition: all 0.4s ease-in;
                -moz-transition: all 0.4s ease-in;
                -o-transition: all 0.4s ease-in;
                transition: all 0.4s ease-in;
                margin-left: 0px;
                // &.expand {
                //     transform: unset;
                //     margin-left: -396px;
                //     -webkit-transition: all 0.4s ease-in;
                //     -moz-transition: all 0.4s ease-in;
                //     -o-transition: all 0.4s ease-in;
                //     transition: all 0.4s ease-in;
                //     // @include desktop_1680 {
                //     //     margin-left: -340px;
                //     // }
                //     .collapse-box {
                //         position: absolute;
                //     }
                // }
                .title {
                    position: absolute;
                    top: 68px;
                    label {
                        color: $filter-note-title !important;
                        font-size: 14px;
                        letter-spacing: 1px;
                        font-weight: 600;
                    }
                }
                .wms-toolbar {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 25px;
                    padding-right: 20px;
                }
                .disable-section {
                    opacity: 0.2;
                    pointer-events: none;
                }
                #search_default_collapse_main {
                    width: unset;
                    .wms-dropdown-component {
                        width: 100%;
                    }
                }
                .smallparcel-form-boxes-wrapper {
                    position: relative;
                    width: 100%;
                    overflow: auto;

                    .smallparcel-form-boxes {
                        transition: all ease-in-out 0.3s;
                        overflow: hidden;
                        margin-right: 20px;
                        .smallparcel-area-content {
                            // display: inline-block;
                            border-radius: 4px;
                            -webkit-border-radius: 4px;
                            -moz-border-radius: 4px;
                            .title {
                                width: 100%;
                                float: left;
                                margin: 0 0 8px 0;
                                label {
                                    color: $form-title !important;
                                    font-size: 11px;
                                    text-transform: uppercase;
                                    letter-spacing: 1px;
                                    font-weight: 600;
                                }
                            }
                            .wms-collapsible-div {
                                .wms_collapsible_header {
                                    .wms-title {
                                        padding: 5px 12px 0 20px;
                                    }
                                }
                                .wms-collapsible-content {
                                    margin-bottom: 0;
                                    // padding: 15px 5px 5px;
                                    .wms-dropdown-component {
                                        width: 100%;
                                    }
                                    .wms-kendo-dropdown-list-component {
                                        width: 100%;
                                        flex-direction: row;
                                        .form_group {
                                            float: left;
                                            position: relative;
                                            padding: 0px 7px;
                                            margin-bottom: 11px;
                                        }
                                    }
                                    .add-clear-icon {
                                        .wms-kendo-dropdown-list-component {
                                            .k-dropdown {
                                                width: calc(100% - 28px);
                                            }
                                        }
                                    }
                                    ul#MultiSelectCustomerSkus_taglist {
                                        position: absolute;
                                    }
                                    .wms-datetimepicker-component {
                                        .border {
                                            width: 100%;
                                        }
                                    }
                                    .wms-input-component {
                                        width: 100%;
                                    }
                                    .wms-multiselect-input-wrapper {
                                        .wms-kendo-dropdown-update-list-component {
                                            button {
                                                padding: 0;
                                                width: auto;
                                                height: 20px;
                                                justify-content: center;
                                                padding-left: 6px;
                                                padding-right: 6px;
                                                .wms_sprite {
                                                    width: 12px;
                                                    height: 12px;
                                                    font-size: 12px;
                                                }
                                                .wms_toolbar_button_text {
                                                    margin: 0px 9px 0px 4px;
                                                    font-size: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .form-group {
                                width: 100%;
                                float: left;
                                padding: 0px;
                                margin-bottom: 15px;
                            }
                            .wms_area_border {
                                height: 100%;
                                overflow: hidden;
                                padding: 0 0 26px 0;
                                #gridContainer {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
                .filter-list-block {
                    padding-bottom: 8px;
                    margin-top: -4px;
                    li.filter-list {
                        display: flex;
                        height: 48px;
                        margin: 0px 7px 12px;
                        background-color: transparent;
                        border-radius: 4px !important;
                        padding: 13px 5px 13px 12px;
                        border: 1px solid #bbbbbb;
                        a.title-text {
                            color: $input-color;
                            position: relative;
                            white-space: nowrap;
                            width: 100px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;
                        }
                        .wms-apply-btn {
                            display: flex;
                            margin: -6px 3px;
                        }
                        .clear-save-filter {
                            padding: 2px 5px;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;
                            i {
                                font-size: 16px;
                                color: rgba(255, 0, 0, 0.2);
                                transition: all 0.3s ease-in-out;
                            }
                            &:hover {
                                color: #2c4059;
                                i {
                                    color: rgba(255, 0, 0, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        .small-parcel-grid {
            transition-property: width;
            -webkit-transition: all 0.4s linear;
            -moz-transition: all 0.4s linear;
            -o-transition: all 0.4s linear;
            transition: all 0.4s linear;
            overflow: hidden;
            background: $form-boxbg;
            overflow-y: auto;
            padding: 0;
            margin-bottom: 30px;
            .title {
                margin: 0 0 0 2px;
            }
            .wms-toolbar-button {
                float: right;
                display: flex;
                flex-wrap: wrap;
                .refreshSmalParcelGrid {
                    .wms_toolbar_button {
                        padding: 0px 16px;
                        .wms_toolbar_button_text {
                            display: none;
                        }
                    }
                }
            }
        }
        .order-summary-opened {
            width: 46%;
            border-right: 1px solid $seprator-color;
        }
        .order-form {
            position: relative;
            flex: 1;
            overflow: hidden;
            padding-left: 15px;
        }

        .search-hover-tooltip {
            position: absolute;
            top: 86px;
            left: 0;
            right: 0;
            background-color: #000000;
            color: white;
            font-size: 12px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 3px;
            padding: 8px;
            margin: 0 15px;
            z-index: 12;
            &::after {
                content: "\A";
                position: absolute;
                top: -10px;
                left: 50%;
                border-style: solid;
                border-width: 6px 6px 0 0px;
                border-color: #000000;
                -webkit-transform: rotate(-135deg);
                width: 10px;
                height: 10px;
                transform: rotate(-45deg) translateX(-50%);
                z-index: -1;
            }
        }
        .wms-search-grid-data {
            height: 100%;
            margin: 0 30px;
            .wms-virtual-grid-holder {
                margin: 0;
                .wms_area_content {
                    .wms_toolbar {
                        flex-wrap: wrap;
                        .button_list {
                            margin-top: 5px;
                        }
                    }
                    .wms-grid-details {
                        margin: 12px 0 0 0;
                        // padding: 5px 13px 5px 11px;
                        .grid-right-content {
                            display: inline-block;
                            height: 15px;
                            margin-top: 2px;
                        }
                        .sort {
                            color: $input-color;
                            span {
                                color: $input-color;
                            }
                        }
                    }
                }
            }
            .wms_area_content {
                .wms-button-dropdown-component {
                    .k-animation-container {
                        ul {
                            min-width: 180px;
                        }
                    }
                }
            }
            .wms-grid-area-wrapper {
                max-height: unset;
                .gridContainer {
                    .k-grid-header-wrap {
                        table {
                            thead {
                                tr {
                                    th {
                                        // padding: 0 30px 0 5px;
                                        .k-header-column-menu {
                                            margin-right: -4px;
                                            z-index: 1;
                                            bottom: 0;
                                            height: 100%;
                                        }
                                        .k-grid-filter {
                                            background: transparent;
                                            margin-top: 10px;
                                            margin-right: -4px;
                                            .k-icon {
                                                &:before {
                                                    content: "\E031";
                                                }
                                            }
                                            .k-header-column-menu {
                                                bottom: 0 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .k-grid-content {
                        .k-grid-norecords {
                            justify-content: center;
                            display: flex;
                            margin-top: 30px;
                            height: 18px;
                            font: normal normal normal 13px/18px Open Sans;
                            color: #222222;
                        }
                    }
                }
            }
        }
    }
    &.expandFilter {
        &.animatedCollapse {
            .small-parcel-search-holder {
                animation: showCommonFilterSection 1s forwards;
            }
        }
    }
    &.collapsedFilter {
        &.animatedCollapse {
            .small-parcel-search-holder {
                animation: hideCommonFilterSection 1s forwards;
            }
        }
        &:not(.animatedCollapse) {
            .small-parcel-search-holder {
                margin-left: -390px;
            }
        }
        .small-parcel-search-holder {
            overflow: hidden;
            .wms-tooltip-content {
                position: fixed;
                top: 105px;
                left: 197px;
                text-align: center;
                padding: 8px 12px;
                background: #ecf5fd;
                min-width: 300px;
                font-size: 14px;
                color: #7d7d7d;
                border: 1px solid #b7d2e5;
                z-index: 2;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03),
                    0 4px 5px 0 rgba(0, 0, 0, 0.04);
            }
            .filter {
                width: 40px;
                height: 100%;

                .fa-caret-left {
                    transform: rotate(180deg);
                    transition: all 0.4s;
                    -webkit-transition: all 0.4s;
                    -moz-transition: all 0.4s;
                    padding: 13px 17px;
                    @include desktop_1280 {
                        padding: 13px 20px;
                    }
                }
                span {
                    text-transform: uppercase;
                    font-size: 12px;
                    transform: rotate(90deg);
                    display: inline-block;
                    width: 120px;
                    position: absolute;
                    top: 90px;
                    left: -40px;
                }
            }
        }
    }

    .wms-tooltip-content {
        position: fixed;
        top: 105px;
        left: 197px;
        text-align: center;
        padding: 8px 12px;
        background: #ecf5fd;
        min-width: 300px;
        font-size: 14px;
        color: #7d7d7d;
        border: 1px solid #b7d2e5;
        z-index: 2;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03),
            0 4px 5px 0 rgba(0, 0, 0, 0.04);
    }
}

#saveCurrentFilter {
    .save-filter-button-wrapper {
        display: inline-flex;
        .wms-button-component {
            .button_holder_proto {
                display: inline-block;
            }
        }
    }
    .wms-area-content {
        margin-top: 15px;
        .wms-area-content-darker {
            padding: 12px;
            .wms-dropdown-component {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
}

#allocationResultMessage {
    .model-content-wrapper {
        text-align: center;
    }
}

.tracking-url {
    color: #1B76FF !important;
    text-decoration: underline !important;
    font-size: 14px;
    font-family: Inter Regular;
}

.rate-details {
    color: #1B76FF !important;
    text-decoration: underline !important;
    font-size: 16px;
    cursor: pointer;
}
