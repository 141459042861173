@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-button-component {
    display: inline-flex;
    margin: 0 12px 6px 0;
    .button_holder {
        position: relative;
        .wms_toolbar_button {
            border: none;
            cursor: pointer;
            width: auto;
            padding: 0 20px 0 15px;
            border-radius: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            transition: 0.2s ease-in-out;
            -wekbit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            height: 32px;
            white-space: nowrap;
            &.wms-disabled {
                opacity: 0.2;
                cursor: not-allowed !important;
                pointer-events: all !important;
            }
            .wms_sprite {
                width: 14px;
                height: 14px;
                font-size: 15px;
                color: $primary-white;
                display: inline-block;
                line-height: 13px;
            }

            .wms_toolbar_button_text {
                display: inline-block;
                font-size: 14px;
                color: $submenufont;
                line-height: 20px;
                font-weight: bold;
                margin-left: 6.25px;
            }
        }

        &.disabled_button {
            .wms_toolbar_button {
                pointer-events: none;
                opacity: 0.2;
            }
        }
        button[disabled] {
            opacity: 0.6;
        }
        .textSpace {
            padding: 0 10px;
        }
        .div_disable {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: not-allowed;
            height: 40px;
            display: inline-block;
        }
        .wms-button-tooltip {
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            background-color: #000000;
            color: white;
            font-size: 12px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            padding: 8px;
            border-radius: 3px;
            min-width: 180px;
            z-index: 12;
            &:before {
                content: "\A";
                position: absolute;
                top: -10px;
                left: 15%;
                border-style: solid;
                border-width: 6px 6px 0 0px;
                border-color: #000000;
                -webkit-transform: rotate(-135deg);
                width: 10px;
                height: 10px;
                transform: rotate(-45deg) translateX(-50%);
                z-index: -1;
            }
        }

        .primary_button_color {
            background: var(--primary-themecolor);
            pointer-events: all;
            &:hover,
            &:active,
            &:focus {
                background: var(--primary-hovered);
            }
            &.wms_disabled_look {
                opacity: 0.2;
                cursor: not-allowed !important;
                pointer-events: none;
            }
        }
        .blue_button_color {
            background: $resetbtn-bg;
            pointer-events: all;
            &:hover,
            &:active,
            &:focus {
                background: $resetbtn-bg-hover;
            }
            &.wms_disabled_look {
                opacity: 0.2;
                cursor: not-allowed !important;
                pointer-events: none;
            }
        }
        .yellow_button_color {
            background: $yellowbtn;
            &:hover,
            &:active,
            &:focus {
                background: $yellowbtn-hover;
            }
        }

        .red_button_color {
            background: $redbtn;
            &:hover,
            &:active,
            &:focus {
                background: $redbtn-hover;
            }
            &.wms_disabled_look {
                opacity: 0.2;
                cursor: not-allowed !important;
                pointer-events: none;
            }
        }

        .orange_button_color {
            border: 1px solid $orangebtn;
            background: none;
            &:hover,
            &:active,
            &:focus {
                border: 1px solid $orangebtn-hover;
                background-color: rgba(0, 0, 0, 0.02);
            }

            & > div {
                color: $orangebtn !important;
            }
        }

        .outlined_button_color {
            border: 1px solid var(--default-border);
            background: none;
            &:hover,
            &:active,
            &:focus {
                background-color: rgba(0, 0, 0, 0.08);
            }

            & > div {
                color: var(--primary-black) !important;
            }
        }

        .black_button_color {
            background: $blackbtn;
            &:hover,
            &:active,
            &:focus {
                background: $blackbtn-hover;
            }
        }

        // css for wms_button_disabled button
        .wms_button_disabled {
            cursor: not-allowed !important;
        }

        button.wms_button_disabled.primary_button_color {
            opacity: 0.2;
            cursor: not-allowed !important;
            pointer-events: none;
            &:hover,
            &:active,
            &:focus {
                background: $greenbtn !important;
            }
        }

        button.wms_button_disabled.yellow_button_color {
            opacity: 0.2;
            cursor: not-allowed !important;
            pointer-events: none;
            &:hover,
            &:active,
            &:focus {
                background: $yellowbtn !important;
            }
        }

        button.wms_button_disabled.red_button_color {
            opacity: 0.2;
            cursor: not-allowed !important;
            pointer-events: none;
            &:hover,
            &:active,
            &:focus {
                background: $redbtn !important;
            }
        }
        button.wms_button_disabled.orange_button_color {
            opacity: 0.2;
            cursor: not-allowed !important;
            pointer-events: none;
            &:hover,
            &:active,
            &:focus {
                background: $orangebtn !important;
            }
        }

        button.wms_button_disabled.outlined_button_color {
            opacity: 0.4;
            cursor: not-allowed !important;
            pointer-events: none;
            &:hover,
            &:active,
            &:focus {
                background: none !important;
            }
        }

        button.wms_button_disabled.black_button_color {
            opacity: 0.2;
            cursor: not-allowed !important;
            pointer-events: none;
            &:hover,
            &:active,
            &:focus {
                background: $blackbtn !important;
            }
        }
    }
}
