@import "../../../scss/variable";
@import "../../../scss/mixin";


.item-storage-charge-wrapper{
    .general-info-wrapper{
        display: flex;
        .chk-wrapper{
            padding-top: 18px;
            display: flex;
            .wms-checkbox-component{
                padding-right: 9px;
                padding-right: 5px;
                .option-input{ 
                    margin: 6px 5px 6px 0;
                }
            }
        }
    }
    .wms-dropdown-component {
        width: 33.3%;
    }
    .number-input-wrapper{
        width: 13%;
    }
    .wms-input-component{
        &.disable-repeat-icon{
            .wms-search-input-icon{
                opacity: 0.5;
                pointer-events: none;
            }
        }
        .wms-search-input-icon{
            i{
                color: #0791de;
            }
        }
    }
    .disable-section{
        opacity: 0.5;
        pointer-events: none;
    }
}