@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-combobox-component {
    width: 100%;
    float: left;
    padding-left: 7px;
    flex-wrap: wrap;
    padding: 0px 6px;
    margin-bottom: 8px;
    .label {
        @include label;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .k-combobox {
        height: 32px;
        width: 100%;
        background-color: transparent;
        color: #222222;

        .k-dropdown-wrap {
            background-color: $primary-white!important;
            border: 1px solid $border-color !important;
            outline: none !important;
            box-shadow: none !important;
            color: $input-color !important;
            border-radius: 4px;

            .k-input {
                padding-top: 0px;
                font-weight: 600;
                font-size: 14px;
                padding: 0 10px;
            }
            .k-clear-value {
                height: 30px;
                top: 1px;
                right: 22px;
            }
            .k-select{
                background: transparent;
            }
        }
    }

    .wms_required_input {
        border: 1px solid $requireinput-border !important;
        &:focus {
            border-color: $primary-themecolor !important;
        }
    }
}