#itemsUpdateModal {
    .model-content-wrapper {
        .wms-collapsible-div {
            .wms-collapsible-content {
                padding: 0;
                hr {
                    border: 0;
                    border-top: 1px solid #eeeeee;
                }
            }
        }
        #itemsUpdateOptions_main {
            .wms-collapsible-content {
                .wms-warning-wrapper {
                    border-bottom: 1px solid #eeeeee;
                    color: #222222;
                    .wms-sprite {
                        margin: 10px 10px 0px 15px;
                    }
                }
                .file-import-section {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 20px;
                    .wms-file-component {
                        width: unset;
                        #import-file-csv {
                            width: calc(100% - 26px);
                        }
                        .wms-filename-text {
                            display: none;
                        }
                    }
                }
                .selected-file-section {
                    margin: 20px;
                    font-size: 11px;
                    label {
                        margin-right: 5px;
                        color: #222222;
                        font-weight: 700;
                    }
                }
            }
        }
        #expectedDataChanges_main {
            .wms-collapsible-content {
                padding: 0;
                .changed_item {
                    padding: 20px;
                    &:not(:last-child) {
                        border-bottom: 1px solid #eeeeee;
                    }
                    .item_field {
                        color: #222222;
                        td {
                            &:first-child {
                                padding-right: 30px;
                                font-weight: bold;
                            }
                        }
                    }
                }
                .no_change_section {
                    display: flex;
                    padding: 10px;
                    justify-content: center;
                }
            }
        }
    }
}
