@import "./../../scss/variable";
@import "./../../scss/mixin";

#item-alias-page-wrapper {
    width: 100%;
    padding: 25px;
    .customer-required {
        color: #D91C03;
        font-size: 14px;
        letter-spacing: 0.5px;
        font-weight: 600;
        width: 100%;
        position: fixed;
        margin: 0;
        margin-top: 4px;
        margin-left: 300px;
    }
    #item-alias-button-area {
        float: right;
        margin-bottom: 10px;
    }

    #item-alias-grid-area {
        .wms-collapsible-content {
            .item-alias-grid-filter-area {
                display: flex;
                width: 70%;
                // margin-bottom: -75px;
                margin-left: 15px;

                .wms-dropdown-component {
                    width: 40%;
                }
                .wms-checkbox-component {
                    padding-top: 18px;
                }
                .wms-input-component.read-icon {
                    .wms-search-input-icon{
                        color: red;
                    }

                }
            }
            .item-alias-grid-div {
                height: 100%;
                .item-alias-refresh-btn{
                    .primary_button_color{
                        padding: 0px 16px;
                        .wms_toolbar_button_text {
                            display: none;
                        }
                    }
                }
                .k-grid-content,
                .k-grid-content-locked {
                    table {
                        tbody {
                            tr {
                                td {
                                    border-bottom: 0;
                                }
                                &.wms-alias-inactive {
                                    td {
                                        color: rgba(244, 137, 49, 1) !important;

                                        .status-warning-icon {
                                            margin-left: 10px;
                                            color: rgba(244, 137, 49, 1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#CreateItemAliasModel{
    .model-content-wrapper{
        .is-active-wrapper{
            margin-top: 18px;
            margin-left: 20px;
        }
        .label-item-in-this-alises {
            font-weight: 700;
            font-size: 14px;
            color: #000000;
            padding: 0 6px;
        }
        .hide-label {
            label {
                display: none;
            }
        }
        #ItemAliasAddModelselectedItemGrid{
            .k-grid-content {
                table{
                    .remove-selected-item {
                        position: absolute;
                        right: 25px;
                    }
                }
            }
        }
    }
}
#ItemAliasImportModel {
    .template-upload-wrapper {
        display: flex;
        flex-direction: column;
        .import-file-content-wapper{
            .import-file-name-and-size{
                background-color: #FAFAFA;
                padding: 4px;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                span{
                    padding-left: 5px;
                    i {
                        margin: 0 5px;
                    }
                }
            }
            .import-parse-grid-header {
                padding: 20px 0px 0px;
                .title {
                    font-size: 16px;
                    font-weight: 800;
                    color: #222222;
                    margin-bottom: 20px;
                }
                .checkbox-area {
                    margin: 20px 0px 10px;
                    height: 20px;
                }
            }
            .import-parse-grid-container {
                .duplicate-error-message {
                    width: 100%;
                    text-align: center;
                    color: #EF6C00;
                }
                .detailTemplate{
                    .wms-form-grid-holder {
                        margin-top: 0px;
                        padding-bottom: 0px;
                        .form-grid {
                            .k-grid-content {
                                height: auto;
                            }
                        }
                    }
                }
                #ItemAliasImportParseGridDetailWapper{
                    .k-grid {

                        thead {
                            display: none !important;
                        }
                        border: none;
                        .k-grid-header {
                            border: none;
                        }
                        .k-grid-content {
                            overflow: hidden;
                            tbody {
                                td {
                                    color: #00000061;
                                    label {
                                        width: 100%;
                                        display: flex;
                                        padding-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                .k-grid-content,
                .k-grid-content-locked {
                    table {
                        tbody {
                            tr {
                                td {
                                    .status-warning-icon {
                                        margin-left: 10px;
                                        color: #EF6C00;
                                        font-size: 1em;
                                        align-self: flex-end;
                                        position: absolute;
                                        right: 10px;

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }



        .import-form-area{
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
            label {
                margin-bottom: 5px;
            }

            .import-area{
                border: 2px dashed #ccc;
                border-radius: 5px;
                padding: 10px;
                .import-drag-area{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0px 0px 15px;
                    position: relative;
                    &.is-dragged {
                        border: 2px dashed #005ca8;
                    }
                    .file-icon-and-name{
                        padding-top: 25px;
                        display: flex;
                        align-items: center;
                    }
                    .wms-file-component {
                        position: absolute;
                        width: 100%;
                        height: 80%;
                        opacity: 0;
                        div{
                            height: 100%;
                            input{
                                height: 100%;
                            }
                        }
                    }
                    i {
                        font-size: 50px;
                        margin-bottom: 15px;
                        color: #bbb;
                    }
                    hr {
                        width: 50%;
                    }


                }
                .button-area{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;

                }
            }
        }
    }
}

.display-inline {
    display: flex;
    padding: 0 15px;
}
.k-grid-header{
    padding: 0px !important;
}
.width-100 {
    width: 100%;
}
.width-90 {
    width: 90%;
}
.width-80 {
    width: 80%;
}
.width-70 {
    width: 70%;
}
.width-60 {
    width: 60%;
}
.width-50 {
    width: 50%;
}
.width-40 {
    width: 40%;
}
.width-20 {
    width: 20%;
}
.width-30 {
    width: 30%;
}
.width-25 {
    width: 25%;
}
.hide {
    display: none !important;
}

.wms-button-component.noIconButton .button_holder .wms_toolbar_button .wms_toolbar_button_text
{
    margin: 0;
}
.k-grid-content-locked + .k-grid-content {
    width: calc(100% - 60px) !important;
}
.k-grid-content {
    padding-right: 0px !important;
}
