@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";
.k-window-maximized {
    .shipAndClose{
        .model-content-wrapper {
            .wms-collapsible-content {
                .wms-form-grid-holder {
                    .k-grid {
                        .k-grid-content {
                            height: auto !important;
                        }
                    }
                }

            }
        }
    }
}

.shipAndClose {
    .wms-area-content-darker.without-bg {
        padding: 0;
        border: none;
        background: transparent;
        margin: 10px 0 0;

        .form_group {
            padding: 0px;
            margin: 0px;
            float: right;

            &:before {
                left: 0;
                right: 0;
            }

            label {
                left: 0px;
            }
        }

        .wms-datetimepicker-component {
            float: left;

            &:before {
                right: 30px !important;
            }
        }
    }

    .gridContainer {
        width: 100%;
        float: left;

        .title {
            margin-top: 10px;
        }

        .k-grid.k-display-block {
            width: 100%;
            float: left;
        }
    }


    .wms-ship-close-title{
        .showHeader{
            .wms-form-grid-component {
                .form-grid{
                    margin: 0;
                    .k-grid-content {
                        height: auto !important;
                    }
                }
            }
        }
    }

    .wms-datetimepicker-component {
        padding: 0;
        display: inline-block;
        width: 100%;

        .border {
            width: 33.33%;
            padding: 0px;

            &::before {
                left: 0;
                right: 30px;
                background-color: #fff;
            }
            label{
                left: 0px;
            }
        }
    }

    .wms-ship-close-title {
        .title {
            label {
                color: #5a7e9d;
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 600;
            }
        }
        // .showHeader {
        //     .wms-form-grid-component  {
        //         display: none;
        //     }
        // }
    }
    .model-content-wrapper {
        .wms-collapsible-div {
            margin: 0;
            .wms-form-grid-holder {
                margin-top: 0px;
            }
            .input-holder{
                display: flex;
                justify-content: flex-end;
                .wms-input-component {
                    padding: 0;
                }

            }
        }
    }
}