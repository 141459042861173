@import "../../../scss/variable";
@import "../../../scss/mixin";

#items-holder-react {
    width: 100%;
    height: 100%;
    display: flex;
    background: #fafbfc;
    padding: 0 !important;
    .items-grid {
        width: 688px;
        height: 100%;
        padding: 30px;
        background: rgba(0, 0, 0, 0.03);
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        .wms-button-component {
            display: inline-flex;
        }
        .wms-collapsible-div {
            margin: 25px 0 20px;
            .wms-input-group-wrapper {
                width: 50%;
                .wms-dropdown-component{
                    width: 50%;
                }
            }
            .form-and-detail-middle {
                display: flex;
                align-items: flex-end;
                width: 100%;
                padding: 8px 0;
                margin: 0;
                .wms-checkbox-component{
                    margin-bottom: 15px;
                }
            }

            .wms-dropdown-component {
                margin-bottom: 0px;
            }
        }
        .wms-grid-wrapper {
            .wms-grid-toolbar {
                .wms-grid-checkbox-content {
                    margin-right: 10px;
                }
                .wms-toolbar-button {
                    .wms-button-component {
                        margin: 0 8px 10px 0;
                    }
                }
            }
            .wms-grid-holder {
                padding-bottom: 30px;
            }
        }
    }
    .items-content-wrapper {
        position: relative;
        flex: 1;
        width: 53.5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .item-form-header-group {
            display: flex;
            width: 100%;
            align-items: center;
            // margin-bottom: 8px;
            flex-wrap: wrap-reverse;
            justify-content: space-between;
            // padding: 20px 20px 10px 30px;
            padding: 15px 30px 14px 30px;
            .form-button-group {
                margin: 15px 0;
                .wms-button-component {
                   margin: 0;
                   margin-left: 10px;
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
            .tab-listing {
                margin: 15px 0;
                ul{
                    margin-top: 0px;
                    li{
                        label{
                            @include desktop_1024{
                                padding: 16px 10px;
                                width: 105px;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .tab-content {
            overflow: auto;
            padding: 0 14px 0px 30px;
            flex: 1 1 auto;
            .collapseItemsTabContent_wrapper{
                .wms-collapsible-div{
                    border-radius: 4px;
                    background: white;
                    box-shadow: 0 1px 5px rgba(159, 159, 159, 0.5);
                    border: none;
                    .wms-collapsible-content{
                        background: transparent;
                        overflow-y: auto;
                        .wms-form-grid-holder{
                            padding: 0 5px;
                        }
                        .wms-input-component {
                            &.wms-input-sell-price-box{
                                width: 16.5%;
                                @include desktop_1280 {
                                    width: 25%;
                                }
                            }
                            &.wms-input-description-box{
                                width: 35%;
                                @include desktop_1280 {
                                    width: 50%;
                                }
                            }
                            &.wms-input-min-on-hand-box{
                                width: 20%;
                            }
                            &.wms-input-storage-temperature-box{
                                 width: 15%;
                            }
                            &.wms-input-harmonized-tariff-box{
                                width: 165px;
                                max-width: 100%;
                                @include desktop_1024{
                                    width: 50%;
                                }
                            }
                            &.upc-input-wrapper{
                                width: 200px;
                                &.disabled-upc {
                                    label {
                                        opacity: 0.2;
                                    }
                                    input {
                                        opacity: 0.2;
                                    }
                                }
                                .div_tooltip {
                                    top: 60px;
                                }
                            }
                            &.secondary-upc-input-wrapper{
                                .div_tooltip {
                                    top: -45px;
                                    bottom: 40px;
                                    &:before{
                                        -webkit-transform: rotate(45deg);
                                        top: 41px;
                                    }
                                }
                            }

                            &.wms-input-total-area-box,
                            &.wms-input-total-volume-box,
                            &.wms-input-weight-box {
                                width: 100px;
                            }
                            &.wms-input-primary-box{
                                width: 140px;
                                max-width: 100%;
                                @include desktop_1280{
                                    width: 110px;
                                }
                            }
                            &.wms-input-height-width-length-box{
                                width: 66px;
                                max-width: 100%;
                            }
                            &.wms-input-total-and-weight-box{
                                width: 85px;
                                max-width: 100%;
                            }
                            &.wms-input-eaches-per-each-box{
                                width: 180px;
                                max-width: 100%;
                                @include desktop_1440{
                                    width: 100px;
                                }
                            }
                            &.wms-input-units-per-box{
                                width: 80px;
                                max-width: 100%;
                            }
                            &.wms-input-min-charge-box{
                                width: 95px;
                                max-width: 100%;
                            }
                            &.wms-input-months-box{
                                width: 140px;
                                max-width: 100%;
                            }
                            &.wms-input-material-box{
                                width: 190px;
                                max-width: 100%;
                                display: inline-block;
                                @include desktop_1024 {
                                    width: 145px;
                                }
                                @include desktop_1280 {
                                    width: 150px;
                                }
                            }
                            &.wms-input-nmfc-description-box{
                                width: 290px;
                                max-width: 100%;
                                display: inline-block;
                                @include desktop_1024 {
                                    width: 245px;
                                }
                                @include desktop_1280 {
                                    width: 250px;
                                }
                            }
                            &.wms-input-quantity-box{
                                width: 80px;
                                max-width: 100%;
                                display: inline-block;
                            }
                            &.wms-input-class-flashpoint-box{
                                width: 50%;
                            }
                            &.invalid-input{
                                input {
                                    border: 1px solid $requireinput-border !important;
                                }
                            }

                        }
                        .wms-checkbox-component {
                            float:none;
                            &.IsPrepackaged {
                                height: 25px;
                                .tooltip-wrapper {
                                    margin-top: 25px;
                                }
                            }
                        }
                        .IsPrepackaged_description_text {
                            font-size: 12px;
                            font-weight: 400;
                            color: #000000;
                            margin-left: 40px;
                            &.disabled {
                                opacity: 0.3;
                            }
                        }
                        .wms-dropdown-component{
                            &.wms-dropdown-ification-box{
                                width: 29.5%;
                                @include desktop_1280 {
                                    width: 50%;
                                }
                            }
                            &.wms-dropdown-allocation-method-box{
                                width: 45%;
                            }
                            &.wms-dropdown-cycle-count-type-box{
                                width: 32%;
                                @include desktop_1280{
                                    width: 28%;
                                }
                            }
                            // Tracking Options
                            &.wms-dropdown-tracking-box {
                                width: 35%;
                                max-width: 100%;
                            }
                            &.wms-outbound-mobile-box {
                                 width: 40%;
                                 @include desktop_1024{
                                    width: 35%;
                                 }
                            }
                            // Tracking Options
                            &.wms-dropdown-shipping-documentation-box{
                                width: 45%;
                                @include desktop_1024{
                                    width: 50%;
                                }
                                @include desktop_1366{
                                    width: 31%;
                                }
                            }
                            &.wms-dropdown-shipping-nmfc-box{
                                width: 165px;
                                @include desktop_1024{
                                    width: 50%;
                                }
                                @include desktop_1280{
                                    width: 140px;
                                }
                            }
                            &.wms-dropdown-packaging-unit-box{
                                width: 250px;
                            }
                            &.wms-dropdown-packaging-unit-uom-box{
                                width: 250px;
                                @include desktop_1024{
                                    width: 58%;
                                }
                            }
                            &.wms-dropdown-counting-mechanism-box{
                                width: 240px;
                                max-width: 100%;
                            }
                            &.wms-storage-box-UOM-box{
                                width: 140px;
                                max-width: 100%;
                                @include desktop_1024{
                                    width: 170px;
                                }
                            }
                            &.wms-dropdown-counting-unit-box{
                                width: 140px;
                                max-width: 100%;
                                @include desktop_1024 {
                                    width: 187px;
                                }
                            }
                        }
                        // Inventory Options section
                        .wms-allocation-main{
                            display: flex;
                            width: 100%;
                            flex-wrap: wrap;
                            @include desktop_1280 {
                                display: inherit;
                            }
                        }
                        .wms-inventory-options-block{
                            display: flex;
                            width: 100%;
                            align-items: center;
                        }
                        // Inventory Options section
                        // .wms-cost-tracking-options{
                        //     display: inline-flex;
                        //     align-items: center;
                        // }
                        .wms-button-component {
                            margin-top: 15px;
                            &.wms-assembly-btn-block{
                                display: flex;
                            }
                        }
                        .wms-assembly-block{
                            .wms-kendo-dropdown-list-component{
                                width: 30%;
                                display: inline-block;
                                float: left;
                            }
                            .wms-dropdown-component {
                                width: 20%;
                            }
                        }
                        .wms-text-area-component{
                            padding: 15px 6px 0;
                        }
                        .wms-recurring-input-holder{
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .wms_primaryunitType_wrap, .wms_measure_wrap{
                            display: flex;
                            padding: 15px 15px 5px 15px;
                            .unit-type-option-wrapper,
                            .primary-input-wrapper,
                            .secondary-input-wrapper {
                                width: 250px;
                            }
                            .primary-units-per-secondary-unit {
                                width: 182px;
                                .div_tooltip {
                                    top: -85px;
                                    width: 400px;
                                    &:before {
                                        -webkit-transform: rotate(45deg);
                                        left: 74px;
                                        top: 72px;
                                    }
                                }
                            }
                            .wms_checkbox_wrap {
                                margin-top: 17px;
                                margin-left: 30px;
                            }
                            .unit-input-wrapper {
                                width: 145px;
                            }
                        }
                        .wms_primaryunitType_wrap {
                            flex-direction: column;
                            .uom-wrapper {
                                display: flex;
                            }
                            .pick-line-wrapper {
                                display: flex;
                                .max-allocate-input-wrapper {
                                    display: flex;
                                    width: 160px;
                                }
                            }
                        }
                        // .disabled-secondary-uom{
                        //     &.wms_primaryunitType_wrap{
                        //         display: block;
                        //     }
                        //     &.wms_checkbox_wrap{
                        //         width: 60%;
                        //         float: right;
                        //         padding: 15px;
                        //     }
                        // }
                        .wms_secondary_uom
                        {
                            margin-left: 0px !important;
                        }
                        .wms_measure_wrap{
                            padding-top: 20px;
                            border-top: 1px solid #EEEEEE;
                            .wms_is_equal_text {
                                margin: 25px 15px 0 15px;
                            }
                        }
                        .wms-zones-box{
                            display: flex;
                            width: 100%;
                            margin-bottom: 20px;
                            @include desktop_1024{
                                display: initial;
                            }
                            .wms_putaway_wrap{
                                @include desktop_1024{
                                    margin-bottom: 15px;
                                }
                                .wms-button-component {
                                    display: block;
                                    margin-left: 8px;
                                    margin-top: 15px;
                                }
                                .wms-input-component {
                                    width: 100%;
                                }
                                .wms-form-grid-holder{
                                    padding: 0 10px;
                                }
                            }
                        }
                        // items menu list
                        .wms-item-qualifiers-block{
                            display: flex;
                            align-items: center;
                            .wms-button-component {
                                margin-top: 6px;
                                margin-left: 10px;
                            }
                        }
                        // TRACKING OPTIONS sections design css
                        .wms-tracking-options-block{
                            display: flex;
                            width: 100%;
                            align-items: center;
                        }
                        .wms-form-grid-holder {
                            .qualifier-delete{
                                border-radius: 50%;
                                // margin: 0;
                                border-color: #db6353;
                                // padding: 0;
                                position: relative;
                                // right: 7px;
                                color: #db6353;
                                line-height: 17px;
                                // .k-icon{
                                //     font-size: 12px;
                                //     margin: 0;
                                //     color: #db6353;
                                //     height: 20px;
                                //     width: 20px
                                // }
                                // .k-i-close::before, .k-i-group-delete::before {
                                //     content: "\E11B";
                                //     top: 5px;
                                //     bottom: 0;
                                //     left: 0;
                                //     right: 0;
                                //     position: absolute;
                                // }
                            }
                        }
                        // TRACKING OPTIONS sections design css and
                    }
                    .wms_drop_block{
                        .wms_datepicker_wrap{
                            .wms-input-component {
                                width: 45%;
                                max-height: 100px;
                                float: left;
                                padding: 0 7px;
                                position: relative;
                                margin-bottom: 12px;
                            }
                            .wms-DatePickerClearer{
                                float: left;
                                width: 30px;
                                margin-left: -10px;
                                margin-top: 19px;
                                border: 1px solid #d8e5ee;
                                background: #008EDD;
                                height: 32px !important;
                                border: none;
                                text-align: center;
                                line-height: 30px;
                                border-left: 0px;
                                color: #ffffff;
                                cursor: pointer;
                                position: relative;
                                z-index: 10;
                            }
                        }
                        .wms_datepicker_wrap{
                            display: flex;
                        }
                        .wms-button-component {
                            margin-left: 6px;
                            display: inherit;
                        }
                        .wms_checkbox_list{
                            display: inline-block;
                            .wms-checkbox-component{
                                float: left;
                                margin-right: 20px;
                                width: auto;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
            #collapseRecurringStorage {
                height: 100%;
                #ItemRecurringStorageDetails_main {
                    height: calc(100% - 20px);
                    display: flex;
                    flex-direction: column;
                    .wms-collapsible-content {
                        flex: 1;
                        display: flex !important;
                        flex-direction: column;

                        .wms-grid-wrapper {
                            flex: 1;
                            // .wms-grid-wrapper {
                                .wms-grid-holder {
                                    .no-josh{
                                        .k-grid-content{
                                            .k-virtual-scrollable-wrap{
                                                table{
                                                    tbody{
                                                        tr{
                                                            td{
                                                                .k-dirty{
                                                                    display: none;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .wms-grid-toolbar{
                                    display: inline-flex;
                                    width: 100%;
                                    flex-wrap: wrap-reverse;
                                    align-items: center;
                                    padding: 20px 0 10px;
                                    .wms-grid-checkbox-content{
                                        // margin-right: 10px;
                                        // margin-top: 20px;
                                        @include desktop_1440{
                                            width: 100%;
                                        }
                                    }
                                    .wms-toolbar-button {
                                        justify-content: flex-end;
                                        width: 100%;
                                        .wms-button-component {
                                            margin: 6px 10px 6px 0;
                                            // margin-left: 12px;
                                            // @include desktop_1680{
                                            //     margin-top: 12px;
                                            // }
                                        }
                                    }
                                }
                            // }
                        }
                    }
                }
            }
            #collapseStorageHandling{
                height: 100%;
                #ItemHanlingCharge_main{
                    height: calc(100% - 20px);
                    display: flex;
                    flex-direction: column;
                    .wms-collapsible-content{
                        flex: 1;
                        display: flex !important;
                        flex-direction: column;
                        .wms-grid-wrapper{
                            flex: 1;
                            .wms-grid-holder {
                                table{
                                    .wms-disabled-row{
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }
                    tr{
                        .facility-exclamator{
                            padding-left: 5px;
                            color: #faca00;
                        }
                        &.k-state-selected{
                            .facility-exclamator{
                                color: white;
                            }
                        }

                    }
                }
            }
            #ItemInventoryUofM_main {
                .wms-collapsible-content {
                    padding: 0;
                }
            }
        }
    }
    .wms-overlay-opacity{
        position: relative;
        overflow: hidden;
        opacity: 0.3;
        transition: 0.5s;
        &::before{
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.03);
            z-index: 11;
            height: 100%;
            width: 100%;
        }
    }
}