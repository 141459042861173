@import "../../../../../scss/variable";
@import "../../../../../scss/mixin";

.manageAllocation {
    width: 100%;
    height: auto;
    display: flex;
    .model-content-wrapper {
        padding: 0px !important;
        display: flex;
        overflow: hidden;
    .grid-filter-wrapper {
        width: 100%;
        display: flex;

    .side-content {
        display: flex;
        flex-direction: column;
        width: 345px;
        min-width: 345px;
        max-width: 345px;
        padding: 0 5px;
        padding: 30px 15px 30px 30px;
        overflow: auto;
        overflow-x: hidden;
        padding-right: 0;

        .wms-button-component {
            float: left;
        }

        .wms-area-header {
            width: 100%;
            float: left;
            font-size: 14px;
            padding: 5px 0 10px;
            color: $label-color;
        }

        .wms-toolbar {
            margin-bottom: 10px;
        }

        #LineItemInfoSection {
            margin-right: 15px;
        }

        .title {
            margin-top: 10px;
            margin-left: 5px;
        }

        .wms-area-content-darker {
            margin: 0;

            .wms-input-component {
                width: 100%;
            }
            .wms-input-group-wrapper {
                .wms-input-component  {
                    width: 50%;
                }
            }

            .form-group {
                width: 100%;
                margin-bottom: 10px;
            }

            .wms-datetimepicker-component {
                .form_group {
                    @extend .form-group
                }
            }

            ul {
                li {
                    width: 100%;
                    float: left;
                    h3 {
                        margin: 0;
                        font-size: 13px;
                        width:44%;
                        text-align:right;
                        line-height: 24px;
                        padding-right: 5px;
                        font-weight: bold;
                        color: $window-title;
                    }

                    p {
                        color: $label-color;
                        text-align:left;
                        width:56%;
                        margin: 0;
                        line-height: 24px;
                        font-size: 13px;
                        padding-left: 5px;
                        word-break: break-word;
                    }
                }
            }
        }

        .without-bg {
            padding: 0px 10px 5px;
            display: inline-block;

            .pull-left {
                float: left;
            }
        }

    }

    .grid-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 30px 30px 30px 15px;
        .wms-warning-wrapper{
            padding:0;
            border: none;
            top: 40px;

        }
        .alert-not {
            color: #ff0006;
            width: auto;
            display: inline-block;
            vertical-align: middle;
        }

        .wms_area_border {
            top: 85px;
            padding: unset;
        }

        .title-block {
            width: 100%;
            float: left;

            label {
                color: #5a7e9d !important;
                display: inline-block;
                margin-right: 8px;
                vertical-align: middle;
                color: $label-color;
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 600;

                .EditAllocationsTotalResults {
                    float: left;
                    margin-right: 5px;
                }

                &.sort {
                    float: right;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: calc(100% - 109px);
                    vertical-align: middle;
                    text-align: right;
                    margin-top: 5px;

                    span {
                        color: #7d7d7d !important;
                    }
                }
            }
        }

        .k-grid .k-grid-header {
            width: 100%;
            float: left;
        }
    }
    }
    }
}

.confirmDeleteLineItem {
    .model-content-wrapper {
        padding:10px 10px 0 10px !important;
        .wms-message-center{
            line-height: 57px;
            text-align: center;
        }
    }
}