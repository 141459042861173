@import "../../../../scss/variable";
@import "../../../../scss/mixin";

#wms-configure-packing-slips {
    height: 100%;
    width: 100%;
    .packing-slips-refresh-btn {
        .wms_toolbar_button {
            padding: 0px 16px;
            .wms_toolbar_button_text {
                display: none;
            }
        }
    }
    .new-layout-grid-wrapper {
        transition-property: width;
        -webkit-transition: all 0.4s linear;
        -moz-transition: all 0.4s linear;
        -o-transition: all 0.4s linear;
        transition: all 0.4s linear;
        overflow: hidden;
        background: $form-boxbg;
        overflow-y: auto;
        padding: 0;
        margin-bottom: 30px;
        .title {
            margin: 0 0 0 2px;
        }
        .wms-toolbar-button {
            float: right;
            display: flex;
            flex-wrap: wrap;
            .refreshSmalParcelGrid {
                .blue_button_color {
                    padding: 0px 16px;
                    .wms_toolbar_button_text {
                        display: none;
                    }
                }
            }
        }
    }
}
#PackingSlipsConfiguration {
    .display-inline {
        display: flex;
    }
    #packingSlipsDetail_main {
        .wms-checkbox-component {
            margin: 17px;
        }
    }
    #packingSlipsAddress1Config_main,
    #packingSlipsAddress2Config_main {
        .display-inline {
            flex-direction: column;
        }
        .wms-radiobtn-component  {
            .wms-radiobtn  {
                margin: 0 30px 21px 0;
            }
        }
        .data-source-lbl {
            width: 100%;
            display: flex;
            letter-spacing: 0px;
            color: #222222;
            opacity: 1;
            margin: 10px 0 10px 5px;
            font-size: 10px;
            line-height: 1.2;
            font-weight: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .wms-icon {
            padding-right: 10px;
            font-size: 15px;
            color: #005ca8;
        }
        .custom-fields-info{
            border-top: 1px solid #F5F5F5;
            border-bottom: 1px solid #F5F5F5;
            padding: 15px 0px;
            margin-bottom: 15px;
        }
    }
}
