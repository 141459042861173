.customOrderinfo {
    .input-components {
        padding: 0;
        border: none;
        background: transparent;
        .wms-input-component {
            width: 100%;
            padding: 0;
            &:before {
                left:0px;
                right:0px;
            }
            label {
                left:0;
                right:0;
            }
        }
    }
}