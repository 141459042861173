@import "../../../../scss/variable";
@import "../../../../scss/mixin";

.wms-grid-wrapper {
    position: relative;

    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .wms-grid-toolbar {
        display: flex;
        width: 100%;
        padding: 0;
        .wms-grid-checkbox-content {
            margin-right: 10px;
            display: flex;
            align-items: center;
            .wms-toolbar-btn-block {
                position: absolute;
                width: 110px;
            }
            .wms-grid-div-disable {
                position: relative;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                cursor: not-allowed;
            }
            .wms-grid-div-tooltip {
                position: absolute;
                z-index: 12;
                top: 23px;
                width: 200px;
                background: #ecf5fd !important;
                font-size: 14px;
                color: #7d7d7d !important;
                border: 1px solid #b7d2e5 !important;
                padding: 4px 8px;
            }
            .wms-grid-div-tooltip:before {
                content: "\A";
                border-style: solid;
                border-width: 11px 11px 0px 20px;
                border-color: transparent #0079c4 transparent transparent;
                position: absolute; // left: -15px;
                -webkit-transform: rotate(-135deg);
                left: 75px;
                top: 1px;
            }
        }
        .wms-toolbar-button {
            flex: 1;
            flex-wrap: wrap;
            display: flex;
            justify-content: flex-end;
        }
        .wms-grid-reset-btn {
            // margin-left: 10px;
            .k-menu {
                float: right;
                margin-bottom: 12px;
                li {
                    overflow: hidden;
                    &.GridMenuTopLink {
                        cursor: pointer;
                        border-radius: 50px;
                        padding: 0 10px 1px 15px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid var(--default-border);
                        &:hover {
                            background-color: rgba(0, 0, 0, 0.08);
                        }
                    }
                    .k-link {
                        padding: 0px;
                        color: var(--primary-black);
                        // line-height: 25px;
                        display: unset;
                        .options-title {
                            // font-weight: bold;
                            font-weight: 700;
                            white-space: nowrap;
                            display: inline-block;
                        }
                        i {
                            font-size: 14px;
                            // margin-right: 4px;
                        }
                        .wms-grid-options {
                            margin-left: 4px;
                            padding-right: 1px;
                        }
                    }
                }
                li.k-state-border-down {
                    overflow: visible;
                }
            }
            .k-animation-container {
                display: block !important;
                left: inherit !important;
                right: 0;
                .k-menu-group {
                    .k-item {
                        &.k-state-default {
                            background: transparent;
                        }
                        &.k-state-hover {
                            background-color: #dfe0e1;
                        }
                        &.wms-disabled-look {
                            .k-link {
                                color: $input-color !important;
                                &:hover {
                                    color: $input-color !important;
                                }
                            }
                        }
                        .k-link {
                            color: $primary-textcolor !important;
                            padding: 0 10px;
                            font-size: 13px;
                            line-height: 30px;
                            background: transparent;
                            &:hover {
                                background-color: #dfe0e1;
                                color: $primary-black !important;
                            }
                        }
                    }
                }
            }
        }
        //-- .wms-grid-reset-btn {
        //     // margin-left: 10px;
        //     .k-menu {
        //         float: right;
        //         margin-bottom: 7px;
        //         li {
        //             overflow: hidden;
        //             &.GridMenuTopLink {
        //                 background: $resetbtn-bg;
        //                 cursor: pointer;
        //                 border-radius: 50px;
        //                 padding: 0 12px 0 15px;
        //                 height: 34px;
        //                 display: flex;
        //                 justify-content: center;
        //                 align-items: center;
        //                 &:hover {
        //                     background: $resetbtn-bg-hover;
        //                 }
        //             }
        //             .k-link {
        //                 padding: 0px;
        //                 color: $primary-white;
        //                 line-height: 25px;
        //                 display: unset;
        //                 .options-title {
        //                     font-weight: bold;
        //                 }
        //                 i {
        //                     font-size: 14px;
        //                     margin-right: 3px;
        //                 }
        //             }
        //         }
        //         li.k-state-border-down {
        //             overflow: visible;
        //         }
        //     }
        //     .k-animation-container {
        //         display: block !important;
        //         left: inherit !important;
        //         right: 0;
        //         .k-menu-group {
        //             .k-item {
        //                 &.k-state-default {
        //                     background: transparent;
        //                 }
        //                 &.k-state-hover {
        //                     background-color: $greenbtn;
        //                 }
        //                 &.wms-disabled-look {
        //                     .k-link {
        //                         color: $input-color !important;
        //                         &:hover {
        //                             color: $input-color !important;
        //                         }
        //                     }
        //                 }
        //                 .k-link {
        //                     color: $primary-textcolor !important;
        //                     padding: 0 10px;
        //                     font-size: 13px;
        //                     line-height: 30px;
        //                     background: transparent;
        //                     &:hover {
        //                         background: $greenbtn;
        //                         color: $primary-white !important;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }
    .wms-grid-top {
        padding: 2px 0 6px;
        border-radius: 4px 4px 0 0;
        background-color: var(--tabel-headbg);
        border: 1px solid #41536440;
        margin-top: 13px;
        .wms-grid-title {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: normal;
            padding: 0 17px 0px 11px;
            letter-spacing: 0.1px;
            color: $input-color;
            border-right: 1px solid $input-color;
            span {
                color: $input-color;
                padding-right: 4px;
                font-weight: bold;
            }
            &:last-child {
                border-right: none;
            }
        }
        .select-info-wrapper {
            padding-left: 16px;
            span {
                padding-right: 0px;
            }
        }
    }

    //-- .wms-grid-top {
    //     padding: 6px 0;
    //     border-radius: 4px 4px 0 0;
    //     background: #e2effc;
    //     border: 1px solid #d0dce8;
    //     background: #e2effc;
    //     margin-top: 13px;
    //     border-bottom: none;
    //     .wms-grid-title{
    //         font-size: 12px;
    //         text-transform: uppercase;
    //         font-weight: normal;
    //         padding: 0 15px;
    //         letter-spacing: 0.5px;
    //         color: $input-color;
    //         border-right: 1px solid $input-color;
    //         span{
    //             color: $input-color;
    //             padding-right: 7px;
    //             font-weight: bold;
    //         }
    //         &:last-child{
    //             border-right: none;
    //         }
    //     }

    // }
    .wms-grid-holder {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        min-height: 400px;
        max-height: 800px;
        .k-grid {
            display: flex !important;
            border-top-color: #d0dce8;
            height: 100%;
            td {
                padding: 6px 12px;
                // remove box shadow on navigate or selection
                &.k-state-focused {
                    box-shadow: none;
                }
            }
            th {
                // remove box shadow on navigate or selection
                &.k-state-focused {
                    box-shadow: none;
                }
            }
            .k-loading-mask {
                background-color: rgba(255, 255, 255, 0.3) !important;
            }
        }
        .k-grid-header {
            // border: 1px solid #b7d2e5;
            border-color: #d0dce8;
            .k-link {
                color: $input-color;
                font-weight: 700;
                padding: 0;
                margin: 0 12px 0 0;
            }
            .k-icon {
                color: $input-color;
            }
            .k-i-sort-asc-sm {
                &:before {
                    color: $input-color;
                }
            }
            .k-header {
                &.k-with-icon {
                    color: $input-color;
                    font-weight: 700;
                    text-transform: uppercase;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: 30px;
                    border-bottom: 0;
                    vertical-align: middle;
                    &.k-state-focused {
                        box-shadow: unset;
                    }
                    .k-icon {
                        color: $input-color;
                    }
                    .k-i-sort-asc-sm {
                        &:before {
                            color: $input-color;
                        }
                    }
                }
                .k-sort-order {
                    margin-left: 5px;
                }
            }
            .k-grid-header-wrap {
                border-width: 0 0 0 0;
                table {
                    thead {
                        tr {
                            th {
                                padding: 4px 12px;
                                // text-align: center;
                                text-align: left;
                                .k-header-column-menu {
                                    height: 100%;
                                    bottom: 0;
                                    margin-right: -4px;
                                    z-index: 1;
                                }
                                .k-link {
                                    position: relative;
                                    .k-icon {
                                        &.k-i-sort-desc-sm,
                                        &.k-i-sort-asc-sm {
                                            vertical-align: middle;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .k-grid-content {
            &.k-auto-scrollable {
                .k-virtual-scrollable-wrap {
                    table {
                        tbody {
                            tr {
                                &.k-alt {
                                    background: #f5f5f5 !important;
                                }
                                &.k-state-selected {
                                    background-color: var(--primary-hovered) !important;
                                    // td{
                                    //     color: $primary-white;
                                    // }
                                }
                                &.wms-over-allocated {
                                    background-color: #f2e1d8 !important;
                                    &.k-state-selected {
                                        background-color: var(--primary-hovered) !important;
                                    }
                                }
                                &.hide-close-button {
                                    .k-command-cell {
                                        display: none;
                                    }
                                }
                                td {
                                    font-weight: 400;
                                    font-size: 13px;
                                    color: $input-color;
                                    word-break: break-word;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    i {
                                        &.wms-required-grid {
                                            color: #f63131;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .no-recored-text-enable {
        .k-virtual-scrollable-wrap {
            display: flex;
            flex-direction: column;
            .k-grid-norecords {
                color: #707070;
                text-align: center;
                margin: auto;
            }
        }
    }
    .wms-grid-message {
        text-align: center;
        min-height: 30px;
        .wms-grid-alert-msg {
            font-weight: 400;
            color: red;
            font-size: 14px;
            align-items: center;
            display: inline-flex;
            &.note {
                &.loading-text {
                    span {
                        color: $input-color;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .k-loading-mask {
        background-color: rgba(255, 255, 255, 0.3) !important;
    }
}
