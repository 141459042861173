.audit-inventory-review-audit-job-grid {
    height: 100%;

    .ShowVariancesOnlyCheckBox {
        margin-right: 48px;
    }
    .audit-inventory-review-audit-job-grid-holder {
        .wms-grid-holder {
            .k-virtual-scrollable-wrap {
                .k-detail-row {
                    .k-hierarchy-cell {
                        display: none;
                        padding: 0px;
                    }
                    .k-detail-cell {
                        padding: 0px;
                        // width: 100%;
                        display: table-row;
                    }
                }
            }
        }
    }
    .grid-options-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add-location-button, .export-review-audit-job, .expanded-view-button {
            .wms_toolbar_button {
                padding-right: 15px;
            }
            .wms_toolbar_button_text {
                margin-left: 0px;
            }
        }
        .grid-options-left-part {
            display: flex;
            align-items: center;
            .item-text {
                margin-right: 20px;
                width: auto;
            }
        }
        .wms-tooltip-component {
            position: relative;
            cursor: pointer;
            &.saw-tooltip-on-icon {
                .div_tooltip {
                    left: -40%;
                    &:before {
                        left: 35%;
                    }
                }
            }
        }
    }
}
