@import "../../../scss/variable";
@import "../../../scss/mixin";
#role-holder-react {
    width: 100%;
    height: 100%;
    display: flex;
    .role-grid {
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        width: 40%;
        height: 100%;
        border-right: 1px solid $seprator-color;
        overflow-y: auto;
        .location-topbtn-holder {
            .search-applied {
                float: right;
                margin: 8px 0 10px 2px;
                label {
                    color: #5a7e9d;
                    font-size: 11px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 600;
                }
            }
        }
        .role-form-boxes {
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            .wms-area-content {
                margin: 0px;
                .wms-area-content-darker {
                    margin-bottom: 12px;
                    .wms-input-group-wrapper {
                        width: 50%;
                        .wms-dropdown-component {
                            width: 50%;
                        }
                    }
                }
            }
        }
        .wms-grid-wrapper {
            .wms-grid-top {
                margin-top: 0;
            }
        }
    }
    .role-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        position: relative;
        .wms_details_labels {
            padding: 25px;
            label {
                padding-left: 10px;
                color: #5a7e9d;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 600;
            }
        }

        .tab-content {
            flex: 1;
            overflow: auto;
            margin-top: 5px;
            .wms-collapsible-div {
                .wms-collapsible-content {
                    display: flex;
                    flex-direction: column;
                    .wms-container-wrapper {
                        .wms-area-content {
                            .wms-area-content-darker {
                                display: flex;
                                justify-content: space-between;
                                .role-type-btn {
                                    display: flex;
                                    padding: 15px;
                                }
                            }
                        }
                    }
                    .wms-input-component {
                        @include desktop_1280 {
                            width: 33.33%;
                        }
                    }

                    #loadingContent {
                        width: 100%;
                        height: 100%;
                    }
                    #loadingContent.firstTime {
                        height: 40% !important;
                    }
                    .wms-treeview-content {
                        height: 100%;
                        .wms-warning-label {
                            font-size: 14px;
                            color: red;
                            margin-bottom: 5px;
                            display: inline-block;
                            span {
                                font-weight: bold;
                            }
                        }
                        .wms-area-content {
                            height: 100%;
                            .wms-area-content-darker {
                                flex-direction: column;
                                padding: 0;
                                .wms-radiobtn-component {
                                    margin-bottom: 16px;
                                    display: inline-block;
                                    width: 25%;
                                    .wms-radiobtn {
                                        margin: 0px;
                                        padding: 0 5px;
                                        @include desktop_1366 {
                                            padding: 0 2px;
                                        }
                                    }
                                }
                                .title {
                                    width: 100%;
                                    float: left;
                                    margin: 0px 0px;
                                    padding-left: 7px;
                                    label {
                                        color: #5a7e9d;
                                        font-size: 11px;
                                        text-transform: uppercase;
                                        letter-spacing: 1px;
                                        font-weight: 600;
                                    }
                                }
                                .wms-permission-wrap {
                                    display: flex;
                                    align-items: center;
                                    .wms-checkbox-component {
                                        margin-right: 15px;
                                    }
                                }
                                .wms_tree_role_wrapper {
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    border-top: 1px solid $input-border;
                                    height: 100%;
                                    .wms_tree_role {
                                        height: 100%;
                                        flex: 1;
                                        overflow: auto;
                                        padding: 15px 10px;
                                        border-right: 1px solid silver;
                                        .k-checkbox:checked + .k-checkbox-label::before {
                                            background-color: var(--primary-themecolor);
                                            border-color: var(--primary-themecolor);
                                        }
                                        .k-checkbox + .k-checkbox-label::before {
                                            background-color: #fff;
                                        }
                                        .k-checkbox:indeterminate + .k-checkbox-label::after {
                                            background-color: var(--primary-themecolor);
                                            border-color: var(--primary-themecolor);
                                            width: 12px;
                                            height: 12px;
                                            top: 2px;
                                            left: 2px;
                                        }
                                        .k-checkbox-label::before {
                                            background-color: #ffffff;
                                        }
                                        .k-in {
                                            color: $label-color;
                                            &.k-state-selected {
                                                color: $label-color;
                                                background-color: transparent;
                                            }
                                        }
                                        span.redText {
                                            color: red;
                                        }
                                    }
                                    &.wms-customer-role-tree {
                                        .wms_tree_role {
                                            height: 100%;
                                            max-height: 273px;
                                        }
                                    }
                                    .wms_permissions_desc {
                                        width: 35%;
                                        right: 0;
                                        top: 2px;
                                        bottom: 0;
                                        overflow: auto;
                                        background: #ffffff;
                                        overflow: auto;
                                        .wms-section-title {
                                            color: $window-title;
                                            line-height: 44px;
                                            font-size: 16px;
                                            font-weight: bold;
                                            display: block;
                                            background: rgba(0, 0, 0, 0.05);
                                            padding: 0 15px;
                                        }
                                        .wms-section-body {
                                            color: $label-color;
                                            padding: 10px;
                                            line-height: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
