.wms-error-wrapper {
    width: 100%;

    .wms-notification {
        &.success {
            margin: auto;
            z-index: 20000;
            position: fixed;
            left: 0;
            right: 0;
            top: 80px;
        }

        &.danger {
            position: fixed;
            margin: auto;
            z-index: 20005;
            left: 0;
            right: 0;
        }

        &.warning {
            position: fixed;
            margin: auto;
            z-index: 20005;
            left: 0;
            right: 0;
        }
    }

    .wms-modal-backdrop-transparent {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #000000;
        opacity: 0.75;
        width: 100%;
        height: 100%;
        z-index: 20000;
    }

    .wms-react-notification {
        margin: auto;
        z-index: 20000;
        position: absolute;
        border-radius: 4px 4px 4px 4px;
        overflow: hidden;
        width: 800px;
        max-height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        top: 100px;
        background-color: #fff;
        transition: all ease-in-out 1s;
        .wms-notification-header {
            padding: 0 23px 0px 30px;
            display: flex;
            height: 80px;
            min-height: 80px;
            color: #222222;
            justify-content: space-between;
            &.danger {
                background-color: #d91c03 !important;
            }
            &.error-recover {
                background-color: #febf33 !important;
            }
            .wms-notification-title {
                margin: auto 0;
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
                display: flex;
                text-transform: uppercase;
                .error-status {
                    max-width: 220px;
                    color: var(--primary-white);
                }
                .title-wrapper {
                    display: flex;
                }
                .error-pipe {
                    padding: 0 9px;
                }
            }

            .wms-collapse-button {
                margin: auto 0;
                min-width: 148px;
                .wms_toolbar_button {
                    background: transparent;
                    border: 1px solid var(--primary-white);
                    // display: flex;
                    color: var(--primary-white) !important;
                    padding: 0 19px 0 15px;
                    .wms_sprite {
                        width: 14px;
                        height: 9px;
                        font-size: 15px;
                        line-height: 11px;
                        color: unset;
                    }
                    .wms_toolbar_button_text {
                        color: unset;
                        margin-left: 8px;
                    }
                }
            }
        }

        .wms-notification-content {
            // padding: 40px;
            word-break: break-word;
            // max-height: 400px;
            overflow: auto;
            color: #222222;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0px;
            flex: 1;
            display: flex;
            flex-direction: column;
            .wms-error-content-main {
                margin: 28px 40px 40px 40px;
                display: flex;
                flex-direction: column;
                p {
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
                ol.order-list {
                    margin-top: 0;
                    margin-bottom: 5px;
                    li {
                        list-style: initial;
                    }
                }
                ul.error-ul-list {
                    margin: 0 18px;
                    li {
                        list-style-type: decimal;
                        margin-top: 12px;
                        margin-bottom: 12px;
                    }
                }
            }
            // .wms-error-code {
            //     display: block;
            //     margin-bottom: 10px;
            // }

            // .single-line-msg {
            //     display: inline;
            //     padding-left: 3px;
            // }

            .wms-error-content-details {
                display: flex;
                flex-direction: column;
                border-top: 1px solid rgba(0, 0, 0, 0.08);
                padding: 40px 40px 20px 40px;

                .wms-details-wrapper {
                    display: flex;
                    margin: 0 0 20px 0;
                    .wms-details-title {
                        width: 200px;
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .wms-details-description {
                        flex: 1;
                    }
                }
            }
        }

        .wms-notification-footer {
            display: flex;
            height: 95px;
            min-height: 95px;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.08);

            .wms-button-component {
                margin: 0 14px 0px 0;
            }
        }

        // .wms-notification-toolbar {
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;

        //     .wms-toolbar {
        //         display: flex;

        //         .wms-toolbar-button {
        //             border-radius: 0px;
        //             line-height: 40px;
        //             display: flex;
        //             margin: 0px;
        //             width: 100%;
        //             align-items: center;
        //             justify-content: center;
        //             text-decoration: none;
        //             cursor: pointer;

        //             .wms-sprite,
        //             .wms-toolbar-button-text {
        //                 vertical-align: top;
        //                 line-height: 40px;
        //             }

        //             .wms-toolbar-button-text {
        //                 padding: 0 4px;
        //             }
        //         }
        //     }
        // }
    }
}
