@import "../../../scss/variable";
@import "../../../scss/mixin";
:local(.wms_settings_wrapper) {
    height: 100%;
    :local(.wms_settingsGrid) {
        width: 50%;
        height: calc(100% - 5px);
        :local(.wms_area_content) {
            margin-top: 15px;
            margin-bottom: 15px;
            height: calc(100% - 60px);
            width: 100%;
            float: left;
            .k-grid-content {
                position: relative;
                top: 0;
            }
            #gridContainer,
            #gridParent {
                height: 100%;
                .k-grid {
                    height: 100%;
                    .k-grid-content {
                        position: relative;
                        top: 0px;
                        height: calc(100% - 48px);
                    }
                }
            }
            // .k-grid {
            //     .k-grid-content {
            //         position: relative;
            //         top: 0px;
            //         height: calc(100% - 48px);
            //     }
            // }
        }
    }
}
